import React, { useState, useLayoutEffect, useRef } from "react"
import { Col, Row, Form, Image, Modal, Button, Figure } from "react-bootstrap"
import DesktopWindow from "../DesktopWindow"
import Progress from "../Progress"

import { getPersonFromId } from "../db/PersonDb"

const PersonForm = ({ params, onAction }) => (
  <div>
    <Form className="person-form">
      <Form.Group as={Row}>
        <Form.Label className="d-flex justify-content-center form-title">
          <Image src="pictos/Logo_de_la_République_française_(1999).svg" />
        </Form.Label>
      </Form.Group>
      <Form.Group as={Form.Row}>
        <Form.Label column="sm" sm="1">
          Réf:
        </Form.Label>
        <Col sm="3">
          <Form.Control
            size="sm"
            name="ref"
            type="text"
            value={params.reference}
            readOnly
          />
        </Col>
        <Form.Label column="sm" sm="1">
          Date:
        </Form.Label>
        <Col sm="3">
          <Form.Control
            size="sm"
            name="creation"
            required
            type="text"
            value={params.creation_date}
            readOnly
          />
        </Col>
        {/**
        <Form.Label column="sm" sm="1">
          Créateur:
        </Form.Label>
        <Col sm="3">
          <Form.Control
            size="sm"
            name="from"
            required
            type="text"
            value={params.created_by}
            readOnly
          />
        </Col>
         */}
      </Form.Group>
      <Form.Group as={Form.Row}>
        <Form.Label column="sm" sm="3">
          <Image src={params.photo} />
        </Form.Label>
        <Col sm="9">
          <Form.Group as={Row}>
            <Form.Label column="sm" sm="4">
              PRENOM:
            </Form.Label>
            <Col>
              <Form.Control
                size="sm"
                name="firstname"
                type="text"
                value={params.firstname}
                readOnly
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column="sm" sm="4">
              NOM DE NAISSANCE:
            </Form.Label>
            <Col>
              <Form.Control
                size="sm"
                name="lastname"
                type="text"
                value={params.lastname}
                readOnly
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column="sm" sm="4">
              NOM MARITAL:
            </Form.Label>
            <Col>
              <Form.Control
                size="sm"
                name="firstname"
                type="text"
                value={params.maidenname}
                readOnly
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column="sm" sm="4">
              DATE DE NAISSANCE:
            </Form.Label>
            <Col>
              <Form.Control
                size="sm"
                name="birthday"
                type="text"
                value={params.birthday}
                readOnly
              />
            </Col>
          </Form.Group>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="3">
          DATE DE DECES:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            size="sm"
            name="death"
            type="text"
            value={params.death}
            readOnly
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="3">
          LIEU DU DECES:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            size="sm"
            name="death"
            type="text"
            value={params.death_place}
            readOnly
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="3">
          CAUSE DU DECES:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            size="sm"
            name="death"
            type="text"
            value={params.death_cause}
            readOnly
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="3">
          SEXE:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            size="sm"
            name="sexe"
            type="text"
            value={params.sexe}
            readOnly
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="3">
          CHEVEUX:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            size="sm"
            name="sexe"
            type="text"
            value={params.hair}
            readOnly
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="3">
          YEUX:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            size="sm"
            name="sexe"
            type="text"
            value={params.eyes}
            readOnly
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="3">
          SIGNES DISTINCTIFS:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            size="sm"
            name="sexe"
            type="text"
            value={params.distinctive_signs}
            readOnly
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="3">
          DERNIERE ADRESSE DE RESIDENCE CONNUE:
        </Form.Label>
        <Col sm="9">
          {params.addressUrl ? (
            <Form.Control
              size="sm"
              name="address"
              type="textarea"
              value={params.address}
              readOnly
              onClick={() => window.open(params.addressUrl, "_blank").focus()}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "blue",
              }}
            />
          ) : (
            <Form.Control
              size="sm"
              name="address"
              type="textarea"
              value={params.address}
              readOnly
            />
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="2">
          SUSPECT
        </Form.Label>
        <Col sm="2">
          <Form.Check
            defaultChecked={params.suspect === "Suspect" ? true : false}
            readOnly
          />
        </Col>
        <Form.Label column="sm" sm="2">
          TEMOIN
        </Form.Label>
        <Col sm="2">
          <Form.Check
            defaultChecked={params.suspect === "Témoin" ? true : false}
            readOnly
          />
        </Col>
        <Form.Label column="sm" sm="2">
          VICTIME
        </Form.Label>
        <Col sm="2">
          <Form.Check
            defaultChecked={params.suspect === "Victime" ? true : false}
            readOnly
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="3">
          NOTES:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            size="sm"
            rows={6}
            name="notes"
            as="textarea"
            value={params.facts}
            readOnly
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column="sm" sm="3">
          RELATIONS CONNUES:
        </Form.Label>
        {params.links.map((link) => (
          <Col sm="3" key={link}>
            <Form.Control
              className="link"
              size="sm"
              name={"link" + link}
              value={
                getPersonFromId(link).firstname +
                " " +
                getPersonFromId(link).lastname
              }
              readOnly
              onClick={() => {
                const result = getPersonFromId(link)
                onAction({
                  type: "game",
                  location: "PersonLookupForm",
                  result: { success: result },
                })
              }}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            />
          </Col>
        ))}
      </Form.Group>
      {params.easteregg ? (
        <Form.Group as={Row}>
          <Form.Label column="sm" sm="3">
            PIECES A CONVICTION:
          </Form.Label>
          {params.easteregg}
        </Form.Group>
      ) : (
        ""
      )}
    </Form>
  </div>
)

var personKey = 0

const PersonView = ({ onAction, team, params, scenario }) => {
  const content = useRef(null)
  const [windowContent, setWindowContent] = useState(null)
  const [fileError, setFileError] = useState(false)
  const [fileErrorCancel, setFileErrorCancel] = useState(false)
  const [person, setPerson] = useState(
    PatchPerson(
      team,
      params,
      scenario,
      onAction,
      setFileError,
      setWindowContent
    )
  )

  useLayoutEffect(() => {
    setPerson(
      PatchPerson(
        team,
        params,
        scenario,
        onAction,
        setFileError,
        setWindowContent
      )
    )
    personKey += 1
    if (content.current) content.current.parentNode.scroll({ top: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, scenario, onAction])

  const onPersonViewClose = () => {
    if (fileError || fileErrorCancel) return
    onAction({
      type: "game",
      location: "PersonView",
      result: "close",
    })
  }

  const onErrorClose = () => {
    setFileError(false)
    onAction({
      type: "game",
      location: "MorenoFileRestore",
      result: { success: true },
    })
  }

  const onErrorCancel = () => setFileErrorCancel(true)

  const onErrorCancelOk = () => setFileErrorCancel(false)

  const onWindowClose = () => setWindowContent(null)

  const onWindowContentAction = (event) => {
    // the only defined action on PersonView subwindow is the one to open
    // the audio file from the Gaelle Moreno folder
    if (event.type === "click") {
      onAction({
        type: "game",
        location: "MorenoAudioClick",
        result: { success: true },
      })
      onWindowClose()
    }
  }

  return (
    <>
      <DesktopWindow
        content={{ title: "Dossier Individu" }}
        onClose={onPersonViewClose}
        style={{ top: "0.5em", left: "12em", width: "25em", height: "25em" }}
      >
        <div ref={content}>
          <PersonForm key={personKey} params={person} onAction={onAction} />
        </div>
      </DesktopWindow>
      {fileError && !fileErrorCancel ? (
        <ConfirmModal onConfirm={onErrorClose} onCancel={onErrorCancel} />
      ) : (
        ""
      )}
      {fileErrorCancel ? <ErrorModal onConfirm={onErrorCancelOk} /> : ""}
      {windowContent ? (
        <DesktopWindow
          content={windowContent}
          onClose={onWindowClose}
          onAction={onWindowContentAction}
          style={{ top: "8em", left: "22em" }}
        />
      ) : (
        ""
      )}
    </>
  )
}

const ConfirmModal = ({ onConfirm, onCancel }) => {
  const [progress, setProgress] = useState(false)
  const onRestore = () => {
    setProgress(true)
  }

  return (
    <Modal.Dialog className="confirm-modal">
      <Modal.Header>
        <Modal.Title>Erreur 412</Modal.Title>
      </Modal.Header>
      <Modal.Body className="restore-body">
        <p>
          Erreur n°412: fichier manquant au dossier : 3W15HR.
          <br />
          <br />
          Voulez-vous tenter de le restaurer ?
        </p>
        {progress && (
          <Progress timeProgress={40} timeEnd={1000} onEnd={onConfirm} />
        )}
      </Modal.Body>
      {!progress && (
        <Modal.Footer>
          <Button variant="primary" onClick={() => onRestore()}>
            Ok
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      )}
    </Modal.Dialog>
  )
}

const ErrorModal = ({ onConfirm }) => (
  <Modal.Dialog className="confirm-modal">
    <Modal.Header>
      <Modal.Title>3W15HR</Modal.Title>
    </Modal.Header>
    <Modal.Body>Erreur n°412: fichier manquant au dossier.</Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onConfirm}>
        Ok
      </Button>
    </Modal.Footer>
  </Modal.Dialog>
)

const PatchPerson = (
  team,
  person,
  scenario,
  onAction,
  setFileError,
  setWindowContent
) => {
  if (person.firstname === "François" && person.lastname === "PILLU")
    person.easteregg = <FPilluEasterEgg />
  else if (
    person.firstname === "Floriane" &&
    person.lastname === "MORÉNO" &&
    team === "delta"
  )
    person.easteregg = (
      <FMorenoEasterEgg
        restore_file={scenario.desktop.delta.folders.Moreno_Need_Restore_File}
        onAction={onAction}
        scenario={scenario}
        setFileError={setFileError}
      />
    )
  else if (
    person.firstname === "Gaëlle" &&
    person.lastname === "MORÉNO" &&
    team === "delta"
  )
    person.easteregg = (
      <GMorenoEasterEgg
        onAction={onAction}
        scenario={scenario}
        setContent={setWindowContent}
      />
    )
  //else if (person.firstname === "Léonardo" && person.lastname === "ROBIREZ")
  //  person.easteregg = <LRobirezEasterEgg />
  return person
}

const FPilluEasterEgg = () => (
  <>
    <Form.Label as={Col} sm={4}>
      <Image src="pictos/qrcode_video_tracking.png" />
    </Form.Label>
    <Form.Label as={Col} sm={4}>
      <Image src="pictos/qrcode_audio_recording.png" />
    </Form.Label>
  </>
)

/*
const LRobirezEasterEgg = () => {
  const onFBClick = () =>
    window
      .open("https://www.facebook.com/Bobby-Deap-106087624888482", "_blank")
      .focus()
  return (
    <Form.Label className="d-flex justify-content-center form-title">
      <Figure onClick={onFBClick} style={{ cursor: "pointer" }}>
        <Figure.Image src="pictos/picto-facebook-600x400.png" />
        <Figure.Caption>Bobby Deap</Figure.Caption>
      </Figure>
    </Form.Label>
  )
}
*/

const GMorenoEasterEgg = ({ onAction, scenario, setContent }) => {
  const onFolderOpen = () => {
    const content = {
      title: "Dossier MORENO",
      style: {
        width: "10em",
        height: "8em",
        zIndex: 500,
      },
      icons: [],
    }
    if (!scenario.desktop.delta.folders.Moreno_Need_Restore_File) {
      content.icons.push({
        id: "fmoreno_audio",
        caption: "3W15HR",
        captionColor: "black",
        image: "pictos/bande son.png",
      })
    }
    setContent(content)
  }
  return (
    <Form.Label className="d-flex justify-content-center form-title">
      <Figure onClick={onFolderOpen} style={{ cursor: "pointer" }}>
        <Figure.Image src="pictos/dossier.png" />
        <Figure.Caption>MORENO</Figure.Caption>
      </Figure>
    </Form.Label>
  )
}

const FMorenoEasterEgg = ({
  restore_file,
  onAction,
  scenario,
  setFileError,
}) => {
  const onRestoreFile = () => {
    setFileError(scenario.desktop.delta.folders.Moreno_Need_Restore_File)
  }
  const onFileClick = () => {
    onAction({
      type: "game",
      location: "MorenoAudioClick",
      result: { success: true },
    })
  }
  return (
    <Form.Label className="d-flex justify-content-center form-title">
      <Figure
        onClick={restore_file ? onRestoreFile : onFileClick}
        style={{ cursor: "pointer" }}
      >
        <Figure.Image
          src={
            restore_file ? "pictos/unknown_file.svg" : "pictos/bande son.png"
          }
        />
        <Figure.Caption>
          {restore_file ? "Fichier introuvable" : "3W15HR"}
        </Figure.Caption>
      </Figure>
    </Form.Label>
  )
}

export { PersonView }
