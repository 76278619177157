import React from "react"
import { Figure } from "react-bootstrap"

const DesktopIcon = ({
  id,
  caption,
  captionColor,
  image,
  onClick,
  className,
  classNameImage,
}) => {
  const captionStyle = captionColor ? { color: captionColor } : {}
  return (
    <Figure id={id} className={`desktop_icon ${className}`} onClick={onClick}>
      <Figure.Image
        className={`desktop_icon_image ${classNameImage}`}
        src={image}
      />
      <Figure.Caption
        className="desktop_icon_caption text-center"
        style={captionStyle}
      >
        {caption}
      </Figure.Caption>
    </Figure>
  )
}

const ForwardDesktopIcon = React.forwardRef(
  ({ id, caption, captionColor, image, onClick }, ref) => {
    const captionStyle = captionColor ? { color: captionColor } : {}
    return (
      <Figure id={id} className="desktop_icon" ref={ref} onClick={onClick}>
        <Figure.Image className="desktop_icon_image" src={image} />
        <Figure.Caption
          className="desktop_icon_caption text-center"
          style={captionStyle}
        >
          {caption}
        </Figure.Caption>
      </Figure>
    )
  }
)

export { DesktopIcon, ForwardDesktopIcon }
