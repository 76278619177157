import React from "react"
import { Image } from "react-bootstrap"

const HackerTip = ({ onAction, onTsunami, noText, hidedText }) => {
  return (
    <>
      <Image
        className="background"
        src="backgrounds/projet hacker bientot.png"
        fluid
      ></Image>
      {!noText && (
        <div className={hidedText ? "hided-text" : "hided-text-no-clip"}>
          J’ai développé ce faux jeu à la demande de mes supérieurs. Je viens de
          m’apercevoir que je n’ai été qu’un pion sur leur échiquier ! Ce n’est
          pas une escape game que vous venez de faire. Vous avez réellement
          piraté les bureaux de la Préfecture de Police et supprimé la seule
          preuve d’un complot d’Etat. Mais il n’est peut-être pas trop tard :
          trouvez dans les fichiers de la police, les éléments qui permettront
          de déjouer cette conspiration. Ils se méfient de moi, je suis
          constamment surveillée, ils ont déjà assassiné mon mari, je dois
          protéger mes enfants. Leur pouvoir de contrôle est immense : chacun de
          mes mots est scanné et peut déclencher l’alerte s’il concerne cette
          affaire. Le seul indice dont je dispose est un nom que je ne peux vous
          communiquer tel quel. Je l’appellerai donc François Perier. Trouvez le
          vrai nom de cet acteur sur internet, puis cherchez son homonyme dans
          les fichiers de la police. Pour vous aider, j’ai contacté
          l’organisation d’activistes <TsunamiText onClick={onTsunami} />. Notre
          démocratie est attaquée, vous en êtes les derniers remparts...
        </div>
      )}
    </>
  )
}

const TsunamiText = ({ onClick }) =>
  onClick ? (
    <>
      <span id="tsunami" onClick={onClick} style={{ cursor: "pointer" }}>
        TSUNAMI
      </span>
      . Cliquez sur le mot en rouge pour leur donner accès à vos ordinateurs
    </>
  ) : (
    <span id="tsunami">TSUNAMI</span>
  )

export default HackerTip
