import React, { useContext } from "react"
import { Col, Row, Form, Button } from "react-bootstrap"

import { searchDb as searchPersonDb } from "../db/PersonDb"
import { searchDb as searchAddressDb } from "../db/AddressDb"
import { GlobalContext } from "../ContextProvider"

const CellTrackingForm = ({ onAction }) => {
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity()) {
      // check form content
      const ref = "0612339089"
      if (event.target.number.value === ref) {
        onAction({
          type: "game",
          location: "CellTrackingForm",
          result: "success",
        })
      } else {
        onAction({
          type: "game",
          location: "CellTrackingForm",
          result: "error",
        })
      }
    }
  }

  return (
    <div className="cell-tracking-form">
      <Form className="forms" onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Form.Text>Entrer le numéro à rechercher :</Form.Text>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="4" htmlFor="number">
            Numéro
          </Form.Label>
          <Col sm="8">
            <Form.Control
              id="number"
              name="number"
              required
              type="text"
              placeholder="06xxxxxxxx"
              maxLength="10"
            />
          </Col>
        </Form.Group>
        <Form.Row>
          <Col sm="12">
            <Button variant="primary" type="submit" block>
              Valider la recherche
            </Button>
            <Form.Text style={{ color: "white" }}>
              Note technique :<br />
              Le bornage téléphonique permet d'identifier une zone (jaune) dont
              la superficie dépend du nombre d’antennes des opérateurs.
              <br />
              <b>
                NB1: Vérifier que la ligne est ouverte. Pour cela il suffit
                d'appeler.
              </b>
            </Form.Text>
          </Col>
        </Form.Row>
      </Form>
    </div>
  )
}

const VehicleRegLookupForm = ({ onAction }) => {
  const onChange = (event) => {
    const len = event.target.value.length
    if (len === 3 || len === 7)
      event.target.value = event.target.value.slice(0, len - 2)
    else if (len === 2 || len === 6) event.target.value += "-"
    event.target.value = event.target.value.toUpperCase()
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity()) {
      // check form content
      const ref = "VA-338-WE"
      if (event.target.number.value.toUpperCase() === ref) {
        onAction({
          type: "game",
          location: "VehicleRegLookupForm",
          result: "success",
        })
      } else {
        onAction({
          type: "game",
          location: "VehicleRegLookupForm",
          result: "error",
        })
      }
    }
  }

  return (
    <div>
      <Form className="forms" onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Form.Text className="text-muted">
            Recherche par plaque minéralogique :
          </Form.Text>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="4" htmlFor="number">
            Immatriculation
          </Form.Label>
          <Col sm="8">
            <Form.Control
              id="number"
              name="number"
              required
              type="text"
              pattern="^[A-Z]{2} ?- ?\d{3} ?- ?[A-Z]{2}$"
              maxLength={9}
              onChange={onChange}
              onPaste={(e) => {
                e.preventDefault()
                return false
              }}
              onDrop={(e) => {
                e.preventDefault()
                return false
              }}
              autoComplete="off"
            />
          </Col>
        </Form.Group>
        <Form.Row>
          <Col sm="12">
            <Button variant="primary" type="submit" block>
              Lancer la recherche
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </div>
  )
}

const ArchiveLookupForm = ({ onAction }) => {
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity()) {
      // check form content
      const ref = "3W15HR"
      if (event.target.ref.value.toUpperCase() === ref) {
        onAction({
          type: "game",
          location: "ArchiveLookupForm",
          result: "success",
        })
      } else {
        onAction({
          type: "game",
          location: "ArchiveLookupForm",
          result: "error",
        })
      }
    }
  }

  return (
    <div>
      <Form className="forms" onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Form.Text className="text-muted">
            Entrer la référence de l'archive recherchée, puis valider :
          </Form.Text>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="4" htmlFor="ref">
            Référence
          </Form.Label>
          <Col sm="8">
            <Form.Control
              id="ref"
              name="ref"
              required
              type="text"
              maxLength={6}
              placeholder="ref"
            />
          </Col>
        </Form.Group>
        <Form.Row>
          <Col sm="12">
            <Button variant="primary" type="submit" block>
              Valider la recherche
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </div>
  )
}

const PersonLookupForm = ({ onAction, scenario }) => {
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity()) {
      // check form content
      const result = searchPersonDb(
        event.target.firstname.value,
        event.target.lastname.value
      )
      if (result) {
        onAction({
          type: "game",
          location: "PersonLookupForm",
          result: { success: result },
        })
      } else {
        onAction({
          type: "game",
          location: "PersonLookupForm",
          result: "error",
        })
      }
    }
  }

  return (
    <div>
      <Form className="forms" onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Form.Text className="text-muted">
            Entrer les nom et prénom de l'individu recherché :
          </Form.Text>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="firstname">
            Prénom
          </Form.Label>
          <Col sm="9">
            <Form.Control
              id="firstname"
              name="firstname"
              required
              type="text"
              placeholder="prénom"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="lastname">
            Nom
          </Form.Label>
          <Col sm="9">
            <Form.Control
              id="lastname"
              name="lastname"
              required
              type="text"
              placeholder="nom"
            />
          </Col>
        </Form.Group>
        <Form.Row>
          <Col sm="12">
            <Button variant="primary" type="submit" block>
              Valider la recherche
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </div>
  )
}

const AddressLookupForm = ({ onAction }) => {
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity()) {
      // check form content
      const result = searchAddressDb(
        event.target.number.value,
        event.target.street.value,
        event.target.city.value
      )
      if (result) {
        onAction({
          type: "game",
          location: "AddressLookupForm",
          result: { success: result },
        })
      } else {
        onAction({
          type: "game",
          location: "AddressLookupForm",
          result: { error: "bad address" },
        })
      }
    }
  }

  return (
    <div>
      <Form className="forms" onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Form.Text className="text-muted">
            Entrer l'adresse puis valider pour chercher le dossier correspondant
            :
          </Form.Text>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="number">
            N°
          </Form.Label>
          <Col sm="9">
            <Form.Control
              id="number"
              name="number"
              required
              type="text"
              placeholder="numéro"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="street">
            Rue
          </Form.Label>
          <Col sm="9">
            <Form.Control
              id="street"
              name="street"
              required
              type="text"
              placeholder="nom de la rue"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="city">
            Ville/CP
          </Form.Label>
          <Col sm="9">
            <Form.Control
              id="city"
              name="city"
              required
              type="text"
              placeholder="nom de la ville ou CP"
            />
          </Col>
        </Form.Group>
        <Form.Row>
          <Col sm="12">
            <Button variant="primary" type="submit" block>
              Valider la recherche
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </div>
  )
}

const EmergencyInterForm = ({ onAction }) => {
  const globalContext = useContext(GlobalContext)
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity()) {
      // check form content
      const result = searchAddressDb(
        event.target.number.value,
        event.target.street.value,
        event.target.city.value
      )
      if (result) {
        if (
          result.id !== 1 ||
          !Boolean(
            globalContext.scenario.desktop?.alpha?.folders?.A_DOC_2BIS
              ?.visible &&
              globalContext.scenario.desktop?.delta?.folders?.D_DOC_2BIS
                ?.visible
          )
        ) {
          onAction({
            type: "game",
            location: "EmergencyInterForm",
            result: { success: result },
          })
        } else {
          // Intervention redondante : intercepted
          onAction({
            type: "game",
            location: "EmergencyInterForm",
            result: { redundant: true },
          })
        }
      } else {
        onAction({
          type: "game",
          location: "EmergencyInterForm",
          result: { error: "bad address" },
        })
      }
    }
  }

  return (
    <div>
      <Form className="forms" onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Form.Text className="text-muted">
            Adresse où envoyer l'intervention :
          </Form.Text>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="number">
            N°
          </Form.Label>
          <Col sm="9">
            <Form.Control
              id="number"
              name="number"
              required
              type="text"
              placeholder="numéro"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="street">
            Rue
          </Form.Label>
          <Col sm="9">
            <Form.Control
              id="street"
              name="street"
              required
              type="text"
              placeholder="nom de la rue"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" htmlFor="city">
            CP ou Ville
          </Form.Label>
          <Col sm="9">
            <Form.Control
              id="city"
              name="city"
              required
              type="text"
              placeholder="nom de la ville"
            />
          </Col>
        </Form.Group>
        <Form.Row className="submit-row">
          <Col sm="12">
            <Button variant="primary" type="submit" block>
              Valider la demande d'intervention
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </div>
  )
}

export {
  CellTrackingForm,
  VehicleRegLookupForm,
  ArchiveLookupForm,
  PersonLookupForm,
  AddressLookupForm,
  EmergencyInterForm,
}
