import React, { useEffect, useState } from "react"
//import { Image } from "react-bootstrap"
import axios from "axios"

import {
  GAME_VERSION_URI,
  GAME_CHECK_URI,
  getServerURL,
  postGameUpdate,
  getURLParam,
} from "../utils"

const DEBUG = false

const HackerPage = ({ onConnect }) => {
  const [token, setToken] = useState(null)

  const fetchData = async () => {
    // retrieve the team token
    const team_token = getURLParam("token")
    const score = getURLParam("score")
    const reset_token = getURLParam("reset")
    try {
      if (team_token) {
        // and check server game version
        const axios_res = await axios.get(getServerURL() + GAME_VERSION_URI)
        const axios_res_csrftoken = axios_res.data.csrftoken
        // then retrieve the game informations for this team
        const postUrl = getServerURL() + GAME_CHECK_URI
        const formData = new FormData()
        formData.append("token", team_token)
        const post_res = await axios.post(postUrl, formData, {
          headers: {
            "X-CSRFToken": axios_res_csrftoken,
          },
        })
        if (post_res.data.error) {
          setToken("error")
          console.warn("Error synchronizing: ", post_res.data.error)
        } else {
          // connect the team websocket
          setToken("synchro")
          onConnect({ ...post_res.data, finalScore: score })
        }
      } else {
        setToken("absence")
        score && onConnect({ finalScore: score })
      }
      if (reset_token) {
        setToken("reset")
        // then retrieve the game informations for this team
        const axios_res = await axios.get(getServerURL() + GAME_VERSION_URI)
        const axios_res_csrftoken = axios_res.data.csrftoken
        const postUrl = getServerURL() + GAME_CHECK_URI
        const formData = new FormData()
        formData.append("token", reset_token)
        const post_res = await axios.post(postUrl, formData, {
          headers: {
            "X-CSRFToken": axios_res_csrftoken,
          },
        })
        postGameUpdate(post_res.data.game_id, "reset")
      }
    } catch (error) {
      setToken("error")
      DEBUG && console.log(error)
      score && onConnect({ finalScore: score })
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="hacker_div">
      <div id="hacker_synch">
        <h2 className="blink_me">
          {token === "reset" ? "REINITIALISATION..." : "SYNCHRONISATION..."}
        </h2>
        {token === "absence" ? (
          <h5>Pas de jeton de jeu.</h5>
        ) : token === "synchro" ? (
          <h5>En attente de l'autre équipe...</h5>
        ) : token === "error" ? (
          <h5>Erreur de jeton</h5>
        ) : token === "reset" ? (
          <h5>Vous pouvez relancer le jeu.</h5>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default HackerPage
