const getDateStringMinusDay = (daysoff) => {
  let dd = Date.now() - daysoff * 24 * 3600 * 1000
  return new Date(dd).toLocaleDateString()
}

const getAge = (birthday) => {
  var ageDifMs = Date.now() - birthday
  var ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

const getStringNorm = (string) =>
  string
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")

export { getDateStringMinusDay, getAge, getStringNorm }
