import React, { useEffect, useState } from "react"

import { createChatBotMessage } from "react-chatbot-kit"

import DesktopDirecteur from "./DesktopDirecteur"
import { DesktopAnalyste } from "./DesktopAnalyste"
import DesktopWindow from "./DesktopWindow"

import { WindowsMapping } from "./windows/WindowsMapping"
import { EscapeBot } from "./chatbot/Chatbot"
import { AudioToolBlink } from "./GameActions"
import { AddressDocs } from "./db/AddressDb"
import { getGameStats, postGameUpdate, getRandomInt } from "../utils"

import { GlobalContext, MessagesContext } from "./ContextProvider"

const DEBUG = false

const CellTrackingFormErrorTexts = [
  "Impossibilité de tracer ce numéro.",
  "Ce numéro de téléphone n'est pas attribué.",
  "Ce numéro est attribué à un téléphone prépayé. Impossible de le localiser.",
]
const AddressLookupFormErrorTexts = [
  "Adresse inconnue.",
  "0 résultat.",
  "Cette adresse n'existe plus.",
]

const DesktopPage = ({ gameId, teamTag, wsSend, gameAction, spectator }) => {
  const [scenario, setScenario] = useState({})
  const [currentStep, setCurrentStep] = useState(0)
  const [login, setLogin] = useState({ failure: "       " })
  const [escWindow, setEscWindow] = useState(null)
  const [subWindow, setSubWindow] = useState(null)
  const [iframe, setIframe] = useState(null)
  const [customView, setCustomView] = useState(null)
  const [customViewComponent, setCustomViewComponent] = useState(null)
  const [deletePictoWithHelp, setDeletePictoWithHelp] = useState(null)
  const [interventionFailed, setInterventionFailed] = useState(0)
  const [interventionFailedAudio, setInterventionFailedAudio] = useState(0)
  const [displayBot, toggleBot] = useState(true)
  /*
  const [messages, setMessages] = useState([
    createChatBotMessage(null, {
      withAvatar: false,
      widget: "cheatsheet",
    }),
  ])
  */
  const [chatbotWidget, setChatbotWidget] = useState(null)
  const [loadedChatBotMessages, setLoadedChatBotMessages] = useState(null)

  const loadChatBotMessages = (scenario) => {
    if (DEBUG) console.log(`CALL LOAD  team ${teamTag} MESSAGES: `, scenario)
    let messages = []
    if (scenario && "messages" in scenario.desktop[teamTag])
      messages = scenario.desktop[teamTag].messages
    if (messages.length === 0) {
      messages.push(
        createChatBotMessage(null, {
          withAvatar: false,
          widget: "cheatsheet",
        })
      )
    }
    if (DEBUG) console.log("Set LOADEDCHATMESSAGE to ", messages)
    setLoadedChatBotMessages(messages)
  }

  const saveChatBotMessages = (messages) => {
    if (DEBUG)
      console.log(`CALL SAVE CHATBOT MESSAGES for team ${teamTag}: `, messages)
    setScenario((prev) => ({
      ...prev,
      desktop: {
        ...prev.desktop,
        [teamTag]: { ...prev.desktop[teamTag], messages: messages },
      },
    }))
    // very special message for backend to update only messages on specific team
    saveScenarioPart({
      team: teamTag,
      messages: messages,
    })
  }

  const setCBMessages = (messages) => {
    if (DEBUG) console.log(` Messages update for team ${teamTag}: `, messages)
    saveChatBotMessages(messages)
  }

  const notifyIndice = (quest, resp, widget) => {
    wsSend({
      topic: "game",
      action: "notify_indice",
      params: { team: teamTag, quest: quest, resp: resp, widget: widget },
    })
  }

  const getObjectId = (object, id) => {
    var result = undefined
    if (object && typeof object === "object") {
      if (object.id === id) return object
      for (let val of Object.values(object)) {
        if (typeof val === "object") {
          result = getObjectId(val, id)
          if (result) break
        }
      }
    }
    return result
  }

  const onWindowClose = () => {
    setEscWindow(null)
  }

  const onSubWindowClose = () => {
    setSubWindow(null)
  }

  const onIframeClose = () => {
    setIframe(null)
  }

  // this one will happen only on Analyst team
  const updateSurvey = ({ id, row, value }) => {
    const id_data = [...scenario.survey[id].data]
    if (row) {
      id_data[row].choice = value
    } else {
      id_data[0].choice = value
    }
    const newSurvey = {
      ...scenario.survey,
      [id]: {
        ...scenario.survey[id],
        data: id_data,
      },
    }
    setScenario((prev) => ({
      ...prev,
      survey: newSurvey,
    }))
    wsSend({
      topic: "game",
      action: "update_survey",
      params: newSurvey,
    })
  }

  useEffect(() => {
    getGameStats(gameId).then((res) => {
      // check server side stored data
      if (Object.entries(res).length === 0) {
        // if empty, load the frontside version of the scenario
        fetch("scenario.json")
          .then((resp) => resp.json())
          .then((json) => {
            // set local version
            setScenario(json)
            loadChatBotMessages(json)
            // and save to backend
            postGameUpdate(gameId, json)
          })
      }
      // set the stored scenario to application
      else {
        const crtStep = res.desktop[teamTag].step
        setScenario(res)
        loadChatBotMessages(res)
        setCurrentStep(crtStep)
        if (crtStep > 0) setLogin({ success: true })
        if (crtStep > 2)
          setChatbotWidget({
            widget: "tsunami_help",
            step: res.steps[res.step_list[crtStep]],
            setMessage: setCBMessages,
            cbMessage: notifyIndice,
          })
        // restart the game from one of a mini game
        if (res.step_list[crtStep] === "mg1") {
          // launch mini game 1 : deminage
          setCustomView({
            id: "minigame_deminage",
            params: {
              team: teamTag,
              setWindow: setEscWindow,
              setScenario: setScenario,
              wsSend: wsSend,
            },
          })
        } else if (res.step_list[crtStep] === "mg2") {
          // launch mini game 2 : crackage
          setCustomView({
            id: "minigame_crackage",
            params: {
              team: teamTag,
              wsSend: wsSend,
              status: 0,
            },
          })
        } else if (res.step_list[crtStep] === "mg3") {
          // launch mini game 3 : piratage
          setCustomView({
            id: "minigame_piratage",
            params: {
              team: teamTag,
              setChatbotWidget: setChatbotWidget,
              toggleBot: toggleBot,
              wsSend: wsSend,
              unlockStep: 0,
            },
          })
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // FX used when game status changed from web socket
  // potential BUG with web socket: sometime, a slight desynchro can
  // override the current CustomView of one team when an action is still running in the view (audio playback for instance)
  useEffect(() => {
    if (!gameAction) return
    // indice notification on other teams
    if (gameAction.action === "notify_indice") {
      if (gameAction.params.team !== teamTag) {
        // update other team chatbot of any indice triggering
        setChatbotWidget({
          widget: "tsunami_help",
          message: gameAction.params,
        })
      }
    }
    // update scenario step synchroneously for all team
    else if (gameAction.action === "update_scenario_step") {
      const stepIdx = gameAction.params.stepIdx
      if (DEBUG) console.log(`UPDATE STEP from ${currentStep} to ${stepIdx}`)
      setScenario((prev) => ({
        ...prev,
        desktop: {
          ...prev.desktop,
          [teamTag]: { ...prev.desktop[teamTag], step: stepIdx },
        },
      }))
      setCurrentStep(stepIdx)
      setTimeout(
        () =>
          setChatbotWidget({
            widget: "tsunami_help",
            step: scenario.steps[scenario.step_list[stepIdx]],
            setMessage: setCBMessages,
            cbMessage: notifyIndice,
          }),
        2000
      )
    } else if (gameAction.action === "login_failed" && teamTag === "alpha") {
      setLogin({ failure: gameAction.params })
    } else if (gameAction.action === "login_success") {
      setLogin({ success: true })
      //step 1: archive
      updateScenarioStep("archive")
    } else if (gameAction.action === "delete_success") {
      setEscWindow(null)
      setSubWindow(null)
      setCustomView({
        id: "msdos_mission_ok",
        params: { help: gameAction.params, team: teamTag },
      })
    } else if (gameAction.action === "tsunami_move" && teamTag === "alpha") {
      // this game action is only for Analyst desk
      // update CustomView if not already removed
      // it can still have websocket message to process after view deletion
      if (customView)
        setCustomView((prevState) => ({
          ...prevState,
          params: {
            ...prevState.params,
            x: gameAction.params.x,
            y: gameAction.params.y,
          },
        }))
    } else if (gameAction.action === "tsunami_success") {
      setCustomView(null)
      // enable picto to display hidden message
      setScenario((prev) => ({
        ...prev,
        desktop: {
          ...prev.desktop,
          alpha: {
            ...prev.desktop.alpha,
            folders: {
              ...prev.desktop.alpha.folders,
              HACK_TIP: {
                ...prev.desktop.alpha.folders.HACK_TIP,
                visible: true,
              },
            },
          },
          delta: {
            ...prev.desktop.delta,
            folders: {
              ...prev.desktop.delta.folders,
              HACK_TIP: {
                ...prev.desktop.delta.folders.HACK_TIP,
                visible: true,
              },
            },
          },
          CHATBOT: {
            ...prev.desktop.CHATBOT,
            visible: true,
          },
        },
      }))
      toggleBot(true)
      setTimeout(() => setChatbotWidget("tsunami_widget"), 1000)
      // step pillu
      updateScenarioStep("pillu")
    } else if (gameAction.action === "emergency_intervention") {
      // bad address can be because of an error or because of not the right known address
      let params = {}
      // final intervention audio: play the final soundtrack
      if ("final" in gameAction.params) {
        params = gameAction.params
        // step report
        updateScenarioStep("report")
      }
      // intervention at 2bis address
      else if (
        "success" in gameAction.params &&
        gameAction.params.success.id === 1
      ) {
        params = gameAction.params.success
      }
      // intervention at louise chenu -> survey
      else if (
        "success" in gameAction.params &&
        gameAction.params.success.id === 2
      ) {
        params = gameAction.params.success
        // check survey picto on desktop
        setScenario((prev) => ({
          ...prev,
          desktop: {
            ...prev.desktop,
            alpha: {
              ...prev.desktop.alpha,
              folders: {
                ...prev.desktop.alpha.folders,
                A_SURVEY: {
                  ...prev.desktop.alpha.folders.A_SURVEY,
                  visible: true,
                },
              },
            },
            delta: {
              ...prev.desktop.delta,
              folders: {
                ...prev.desktop.delta.folders,
                D_SURVEY: {
                  ...prev.desktop.delta.folders.D_SURVEY,
                  visible: true,
                },
              },
            },
          },
        }))
        toggleBot(true)
        setTimeout(() => setChatbotWidget("robirez_widget"), 500)
        // step survey
        updateScenarioStep("survey")
      }
      // intervention at another known address -> failed
      else if ("success" in gameAction.params) {
        params = gameAction.params.success
        setInterventionFailed((prev) => prev + 1)
      }
      // intervention failed : bad address -> failed
      else {
        params = { error: interventionFailedAudio }
        setInterventionFailed((prev) => prev + 1)
        setInterventionFailedAudio((prev) => prev + 1)
      }
      // display the custom intervention component
      // can be a sound or the form
      setTimeout(() => {
        setCustomView({
          id: "emergency_inter_result",
          params: {
            team: teamTag,
            address: params,
            updateSurvey: updateSurvey,
          },
        })
      }, 500)
    } else if (
      gameAction.action === "moreno_audio_assist" &&
      teamTag === "alpha"
    ) {
      // red blinking of analyst audio cleaning tool
      AudioToolBlink(setScenario)
    } else if (gameAction.action === "update_audio_mix") {
      if (gameAction.params.cleared) {
        // set audio file as beeing cleared
        setScenario((prev) => ({
          ...prev,
          desktop: {
            ...prev.desktop,
            alpha: {
              ...prev.desktop.alpha,
              folders: {
                ...prev.desktop.alpha.folders,
                AUDIO_MORENO: {
                  ...prev.desktop.alpha.folders.AUDIO_MORENO,
                  cleared: true,
                  visible: true,
                },
              },
            },
            delta: {
              ...prev.desktop.delta,
              folders: {
                ...prev.desktop.delta.folders,
                AUDIO_MORENO: {
                  ...prev.desktop.delta.folders.AUDIO_MORENO,
                  cleared: true,
                },
              },
            },
          },
        }))
        // step travel
        updateScenarioStep("travel")
        // dynamically update audio if window player is open
        if (teamTag === "delta" && customView)
          setCustomView((prevState) => ({
            ...prevState,
            params: {
              ...prevState.params,
              cleared: true,
            },
          }))
      }
      // update the audio playback ...
      else if (teamTag === "delta" && customView) {
        const cleared = scenario.desktop.delta.folders.AUDIO_MORENO.cleared
        setCustomView((prevState) => ({
          ...prevState,
          params: {
            ...prevState.params,
            ...gameAction.params,
            cleared: cleared,
          },
        }))
      }
    } else if (gameAction.action === "update_survey" && teamTag === "delta") {
      setScenario((prev) => ({
        ...prev,
        survey: gameAction.params,
      }))
    } else if (gameAction.action === "minigame_deminage") {
      // save the survey result also on alpha team scenario structure
      if (teamTag === "alpha")
        setScenario((prev) => ({
          ...prev,
          survey: {
            ...prev.survey,
            results: gameAction.params,
          },
        }))
      // launch mini game 1 : deminage
      setCustomView({
        id: "minigame_deminage",
        params: {
          team: teamTag,
          setWindow: setEscWindow,
          setScenario: setScenario,
          wsSend: wsSend,
        },
      })
      // step minigame1
      updateScenarioStep("mg1")
    } else if (gameAction.action === "minigame_deminage_end") {
      setScenario((prev) => ({
        ...prev,
        scoring: { ...prev.scoring, miniGame1: gameAction.params.success },
      }))
      setEscWindow(null)
      setSubWindow(null)
      setIframe(null)
      if (teamTag === "alpha")
        setCustomView((prevState) => ({
          ...prevState,
          params: {
            ...prevState.params,
            ...gameAction.params,
          },
        }))
    } else if (gameAction.action === "minigame_crackage") {
      // launch mini game 2 : crackage
      setCustomView({
        id: "minigame_crackage",
        params: {
          team: teamTag,
          wsSend: wsSend,
          status: 0,
        },
      })
      // step minigame2
      updateScenarioStep("mg2")
    } else if (gameAction.action === "minigame_crackage_start") {
      // synchronize game starting: the first team that start the game
      // trigger the launch of the game/timer for the other team !
      setCustomView((prevState) => ({
        ...prevState,
        params: {
          ...prevState.params,
          status: 1,
        },
      }))
    } else if (gameAction.action === "minigame_crackage_success") {
      // synchronize game success: both teams have to finish the game
      // before the timeout to win this step !
      setCustomView((prevState) => ({
        ...prevState,
        params: {
          ...prevState.params,
          status: prevState.params.status + 1,
        },
      }))
    } else if (gameAction.action === "minigame_piratage") {
      // launch mini game 3 : piratage
      setCustomView({
        id: "minigame_piratage",
        params: {
          team: teamTag,
          setChatbotWidget: setChatbotWidget,
          toggleBot: toggleBot,
          wsSend: wsSend,
          unlockStep: 0,
        },
      })
      // step minigame3
      updateScenarioStep("mg3")
    } else if (gameAction.action === "minigame_piratage_success") {
      // synchronize mini game 3 steps
      const nextStep = gameAction.params.unlockStep
      if (nextStep <= 4)
        setCustomView((prevState) => ({
          ...prevState,
          params: {
            ...prevState.params,
            unlockStep: nextStep,
            matrixCellId: gameAction.params.cellId,
          },
        }))
    } else if (gameAction.action === "final_report") {
      // display the final report component on both desktop
      // load full game stats from backend
      getGameStats(gameId).then((res) => {
        const final = res ? { final: res } : { ...gameAction.params }
        setTimeout(
          () =>
            setCustomView({
              id: "final_report",
              params: { team: teamTag, ...final },
            }),
          1000
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameAction, teamTag])

  // This is called from a onWindow action prop to update game status
  const onGameAction = (event) => {
    // STEP MASTERMIND LOGIN
    if (event.location === "Login") {
      if (event.result === "success") {
        wsSend({
          topic: "game",
          action: "login_success",
        })
      } else {
        // login error typed passwd is in event.result.failed
        setLogin({ failure: event.result.failed })
        wsSend({
          topic: "game",
          action: "login_failed",
          params: event.result.failed,
        })
      }
    }
    // CELL PHONE TRACKING LOOKUP
    else if (event.location === "CellTrackingForm") {
      if (event.result === "success") {
        // display the cell phone localization map
        setCustomView({ id: "localize_cellphone" })
        // step cellTracking
        updateScenarioStep("cellTracking")
      } else {
        // error in lookup form
        setSubWindow({
          title: "Mauvais numéro",
          text: CellTrackingFormErrorTexts[
            getRandomInt(CellTrackingFormErrorTexts.length)
          ],
          style: { top: "5em", left: "21em", width: "12em", height: "8em" },
        })
      }
    }
    // VEHICLE REGISTRATION LOOKUP
    else if (event.location === "VehicleRegLookupForm") {
      if (event.result === "success") {
        // display the city cam report with custom date inside
        setCustomView({
          id: "citycam_report",
        })
        //step vehicleReg
        updateScenarioStep("vehicleReg")
      } else {
        // error in lookup form
        setSubWindow({
          title: "Mauvaise plaque",
          text: "0 résultat.",
          style: { top: "5em", left: "21em", width: "12em", height: "6.5em" },
        })
      }
    }
    // STEP CITYCAM REPORT
    else if (event.location === "CityCamReport") {
      if (event.result === "close") {
        setCustomView(null)
      }
    }
    // STEP ARCHIVE LOOKUP
    else if (event.location === "ArchiveLookupForm") {
      if (event.result === "success" && scenario.desktop.delta.step <= 2) {
        // display the lockee modal
        setCustomView({ id: "picto_to_delete" })
      } else if (scenario.desktop.delta.step > 2) {
        // result already processed
        setSubWindow({
          title: "Erreur",
          text: "Dossier déjà traité.",
          style: { top: "5em", left: "21em", width: "12em", height: "6.5em" },
        })
      } else {
        // error in lookup form
        setSubWindow({
          title: "Erreur",
          text: "0 résultat.",
          style: { top: "5em", left: "21em", width: "12em", height: "6.5em" },
        })
      }
    }
    // STEP PERSON LOOKUP
    else if (event.location === "PersonLookupForm") {
      if (event.result instanceof Object && "success" in event.result) {
        setCustomView({
          id: "person_view",
          params: event.result.success,
        })
        if (event.result.success.id === 1) {
          // Francois Pillu sheet: step morse
          updateScenarioStep("morse")
        } else if (event.result.success.id === 4) {
          // Floriane Moreno sheet: step moreno
          updateScenarioStep("moreno")
        } else if (event.result.success.id === 8) {
          // Leonardo Robirez sheet: step robirez
          updateScenarioStep("robirez")
        }
      } else {
        // error in lookup form
        // TODO implement different error messages
        setSubWindow({
          title: "Erreur",
          text: "0 résultat",
          style: { top: "6em", left: "20em", width: "12em", height: "6.5em" },
        })
      }
    }
    // STEP PERSON VIEW
    else if (event.location === "PersonView") {
      if (event.result === "close") {
        setCustomView(null)
      }
    }
    // STEP SURVEY VIEW
    else if (event.location === "SurveyView") {
      if (event.result === "close") {
        setCustomView(null)
      } else if (event.result === "open" && customView === null) {
        setCustomView({
          id: "emergency_inter_survey",
          params: {
            team: teamTag,
            updateSurvey: updateSurvey,
          },
        })
      }
    } else if (event.location === "SubmitSurvey") {
      // this message is only from DELTA desktop
      if (DEBUG) console.log("Survey result is : ", event.result.success)
      // remove the survey form
      // setCustomView(null)
      // save survey results in scenario (Delta team)
      setScenario((prev) => ({
        ...prev,
        survey: {
          ...prev.survey,
          results: event.result.success,
        },
      }))
      // start mini game 1: deminage
      wsSend({
        topic: "game",
        action: "minigame_deminage", // minigame_deminage
        params: event.result.success, // send the survey result for alpha
      })
    }
    // STEP MINI GAME 1 END (DEMINAGE)
    else if (event.location === "DeminageEnd") {
      if (teamTag === "delta")
        wsSend({
          topic: "game",
          action: "minigame_crackage", // launch minigame 2: crackage
          params: {},
        })
    }
    // STEP MINI GAME 2 END (CRACKAGE)
    else if (event.location === "CrackageEnd") {
      setScenario((prev) => ({
        ...prev,
        scoring: {
          ...prev.scoring,
          miniGame2: event.result.success,
        },
      }))
      if (teamTag === "delta")
        wsSend({
          topic: "game",
          action: "minigame_piratage", // launch minigame 3: piratage
          params: {},
        })
    }
    // STEP MINI GAME 3 END (PIRATAGE)
    else if (event.location === "PiratageEnd") {
      setScenario((prev) => ({
        ...prev,
        desktop: {
          ...prev.desktop,
          CHATBOT: {
            ...prev.desktop.CHATBOT,
            visible: false,
          },
        },
        scoring: {
          ...prev.scoring,
          miniGame3: event.result.success,
        },
      }))
      // this wsSend has to be called after mini_game_three
      if (teamTag === "delta")
        wsSend({
          topic: "game",
          action: "emergency_intervention", // final intervention
          params: { final: scenario.survey.results },
        })
      // setCustomView(null)
    }
    // STEP MORENO PERSON FILE RESTORE
    else if (event.location === "MorenoFileRestore") {
      if (event.result.success) {
        // update the "Moreno_Need_Restore_File" flag in scenario
        setScenario((prev) => ({
          ...prev,
          desktop: {
            ...prev.desktop,
            delta: {
              ...prev.desktop.delta,
              folders: {
                ...prev.desktop.delta.folders,
                Moreno_Need_Restore_File: false,
              },
            },
          },
        }))
      }
    }
    // STEP MORENO AUDIO FILE CLICK
    else if (event.location === "MorenoAudioClick") {
      if (
        event.result.success &&
        (customView === null || customView.id === "person_view")
      ) {
        const cleared = scenario.desktop[teamTag].folders.AUDIO_MORENO.cleared
        setCustomView({
          id: "mixage_audio_delta",
          params: { team: teamTag, cleared: cleared },
        })
        // enable picto to display moreno audio file
        // only display moreno sound on delta at first (before clean)
        setScenario((prev) => ({
          ...prev,
          desktop: {
            ...prev.desktop,
            delta: {
              ...prev.desktop.delta,
              folders: {
                ...prev.desktop.delta.folders,
                AUDIO_MORENO: {
                  ...prev.desktop.delta.folders.AUDIO_MORENO,
                  visible: true,
                },
              },
            },
          },
        }))
        // step audio
        updateScenarioStep("audio")
      }
    } else if (event.location === "MorenoAudioClose") {
      setCustomView(null)
    }
    // STEP MORENO AUDIO FILE CLICK
    else if (event.location === "MorenoAudioAssist") {
      wsSend({
        topic: "game",
        action: "moreno_audio_assist",
      })
    }
    // STEP ADDRESS LOOKUP
    else if (event.location === "AddressLookupForm") {
      if (event.result.success) {
        const docs = AddressDocs.find(
          (address) => address.id === event.result.success.id
        )
        if (teamTag === "alpha") {
          setSubWindow({
            id: "recherche_cadastrale",
            title: "Rapport Cadastral",
            type: "app",
            url: docs.cadastre + "?#zoom=85&scrollbar=0&toolbar=1&navpanes=0",
            style: {
              width: "30em",
              height: "19em",
              top: "2em",
              left: "10em",
            },
          })
        } else {
          // team "delta"
          setSubWindow({
            id: "recherche_propriete",
            title: "Historique des Propriétaires",
            type: "app",
            url: docs.owner + "?#zoom=85&scrollbar=0&toolbar=1&navpanes=0",
            style: {
              width: "26.5em",
              height: "26em",
              top: "0.5em",
              left: "14em",
            },
          })
        }
        if (docs.id === 1) {
          // step property2bis
          updateScenarioStep("property2bis")
        }
      } else {
        // error in lookup form
        setSubWindow({
          title: "Adresse inconnue",
          text: AddressLookupFormErrorTexts[
            getRandomInt(AddressLookupFormErrorTexts.length)
          ],
          style: { top: "6em", left: "20em", width: "12em", height: "6.5em" },
        })
      }
    }
    // STEP Emergency intervention
    else if (event.location === "EmergencyInterForm") {
      // transfer emergency form to web socket
      if (event.result.redundant) {
        setSubWindow({
          title: "Erreur",
          text: "Intervention déjà réalisée.",
          style: {
            top: "4em",
            left: "23em",
            width: "12em",
            height: "6.5em",
          },
        })
      } else {
        wsSend({
          topic: "game",
          action: "emergency_intervention",
          params: event.result,
        })
        setEscWindow(null)
      }
    } else if (event.location === "EmergencyAudioEnd") {
      if (event.result.success && event.result.address.id === 1) {
        setCustomView(null)
        // after intervention at 2Bis, display found documents
        // enable picto to display moreno audio file
        setScenario((prev) => ({
          ...prev,
          desktop: {
            ...prev.desktop,
            alpha: {
              ...prev.desktop.alpha,
              folders: {
                ...prev.desktop.alpha.folders,
                A_DOC_2BIS: {
                  ...prev.desktop.alpha.folders.A_DOC_2BIS,
                  visible: true,
                },
              },
            },
            delta: {
              ...prev.desktop.delta,
              folders: {
                ...prev.desktop.delta.folders,
                D_DOC_2BIS: {
                  ...prev.desktop.delta.folders.D_DOC_2BIS,
                  visible: true,
                },
              },
            },
          },
        }))
        const icon = getObjectId(
          scenario,
          teamTag === "alpha" ? "A_DOC_2BIS" : "D_DOC_2BIS"
        )
        setEscWindow(icon.window)
        // step interv2bis
        updateScenarioStep("interv2bis")
      }
      // END OF THE GAME! Send socket message for FINAL REPORT
      else if (event.result.final) {
        if (teamTag === "delta") {
          // launch final report display with scenario from delta team
          wsSend({
            topic: "game",
            action: "final_report", // display final report
            params: { final: scenario },
          })
        }
        // and send socket message to update game status to END OF GAME
        // send it for both team to be sure the end of game is updated
        setTimeout(
          () =>
            wsSend({
              topic: "start",
              game_id: gameId,
              game_state: "FI",
            }),
          2000
        )
      } else if (event.result.success) {
        // nullify custom view when ending an intervention audio
        setCustomView(null)
      }
    }
    // STEP LOCKEE
    else if (event.location === "Lockee") {
      if (event.result === "success") {
        const paramHelp = deletePictoWithHelp ? "with_help" : ""
        wsSend({ topic: "game", action: "delete_success", params: paramHelp })
      } else if (event.result === "timeout") {
        // display chatbot tsunami message
        setScenario((prev) => ({
          ...prev,
          desktop: {
            ...prev.desktop,
            CHATBOT: {
              ...prev.desktop.CHATBOT,
              visible: true,
            },
          },
        }))
        toggleBot(true)
        setTimeout(() => setChatbotWidget("lockee_help_widget"), 1000)
        setTimeout(
          () =>
            setChatbotWidget({
              widget: "tsunami_help",
              step: scenario.steps.lockee,
            }),
          1500
        )
      }
    }
    // STEP DELETE_FILE
    else if (event.location === "DeletePicto") {
      if (event.result === "close") {
        setCustomView(null)
      } else {
        setDeletePictoWithHelp(event.result === "success_with_help")
        setCustomView({ id: "module_lockee" })
        updateScenarioStep("lockee")
      }
    }
    // STEP CLICK ON HIDDEN MESSAGE BEHIND AD 'TSUNAMI'
    else if (event.location === "Tsunami") {
      if (event.result === "success") {
        setCustomView(null)
        wsSend({ topic: "game", action: "tsunami_success" })
      } else if (event.result.event === "mousemove") {
        // event.result maybe an objet
        wsSend({
          topic: "game",
          action: "tsunami_move",
          params: { ...event.result },
        })
      }
    } else if (event.location === "UpdateAudioMix") {
      // send the audio mixer value position to websocket
      wsSend({
        topic: "game",
        action: "update_audio_mix",
        params: { ...event.result },
      })
    }
  }

  // manage the desktop page behavior depending on user actions
  const onWindowAction = (event) => {
    if (event.type === "click") {
      if (spectator) return
      const id = event.target.parentElement.id
      const icon = getObjectId(scenario, id)
      if (!icon) return
      // special case: the mixage_audio_delta custom view is open on analyst
      // and he wants to access audio tools
      if (id === "A6" && customView && customView.id === "mixage_audio_delta")
        setCustomView(null)
      // another special case: minigame1 use CustomView but player can open
      // the minigame dedicated folder (delta only).
      else if (
        subWindow ||
        iframe ||
        (customView &&
          !(
            [
              "D_MINIGAME1",
              "A_MINIGAME1",
              "d_deminage",
              "a_deminage",
              "mine1",
              "mine2",
            ].includes(id) && customView.id === "minigame_deminage"
          ))
      )
        return
      if (icon && icon.window) {
        if (icon.type === "folder") {
          // if a folder is already open we may open the new folder in subwindow
          setEscWindow(icon.window)
        } else {
          setSubWindow(icon.window)
        }
      } else if (icon && icon.url) {
        setIframe({ title: icon.caption, url: icon.url })
      } else if (icon && icon.href) {
        window.open(icon.href, icon.caption)
      }
    } else if (event.type === "game") {
      onGameAction(event)
    }
  }

  useEffect(() => {
    if (DEBUG) console.log("Update Scenario or CustomView: ", customView)
    if (customView == null) {
      setCustomViewComponent(null)
    } else if (customView && customView.id in WindowsMapping) {
      setCustomViewComponent(
        React.createElement(WindowsMapping[customView.id], {
          onAction: onWindowAction,
          team: teamTag,
          scenario: scenario,
          params: customView.params,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario, customView])

  // Trigger action (save this player Scenario) when Scenario change step
  useEffect(() => {
    if (currentStep) {
      let content = {
        [teamTag]: { ...scenario.desktop[teamTag] },
        CHATBOT: { ...scenario.desktop.CHATBOT },
      }
      // some data are only set on delta team
      if (teamTag === "delta") {
        content = {
          ...content,
          scoring: {
            ...scenario.scoring,
            nbIntervention: interventionFailed,
          },
        }
        // after the SubmitSurvey, store the survey results to db
        // store this data only for the delta team
        if (currentStep === 14) {
          content = {
            ...content,
            survey: { ...scenario.survey },
          }
        }
      }
      saveScenarioPart(content)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  const saveScenarioPart = (content) => {
    const step = scenario.step_list
      ? scenario.step_list[currentStep]
      : "unknown"
    wsSend({
      topic: "save",
      step: step,
      scenario: content,
    })
  }

  // Set a new step in the Scenario advancement
  const updateScenarioStep = (step) => {
    const stepIdx = scenario.step_list.indexOf(step)
    if (stepIdx > currentStep)
      wsSend({
        topic: "game",
        action: "update_scenario_step",
        params: { stepIdx: stepIdx },
      })
  }

  // triggered at last audio indice
  const unlockCleanedAudio = () => {
    // unlock cleaned audio file on both desktop (last audio indice)
    wsSend({
      topic: "game",
      action: "update_audio_mix",
      params: { cleared: "cleared" },
    })
  }

  if (!scenario || !scenario.desktop) return <></>
  return (
    <GlobalContext.Provider
      value={{
        teamTag: teamTag,
        scenario: scenario,
      }}
    >
      <MessagesContext.Provider value={chatbotWidget}>
        <div id="desktop_div">
          {(!customView ||
            (customView &&
              ![
                "final_report",
                "minigame_crackage",
                "minigame_piratage",
              ].includes(customView.id))) &&
          teamTag === "alpha" ? (
            <DesktopAnalyste
              onFolderClick={onWindowAction}
              content={scenario.desktop.alpha}
              onAction={onWindowAction}
              login={login}
            />
          ) : (
            (!customView ||
              (customView &&
                ![
                  "final_report",
                  "minigame_crackage",
                  "minigame_piratage",
                ].includes(customView.id))) && (
              <DesktopDirecteur
                onFolderClick={onWindowAction}
                content={scenario.desktop.delta}
                onAction={onWindowAction}
                login={login}
              />
            )
          )}
          {escWindow && (
            <DesktopWindow
              content={escWindow}
              scenario={scenario}
              onClose={onWindowClose}
              onAction={onWindowAction}
            />
          )}
          {subWindow && (
            <DesktopWindow
              content={subWindow}
              scenario={scenario}
              onClose={onSubWindowClose}
              onAction={onWindowAction}
              style={{ top: "8em", left: "22em" }}
            />
          )}
          {iframe && (
            <DesktopWindow
              content={iframe}
              onClose={onIframeClose}
              onAction={onWindowAction}
              style={{
                top: "3em",
                left: "15em",
                width: "25em",
                height: "21em",
              }}
            />
          )}
          {scenario.desktop.CHATBOT.visible &&
            loadedChatBotMessages &&
            (!customView ||
              (customView && !["final_report"].includes(customView.id))) && (
              <EscapeBot
                displayBot={displayBot}
                toggleBot={toggleBot}
                loadedMessages={loadedChatBotMessages}
                saveMessages={saveChatBotMessages}
              />
            )}
          {customViewComponent}
        </div>
      </MessagesContext.Provider>
    </GlobalContext.Provider>
  )
}

export default DesktopPage
