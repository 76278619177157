import React, { useEffect, useState } from "react"
import { ProgressBar } from "react-bootstrap"

const Progress = ({ timeProgress, timeEnd, onEnd }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    let interval = setInterval(() => setProgress((prog) => prog + 1), 40)
    if (progress >= 100) {
      clearInterval(interval)
      setTimeout(() => onEnd(), 1000)
    }
    return () => clearInterval(interval)
  }, [progress, onEnd])

  return (
    <>{progress ? <ProgressBar now={progress} label={`${progress}%`} /> : ""}</>
  )
}

export default Progress
