import React, { useState, useEffect } from "react"
import useSound from "use-sound"
import { Container, Row, Col } from "react-bootstrap"

import PlayAudio from "../PlayAudio"

const AUDIO_INTRO = "audio/04 - mini jeu 1 intro.mp3"
const AUDIO_FAILED = "audio/05 - mini jeu 1 échec.mp3"
const AUDIO_SUCCESS = "audio/06 - mini jeu 1 réussite.mp3"

const WIRE_LIST = [
  { src: "minigames/deminage/blanc.png", alt: "blanc" },
  { src: "minigames/deminage/blanc.png", alt: "blanc" },
  { src: "minigames/deminage/bleu.png", alt: "bleu" },
  {
    src: "minigames/deminage/noir points verts.png",
    alt: "noir à points verts",
  },
  {
    src: "minigames/deminage/rayé orange et rouge.png",
    alt: "rayé orange et rouge",
  },
  {
    src: "minigames/deminage/rayé rouge et jaune.png",
    alt: "rayé rouge et jaune",
  },
  { src: "minigames/deminage/rouge.png", alt: "rouge" },
  {
    src: "minigames/deminage/vert points noirs.png",
    alt: "vert à points noirs",
  },
  { src: "minigames/deminage/violet.png", alt: "violet" },
]

const Deminage = ({ onAction, scenario, params }) => {
  const optionAudioIntro = {
    onplay: () => {
      setPlayAudio(true)
    },
    onend: () => {
      setPlayAudio(false)
      const miniGameName =
        params.team === "alpha" ? "A_MINIGAME1" : "D_MINIGAME1"
      // update scenario for the right team and the right game
      params.setScenario((prev) => ({
        ...prev,
        desktop: {
          ...prev.desktop,
          [params.team]: {
            ...prev.desktop[params.team],
            folders: {
              ...prev.desktop[params.team].folders,
              [miniGameName]: {
                ...prev.desktop[params.team].folders[miniGameName],
                visible: true,
              },
            },
          },
        },
      }))
      params.setWindow(
        scenario.desktop[params.team].folders[miniGameName].window
      )
      if (params.team === "delta") setDisplayGame(true)
    },
    autoplay: true,
  }
  const optionAudioSuccess = {
    onplay: () => {
      setPlayAudio(true)
    },
    onend: () => {
      setPlayAudio(false)
      onAction({
        type: "game",
        location: "DeminageEnd",
        result: { success: true },
      })
    },
  }
  const optionAudioFailed = {
    onplay: () => {
      setPlayAudio(true)
    },
    onend: () => {
      setPlayAudio(false)
      onAction({
        type: "game",
        location: "DeminageEnd",
        result: { success: false },
      })
    },
  }
  const [playIntro] = useSound(AUDIO_INTRO, optionAudioIntro)
  const [playSuccess] = useSound(AUDIO_SUCCESS, optionAudioSuccess)
  const [playFailed] = useSound(AUDIO_FAILED, optionAudioFailed)
  const [playAudio, setPlayAudio] = useState(false)
  const [displayGame, setDisplayGame] = useState(false)
  const [wires, setWires] = useState([0, 0, 0])

  // when mounting this component, play intro audio
  useEffect(() => {
    playIntro()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValidate = () => {
    // check answer, must be : bleu, noir a point vert, blanc
    const success = wires.join("") === "231" ? "success" : "failed"
    // this wsSend to trigger the audio play also on alpha desktop
    params.wsSend({
      topic: "game",
      action: "minigame_deminage_end",
      params: { success: success },
    })
    if (success === "success") playSuccess()
    else playFailed()
    setDisplayGame(false)
  }

  // this effect is called on on Analyst when the Director validate the result
  // cf onValidate method that wsSend this message that come back to Analyste
  useEffect(() => {
    if ("success" in params)
      if (params.success === "success") playSuccess()
      else playFailed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onWireClick = (event) => {
    if (event.target.classList.contains("wire-choice")) {
      const num = parseInt(event.target.id)
      if (wires[0] === 0) {
        setWires((oldWires) => [num, oldWires[1], oldWires[2]])
      } else if (wires[1] === 0) {
        setWires((oldWires) => [oldWires[0], num, oldWires[2]])
      } else if (wires[2] === 0) {
        setWires((oldWires) => [oldWires[0], oldWires[1], num])
      }
    } else if (["wire1", "wire2", "wire3"].includes(event.target.id)) {
      const num = parseInt(event.target.id[event.target.id.length - 1]) - 1
      setWires((oldWires) =>
        oldWires.map((value, idx) => (idx === num ? 0 : value))
      )
    }
  }

  return (
    <>
      {playAudio ? <PlayAudio teamTag={params.team} /> : ""}
      {displayGame ? (
        <Container id="minigame-deminage">
          <Row>Indiquez les fils à sectionner dans l'ordre:</Row>
          <Row>
            <Col xs={6}>
              <Row>
                <Col>
                  <img
                    id={1}
                    className="wire-choice"
                    src={WIRE_LIST[1].src}
                    alt={WIRE_LIST[1].alt}
                    onClick={onWireClick}
                  ></img>
                </Col>
                <Col>
                  <img
                    id={2}
                    className="wire-choice"
                    src={WIRE_LIST[2].src}
                    alt={WIRE_LIST[2].alt}
                    onClick={onWireClick}
                  ></img>
                </Col>
                <Col>
                  <img
                    id={3}
                    className="wire-choice"
                    src={WIRE_LIST[3].src}
                    alt={WIRE_LIST[3].alt}
                    onClick={onWireClick}
                  ></img>
                </Col>
                <Col>
                  <img
                    id={4}
                    className="wire-choice"
                    src={WIRE_LIST[4].src}
                    alt={WIRE_LIST[4].alt}
                    onClick={onWireClick}
                  ></img>
                </Col>
              </Row>
              <Row>
                <Col>
                  <img
                    id={5}
                    className="wire-choice"
                    src={WIRE_LIST[5].src}
                    alt={WIRE_LIST[5].alt}
                    onClick={onWireClick}
                  ></img>
                </Col>
                <Col>
                  <img
                    id={6}
                    className="wire-choice"
                    src={WIRE_LIST[6].src}
                    alt={WIRE_LIST[6].alt}
                    onClick={onWireClick}
                  ></img>
                </Col>
                <Col>
                  <img
                    id={7}
                    className="wire-choice"
                    src={WIRE_LIST[7].src}
                    alt={WIRE_LIST[7].alt}
                    onClick={onWireClick}
                  ></img>
                </Col>
                <Col>
                  <img
                    id={8}
                    className="wire-choice"
                    src={WIRE_LIST[8].src}
                    alt={WIRE_LIST[8].alt}
                    onClick={onWireClick}
                  ></img>
                </Col>
              </Row>
            </Col>
            <Col xs={4} className="d-flex align-items-center">
              <Row id="wire-wrapper" className="d-flex align-items-center">
                <Col>
                  <div className="wire-border">
                    <img
                      id="wire1"
                      className={wires[0] ? "" : "unset"}
                      src={WIRE_LIST[wires[0]].src}
                      alt={WIRE_LIST[wires[0]].alt}
                      onClick={onWireClick}
                    ></img>
                  </div>
                </Col>
                <Col>
                  <div className="wire-border">
                    <img
                      id="wire2"
                      className={wires[1] ? "" : "unset"}
                      src={WIRE_LIST[wires[1]].src}
                      alt={WIRE_LIST[wires[1]].alt}
                      onClick={onWireClick}
                    ></img>
                  </div>
                </Col>
                <Col>
                  <div className="wire-border">
                    <img
                      id="wire3"
                      className={wires[2] ? "" : "unset"}
                      src={WIRE_LIST[wires[2]].src}
                      alt={WIRE_LIST[wires[2]].alt}
                      onClick={onWireClick}
                    ></img>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={2} className="d-flex align-items-center">
              <button
                onClick={onValidate}
                disabled={wires.some((v) => v === 0)}
              >
                Valider
              </button>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}
    </>
  )
}

export default Deminage
