import React, { useState, useEffect } from "react"
import { Container, Col, Row, Image } from "react-bootstrap"
import DesktopWindow from "../DesktopWindow"
import { getDateStringMinusDay, getAge } from "../db/DbUtils"
import Score from "../Score"
import { getURLParam, getScoreFromScenario } from "../../utils"

import { FacebookShareButton } from "react-share"
import { FacebookIcon } from "react-share"
import { FcGoogle } from "react-icons/fc"

// both robirez and moreno alive
const Text1 = () => (
  <>
    Grâce au travail de Nathan Picot, négociateur pour la Préfecture de police
    de Paris, l’otage a été libérée. Elle est saine et sauve. Leonardo Robirez
    s’est rendu et a été incarcéré. Son procès devrait se tenir dans les mois
    prochains. Nathan Picot a tenu à féliciter le travail des analystes pour la
    qualité du profilage criminel du ravisseur.
  </>
)

// both robirez and moreno dead
const Text2 = () => (
  <>
    Malgré le travail de Nathan Picot, négociateur pour la Préfecture de police
    de Paris, l’otage n’a pas pu être sauvée. Au cours de l’intervention, le
    ravisseur a froidement exécuté la pauvre jeune fille avant d’être abattu par
    un tireur d’élite. Nathan Picot a déploré l’amateurisme du travail effectué
    par les analystes. Selon lui, le profil psychologique établi était très
    éloigné de celui du ravisseur. Nathan Picot se dit profondément touché par
    cet échec et ignore à l’heure actuelle s’il pourra poursuivre à l’avenir son
    métier de négociateur.
  </>
)

// robirez dead, moreno alive
const Text3 = () => (
  <>
    Grâce au travail de Nathan Picot, négociateur pour la Préfecture de police
    de Paris, l’otage a été libérée. Elle est saine et sauve. Mais au cours de
    l’intervention, Leonardo Robirez a été abattu par un tireur d’élite. Nathan
    Picot a déploré que les informations récoltées sur le ravisseur étaient
    lacunaires voire erronées. En effet, selon lui, tout a basculé à un moment
    clé du protocole de négociation : Nathan Picot, s’appuyant sur les
    informations fournies par les analystes, a fait appel à un proche du
    ravisseur pour le convaincre par téléphone de se rendre. Or, contre toute
    attente, ce coup de téléphone a plongé Leonardo Robirez dans une rage
    meurtrière, obligeant le tireur d’élite à l’abattre.
  </>
)

// moreno alive
const Text4Title = () => (
  <>
    Rejet de la Loi de Surveillance Générale :<br />
    la majorité plus que jamais divisée
  </>
)

const Text4 = () => (
  <>
    POUR : 240 députés / CONTRE : 261 députés - Abstention volontaire : 76.
    <br />
    <br />
    L’Assemblée nationale s’est prononcée aujourd’hui contre la loi de
    Surveillance Générale avec 261 voix contre et 240 voix pour.
    <br />
    Il s’en est donc fallu de peu pour que le ministère de l'intérieur bénéficie
    d’un nouvel éventail de dispositions et de mesures que beaucoup jugent
    liberticides.
    <br />
    <br />
    En effet, cette loi prévoyait un hyper contrôle de la population avec le
    déclenchement à votre insu de la caméra et des micros de votre smartphone,
    la mise sur écoute de l’ensemble des Français, un scannage permanent et une
    alerte donnée à la prononciation de mots clés listés, des opérations de
    surveillance sans l’autorisation préalable d’un juge etc.
    <br />
    Le débat a provoqué une véritable fracture au sein de la majorité
    présidentielle profondément divisée. Il a fallu la voix de la députée Gaëlle
    Moreno, cheffe de file de la majorité, pour empêcher cette loi proposée par
    le Ministre de l'intérieur lui-même, d’être votée.
    <br />
    <br />
    Pourtant, tout était incertain. En effet, la députée s’est mise en retrait
    du débat politique il y a quelques jours. C’est pourtant elle qui, au sein
    de la majorité, avait le plus protesté contre cette loi qu’elle qualifiait
    d'anti-démocratique. Maintenant que l’affaire Floriane Moreno est révélée au
    grand jour, cette brusque réserve prend tout son sens. La députée nous a
    d’ailleurs confié que si sa fille n’avait pas été libérée, elle était
    résolue à céder aux exigences du preneur d’otages c'est-à-dire à convaincre
    toute la majorité de voter en faveur de cette loi. A l’occasion du vote, la
    députée s’est lancée dans une violente diatribe contre cette loi et ceux qui
    voulaient la promulguer. Et c’est plus de la moitié de l’Assemblée, tous
    partis confondus, qui s’est levée pour lui faire une véritable “standing
    Ovation”.
    <br />
    <br />
    Cette nouvelle popularité pourrait amener la députée à poursuivre son
    ascension politique et peut-être même à briguer la fonction de Président de
    la République lors des prochaines élections.
  </>
)

// moreno dead
const Text5Title = () => (
  <>
    Rejet de la Loi de Surveillance Générale :<br />
    la classe politique plus que jamais divisée
  </>
)

const Text5 = () => (
  <>
    POUR : 240 députés / CONTRE : 245 voix - Abstention volontaire : 92.
    <br />
    <br />
    L’Assemblée nationale s’est prononcée aujourd’hui contre la loi de
    Surveillance Générale avec 245 voix contre et 240 voix pour.
    <br />
    Il s’en est donc fallu de peu pour que le ministère de l'intérieur bénéficie
    d’un nouvel éventail de dispositions et de mesures que beaucoup jugent
    liberticides.
    <br />
    <br />
    En effet, cette loi prévoyait un hyper contrôle de la population avec le
    déclenchement à votre insu de la caméra et des micros de votre smartphone,
    la mise sur écoute de l’ensemble des Français, un scannage permanent et une
    alerte donnée à la prononciation de mots clés listés, des opérations de
    surveillance sans l’autorisation préalable d’un juge etc. Cette loi avait
    été dénoncée par tous les membres de l’opposition et par certains membres de
    la majorité, dont la députée Gaelle Moreno.
    <br />
    Pourtant, le vote était incertain. En effet, la députée s’est mise en
    retrait du débat politique il y a quelques jours. C’est pourtant elle qui,
    au sein de la majorité, avait le plus protesté contre cette loi qu’elle
    qualifiait d'anti-démocratique. Maintenant que l’affaire Floriane Moreno est
    révélée au grand jour, cette brusque réserve prend tout son sens. Nous avons
    en effet appris aujourd’hui que Leonardo Robirez exerçait un chantage odieux
    sur la députée (cf article “Enlèvement, séquestration, chantage : les
    coulisses d’un complot…”). Si elle ne changeait pas sa position et n'usait
    pas de son influence pour que les membres de la majorité adoptent cette loi,
    sa fille Floriane serait exécutée. Et c’est ce qu’elle aurait fait sans nul
    doute si sa fille était restée entre les mains du ravisseur.
    Malheureusement, l’arrestation de Leonardo Robirez a entrainé la mort de la
    jeune fille. Sous le choc, la députée Gaelle Moreno n’a pas pu se rendre à
    l’Assemblée nationale.
    <br />
    Des sources proches de Gaelle Moreno nous révèlent que, suite à ces
    événements tragiques, la députée pourrait annoncer son retrait de la vie
    politique et sa détermination à révéler au grand jour les dessous de cette
    sordide histoire.
  </>
)

const FBShareButton = ({ text, fontSize, marginRight, url, scoring }) => {
  return (
    <div className="sticky-btn fb-sticky" style={{ marginRight: marginRight }}>
      <FacebookShareButton
        url={url}
        quote={"On a sauvé la France en croyant faire une Escape Game !"}
        hashtag={"#mindquest"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          outline: "none",
        }}
      >
        <FacebookIcon
          size={48}
          round
          style={{
            position: "absolute",
            transform: "translate(0,-20px)",
            top: 0,
          }}
        />
        {text.map((t, i) => (
          <span key={i} className="sticky-text" style={{ fontSize: fontSize }}>
            {t}
          </span>
        ))}
      </FacebookShareButton>
    </div>
  )
}

const Recommendation = ({ text, fontSize, url, marginRight, icon }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none", marginRight: marginRight }}
    >
      <div className="sticky-btn recommendation">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
          {text.map((t, i) => (
            <span
              key={i}
              className="sticky-text"
              style={{ fontSize: fontSize }}
            >
              {t}
            </span>
          ))}
        </div>
      </div>
    </a>
  )
}

const FinalReport = ({ params }) => {
  // cas 1:otage et ravisseur vivants
  // cas 2: otage vivant, ravisseur mort
  // cas 3: otage et ravisseur morts
  const [finalCase, setFinalCase] = useState(1)
  const [scoring, setScoring] = useState({})
  const [token, setToken] = useState(null)

  useEffect(() => {
    setToken(getURLParam("token"))
    const scenario = params.final
    if (scenario) {
      const audio = scenario.survey.results.audio[2] || ""
      setFinalCase(audio === "audio26" ? 3 : audio === "audio25" ? 1 : 2)
      setScoring(getScoreFromScenario(scenario))
    }
  }, [params])

  const onReportClose = () => {
    console.log("MERCI D'AVOIR JOUE A ESCAPE WEB - COMPLOT D'ETAT")
  }

  return (
    <>
      <div className="sticky-btn-container" style={{ left: "1rem" }}>
        <FBShareButton
          url={`https://escapeweb.mindquest-games.fr/${
            token && "?token=" + token + "&score=true"
          }`}
          text={["Partager", "Jeu"]}
          fontSize={"22px"}
          marginRight={"20px"}
          scoring={scoring}
        />
        <Recommendation
          url={"https://g.page/r/CbMJo_pH1hJ8EAI/review"}
          text={["Noter", "MindQuest", "sur Google"]}
          fontSize={"14px"}
          icon={
            <>
              <div
                style={{
                  width: "36px",
                  height: "36px",
                  position: "absolute",
                  transform: "translate(0,-17px)",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "18px",
                  top: 0,
                }}
              />
              <FcGoogle
                size={42}
                style={{
                  position: "absolute",
                  transform: "translate(0,-20px)",
                  top: 0,
                }}
              />
            </>
          }
          marginLeft={"20px"}
          marginRight={"20px"}
        />
        <Recommendation
          url={"https://www.facebook.com/MQGames/reviews"}
          text={["Noter", "MindQuest", "sur Facebook"]}
          fontSize={"14px"}
          icon={
            <>
              <FacebookIcon
                size={48}
                round
                style={{
                  position: "absolute",
                  transform: "translate(0,-20px)",
                  top: 0,
                }}
              />
            </>
          }
        />
      </div>
      <div
        className="sticky-btn-container"
        style={{ right: "1rem", flexDirection: "row-reverse" }}
      ></div>
      <Score scoring={scoring} extraClassName="modal-score-game" />
      <DesktopWindow
        content={{ title: "Les Echos du Quotidien" }}
        onClose={onReportClose}
        style={{ top: "0.5em", left: "20em", width: "28em", height: "27em" }}
      >
        <Container className="final-report">
          <Row className="d-flex justify-content-end">
            <b>N°17952</b>
          </Row>
          <Row className="d-flex justify-content-center">
            <Image src="report/Les Echos du Quotidien logo.png" alt="logo" />
          </Row>
          <Row>{getDateStringMinusDay(0)}</Row>
          <p />
          <Row className="h4 d-flex justify-content-center">
            Ils croyaient participer à une Escape Game,
          </Row>
          <Row className="h2 d-flex justify-content-center">
            ils ont sauvé la FRANCE.
          </Row>
          <Row className="d-flex justify-content-center">
            <Image src="report/cyber-attack1280.jpg" alt="team" />
          </Row>
          <Row>
            <div className="report-two-cols">
              C’est l’intervention incroyable d’un groupe d’anonymes qui a
              permis la révélation de ce complot national.
              <br />
              Tout commence lorsqu'un groupe de joueurs répond à l’annonce d’un
              escape game en ligne. Le scénario leur donne pour mission de
              hacker les fichiers de la police pour supprimer un fichier
              compromettant. Ils ne se doutent pas un seul instant que ce “jeu”
              est bien réel et va les entraîner dans une machination
              gouvernementale. Ce fameux fichier n’est rien d’autre que l’appel
              au secours que Floriane Moreno a lancé depuis le coffre de la
              voiture du ravisseur [CF article Enlèvement, séquestration,
              chantage : les coulisses d’un complot... ].
              <br />
              <br />
              Il était donc primordial pour les complotistes que la police ne
              l’entende pas et qu’elle ne soit mêlée d’aucune sorte à cette
              affaire. Enquêter sur la disparition de Floriane Moreno aurait mis
              la police sur la piste des commanditaires de ce complot visant à
              faire voter la loi de Surveillance Générale qui limite les
              libertés individuelles. [cf Article sur la loi de surveillance
              générale] C’est grâce à l’intervention de Cécilia Peres,
              responsable informatique de la B.R.I., que ces joueurs ont compris
              qu’ils avaient été manipulés. Avec l’aide du groupe d’activistes
              Tsunami, nos apprentis espions ont continué à utiliser les
              ordinateurs de la police mais dans le but, cette fois, de déjouer
              ce complot et de remonter à la planque du ravisseur de Floriane
              Moreno.
              <br />
              <br />
              Restent posées deux questions fondamentales : qui a tiré les
              ficelles de cette incroyable machination ? qui avait intérêt à ce
              que la loi de Surveillance Générale soit votée ?<br />
              La complicité d’un membre haut placé de l’État semble être une
              piste logique. Une commission sénatoriale a été nommée pour
              enquêter sur cette affaire.
            </div>
          </Row>
          <Row className="d-flex justify-content-end">
            <b>
              Article par C.Villolango
              <br />
              Photos par F.Adar
            </b>
          </Row>
          <p />
          <Row className="h4 d-flex justify-content-center">
            Enlèvement, séquestration, chantage :
          </Row>
          <Row className="h2 d-flex justify-content-center">
            les coulisses d’un complot...
          </Row>
          <Row>
            <div className="report-one-col">
              {getDateStringMinusDay(0)} les forces du GIGN ont pris d’assaut
              une maison de la banlieue parisienne. Leonardo Robirez y détenait
              en otage Floriane Moreno, la fille de la députée Gaelle Moreno,
              chef de file de la majorité. La jeune fille de{" "}
              {getAge(new Date(2004, 2, 11))} ans avait été kidnappée à son
              domicile une semaine auparavant. Sa disparition avait été tenue
              secrète par sa mère qui craignait pour la vie de sa fille. La
              députée devait user de son influence au sein de l’Assemblée
              nationale pour que soit votée la loi de Surveillance Générale (Cf
              article sur la loi de surveillance générale). Si elle refusait, le
              ravisseur était résolu à exécuter la jeune Floriane.
              <br />
              Pendant son enlèvement, la courageuse jeune fille est arrivée à
              dissimuler son portable et à appeler la police alors qu’elle était
              dans le coffre du ravisseur. C’est ce coup de téléphone qui a
              permis d’identifier Leonardo Robirez.
            </div>
          </Row>
          <p />
          <Row>
            <Col className="d-flex justify-content-center">
              <Image src="report/sirene police.png" alt="police" />
            </Col>
            <Col className="d-flex justify-content-center">
              <Image src="report/caméras de surveillance.png" alt="cameras" />
            </Col>
          </Row>
          <p />
          <Row>
            <div className="report-one-col">
              {finalCase === 1 ? (
                <Text1 />
              ) : finalCase === 2 ? (
                <Text3 />
              ) : (
                <Text2 />
              )}
            </div>
          </Row>
          <p />
          <Row className="h5 d-flex justify-content-start">
            {finalCase === 3 ? <Text5Title /> : <Text4Title />}
          </Row>
          <Row>
            <div className="report-two-cols">
              {finalCase === 3 ? <Text5 /> : <Text4 />}
            </div>
          </Row>
          <p />
          <Row className="h4">
            LEONARDO ROBIREZ : PROFIL D’UN PRENEUR D’OTAGE
          </Row>
          <Row>
            <Col xs={3} className="d-flex flex-column justify-content-center">
              <Image src="report/portrait léonardo.png" alt="leonardo" />
              Source: Facebook
            </Col>
            <Col>
              <div className="report-one-col">
                Quand on parcourt la vie de Leonardo Robirez, on se rend vite
                compte que ce jeune homme au regard sombre avait le choix entre
                devenir un héros ou devenir un assassin. Oui, Leonardo Robirez
                aurait pu incarner la figure du héros. Son père, commandant de
                brigade divisionnaire de Paris, est un homme droit et loyal. Sa
                mère, lieutenant de police, incarne la figure de la femme de
                principe exemplaire, morte dans l’exercice de ses fonctions.
                <br />
                Si Leonardo Robirez quitte rapidement un système scolaire
                étouffant, il trouve sa place dans l’armée de terre. Il sert
                loyalement sa patrie et intègre les unités commandos de la 11e
                brigade des parachutistes en tant qu’artificier. Il n’a alors
                que 18 ans.
                <br />
                Leonardo Robirez aurait pu être un héros. Il se fait remarquer
                le 14 janvier 2013, lors de l’opération SERVAL au Mali pour son
                courage. Et qu’importe si ses supérieurs appellent cette audace
                témérité, tout le monde s’accorde à dire que Léonardo Robirez
                n’a peur de rien. Les nombreuses médailles qu’il reçoit en sont
                la preuve.
              </div>
            </Col>
          </Row>
          <p />
          <Row>
            <Col>
              <div className="report-one-col">
                Leonardo Robirez aurait donc pu être un héros. De 2015 à 2018,
                il est un membre apprécié de la Brigade de Recherche et
                d’Intervention (B.R.I. ). En 2019, il devient responsable de
                l’unité de Protection rapprochée du Ministre de l'Intérieur
                (GSMI). Ce dernier loue d’ailleurs son professionnalisme et sa
                rigueur exemplaire.
                <br />
                Sportif accompli, il remporte de nombreuses compétitions
                nationales de snowboard, et devient même champion de France de
                cette discipline en 2019. Il gagne alors sa place aux J.O. de
                Beijing prévus en 2022.
                <br />
                Alors, comment un tel personnage se mue-t-il soudain en un
                meurtrier violent ? Leonardo Robirez possède en lui cette part
                d’ombre depuis bien longtemps. C’est sans doute la mort
                particulièrement injuste de sa mère, en 1998, qui le fait
                basculer : il est persuadé que ce système judiciaire sur lequel
                repose son pays n’est pas fiable. Il est persuadé que si ce
                voyou, alors suspecté de braquage, avait été mis sur écoute,
                jamais sa mère ne serait morte*.
              </div>
            </Col>
            <Col xs={3} className="d-flex flex-column justify-content-center">
              <Image src="report/snowboard.png" alt="snowboard" />
              Source: Facebook
            </Col>
          </Row>
          <p />
          <Row>
            <div className="report-one-col">
              Le mal est fait, le ver est dans le fruit : Robirez, à partir de
              ce jour, ne sera plus jamais le même. Et les affaires
              s’accumulent.
              <br />
              En 2014, un jeune homme dépose une plainte pour coups et blessures
              contre lui. Mais la plainte est retirée sans explication.
              <br />
              En 2015, un de ses anciens professeurs est froidement assassiné.
              Robirez est fortement soupçonné. Mais un alibi tombé du ciel - un
              match de foot en compagnie de son père - clôt une enquête
              bâclée**.
              <br />
              En 2019, Frédéric Taullé, un ami d’enfance, porte plainte contre
              lui pour diffamation. Mais, là encore, la plainte est
              inexplicablement retirée.
              <br />
              On voit à quel point la figure de Leonardo Robirez est complexe :
              loyauté et trahison, sang-froid et rage, soif de justice et
              comportement meurtrier. Léonardo Robirez est peut-être
              l’incarnation d’une génération frustrée et déçue, en manque de
              repères, qui ne trouve de réponses que dans la violence et le
              complotisme.
              <br />
              <br />
              <i>
                *Magali Robirez est morte exécutée par le voyou qu’elle avait
                arrêté quelques semaines plus tôt. C’est d’ailleurs certainement
                ce qui a poussé L. Robirez à kidnapper la jeune Floriane Moreno
                : il voulait que la loi de Surveillance Générale soit votée.
                <br />
                **L’enquête sur la mort du professeur Higuelin vient d’être
                ré-ouverte suite aux derniers événements.
              </i>
            </div>
          </Row>
        </Container>
      </DesktopWindow>
    </>
  )
}

export default FinalReport
