import React, { useState, useEffect } from "react"
import { Form, Image } from "react-bootstrap"
import DesktopWindow from "../DesktopWindow"
import { getDateStringMinusDay } from "../db/DbUtils"

const CityCamForm = ({ content }) => (
  <div>
    <Form className="citycam-form">
      <Form.Group
        as={Form.Row}
        className="d-flex align-items-end"
        style={{ marginBottom: "30px" }}
      >
        <Form.Label column="sm" sm="3">
          <Image src="pictos/logo cam.png" />
        </Form.Label>
        <Form.Label column="sm" sm="9">
          <h4 style={{ fontFamily: "Tahoma, monospace" }}>
            City<b>CAM</b>
            <br></br>TRACKING REPORT
          </h4>
        </Form.Label>
      </Form.Group>
      <Form.Group as={Form.Row} className="group-sep">
        <Form.Label column="sm" sm="5">
          <b>{content.periode.label}</b>
        </Form.Label>
        <Form.Label column="sm" sm="4">
          du: {content.periode.from}
        </Form.Label>
        <Form.Label column="sm" sm="3">
          au: {content.periode.to}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Form.Row}>
        <Form.Label column="sm" sm="5">
          <b>{content.immatriculation.label}</b>
        </Form.Label>
        <Form.Label column="sm" sm="7">
          {content.immatriculation.value}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Form.Row}>
        <Form.Label column="sm" sm="5">
          <b>{content.proprietaire.label}</b>
        </Form.Label>
        <Form.Label column="sm" sm="7">
          {content.proprietaire.value}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Form.Row}>
        <Form.Label column="sm" sm="5">
          <b>{content.adresse.label}</b>
        </Form.Label>
        <Form.Label column="sm" sm="7">
          {content.adresse.value}
        </Form.Label>
      </Form.Group>
      {content.tracking.map((item, i) => (
        <Form.Group key={i} className={i === 0 ? "group-sep" : "group-sepdbl"}>
          <Form.Group as={Form.Row}>
            <Form.Label column="sm" sm="4">
              <b>Date:</b>
            </Form.Label>
            <Form.Label column="sm" sm="8">
              {item.date}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Form.Row}>
            <Form.Label column="sm" sm="4">
              <b>Heure:</b>
            </Form.Label>
            <Form.Label column="sm" sm="8">
              {item.heure}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Form.Row}>
            <Form.Label column="sm" sm="4">
              <b>Ville:</b>
            </Form.Label>
            <Form.Label column="sm" sm="8">
              {item.ville}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Form.Row}>
            <Form.Label column="sm" sm="4">
              <b>Adresse caméra:</b>
            </Form.Label>
            <Form.Label column="sm" sm="8">
              {item.camera}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Form.Row}>
            <Form.Label column="sm" sm="4">
              <b>Adresse véhicule localisé:</b>
            </Form.Label>
            <Form.Label column="sm" sm="8">
              {item.localisation}
            </Form.Label>
          </Form.Group>
        </Form.Group>
      ))}
      <Form.Group as={Form.Row} className="group-sep">
        <Form.Label
          column="sm"
          sm="12"
          className="d-flex justify-content-center"
        >
          <h6 style={{ fontFamily: "Tahoma, monospace" }}>FIN DE RAPPORT</h6>
        </Form.Label>
      </Form.Group>
      <Form.Group as={Form.Row} className="footer">
        <Form.Label
          column="sm"
          sm="12"
          className="d-flex justify-content-center"
        >
          <i style={{ textAlign: "center" }}>
            SARL CityCAM, Capital Social 1 000 000€
            <br />
            34 Avenue du Président Salvador Allende, 93100 Montreuil
          </i>
        </Form.Label>
      </Form.Group>
    </Form>
  </div>
)

const CityCamReport = ({ onAction }) => {
  const [camContent, setCamContent] = useState(null)

  useEffect(() => {
    const replaceDate = (metadate) => {
      if (metadate.startsWith("@date_J-")) {
        const dayoff = parseInt(metadate.slice(8), 10)
        return getDateStringMinusDay(dayoff)
      }
      return metadate
    }
    fetch("citycam.json")
      .then((resp) => resp.json())
      .then((json) => {
        // patch the content to update dates
        json.periode.from = replaceDate(json.periode.from)
        json.periode.to = replaceDate(json.periode.to)
        json.tracking.forEach((item) => (item.date = replaceDate(item.date)))
        setCamContent(json)
      })
  }, [])

  const onCityCamClose = () => {
    onAction({
      type: "game",
      location: "CityCamReport",
      result: "close",
    })
  }

  return (
    <DesktopWindow
      content={{ title: "CityCAM Tracking Report" }}
      onClose={onCityCamClose}
      style={{ top: "0.5em", left: "12em", width: "25em", height: "25em" }}
    >
      {camContent ? <CityCamForm content={camContent} /> : ""}
    </DesktopWindow>
  )
}

export default CityCamReport
