import axios from "axios"

const SYNCH = 0
const STARTED = 1
const END = 2
const SCORE = 3
const GAME_STATES = ["SYNCH", "STARTED", "END", "SCORE"]

const GAME_VERSION_URI = "game/version/"
const GAME_CHECK_URI = "game/check/"
const GAME_UPDATE_URI = "game/update/"
const GAME_STATS_URI = "game/stats/"

const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max))

const pad0 = (value) => {
  let sval = value.toString()
  if (sval.length < 2) sval = "0" + sval
  return sval
}

const throttle = (callback, limit) => {
  var wait = false
  return function () {
    if (!wait) {
      callback.apply(null, arguments)
      wait = true
      setTimeout(function () {
        wait = false
      }, limit)
    }
  }
}

const getCsrfToken = () => {
  const csrf = document.cookie.match("(^|;)\\s*csrftoken\\s*=\\s*([^;]+)")
  return csrf ? csrf.pop() : ""
}

const getServerURL = () => {
  return process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_SERVER_ADDRESS
    : process.env.REACT_APP_DEV_SERVER_ADDRESS
}

const getWebSocketURL = () => {
  return process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_WS_SERVER_ADDRESS
    : process.env.REACT_APP_DEV_WS_SERVER_ADDRESS
}

const getToken = () => {
  const params = new URLSearchParams(window.location.search)
  let token = ""
  if (params.has("token")) {
    token = params.get("token")
  }
  return token
}

const getURLParam = (keyword) => {
  /**
   * @param {string} keyword Checks and returns a parameter matching the keyword from URLSearchParams.
   */
  const params = new URLSearchParams(window.location.search)
  let query = ""
  if (params.has(keyword)) {
    query = params.get(keyword)
  }
  return query
}

const getReset = () => {
  const params = new URLSearchParams(window.location.search)
  let token = ""
  if (params.has("reset")) {
    token = params.get("reset")
  }
  return token
}

// test is current token is in test game token list
const isTestGame = () => {
  return [
    "b31fcbfe-147b-45dc-be84-f582757cee6b",
    "e4899e95-84ca-415a-bcca-146a7ddc4e0a",
    "6b0f644a-de9f-48f3-b6e7-bf68d928f400",
    "fb240f48-4125-4de0-9eea-722401e82f0f",
  ].includes(getToken())
}

const postGameUpdate = (gameId, status) => {
  const postUrl = getServerURL() + GAME_UPDATE_URI + gameId
  const formData = new FormData()
  formData.append("status", JSON.stringify(status))
  return axios
    .post(postUrl, formData)
    .then((res) => {
      if (res.data.error) {
        console.warn("Error updating game: ", res.data.error)
      }
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}

const getGameStats = (gameId) => {
  const postUrl = getServerURL() + GAME_STATS_URI + gameId
  return axios
    .get(postUrl)
    .then((res) => {
      if (res.data.error) {
        console.warn("Error getting stats game: ", res.data.error)
      }
      return res.data
    })
    .catch((error) => {
      console.log(error)
    })
}

const extractIndices = (messages) =>
  messages
    ? messages
        .filter(
          (msg) =>
            msg.type === "user" &&
            msg.message !== "Nous avons besoin de votre aide !"
        )
        .map((msg) => msg.message)
    : []

const getScoreFromScenario = (scenario) => {
  // final case
  // 1 = hostage and hijacker alive
  // 2 = hijacker dead, hostage alive
  // 3 = hostage and hijacker dead
  const audio = scenario.survey.results.audio[2]
  const finalCase = audio === "audio26" ? 3 : audio === "audio25" ? 1 : 2
  // survey score (110 points max)
  let score = scenario.survey.results.percent
  // total indices used
  let indices_alpha = extractIndices(scenario.desktop.alpha.messages).length
  let indices_delta = extractIndices(scenario.desktop.delta.messages).length
  let indices = Math.max(indices_alpha, indices_delta)
  // investigator is the survey score on 5 points
  let investigator = score / 20
  // global success scoring is survey score minus indice count minus mini game failed
  score = score - indices
  // hacker score depends on indice used on 5 points
  let hacker = Math.max(0, 5 - indices / 8) // 40 indices
  // leader score if number of death and mini game failed
  let leader = scenario.scoring.miniGame1 === "failed" ? -5 : 0 // 0 or 1 dead
  leader = leader + (scenario.scoring.miniGame2 === false ? -5 : 0) // 0, 1 or 2 dead
  leader = leader + (scenario.scoring.miniGame3 === false ? -3 : 0) // forcing
  score = score + leader
  leader = Math.max(0, 5 + leader / 3) // count/5
  // failed intervention count
  if (scenario.scoring.nbIntervention) {
    let interv = scenario.scoring.nbIntervention * 3
    score = score - interv
  }
  score = score - (finalCase === 2 ? 10 : finalCase === 3 ? 25 : 0)
  score = Math.max(0, score)
  let res = {
    indiceCount: indices,
    hacker: hacker,
    investig: investigator,
    leader: leader,
    score: score,
  }

  return res
}

export {
  GAME_STATES,
  SYNCH,
  STARTED,
  END,
  SCORE,
  GAME_VERSION_URI,
  GAME_CHECK_URI,
  pad0,
  getCsrfToken,
  getServerURL,
  getWebSocketURL,
  postGameUpdate,
  getGameStats,
  getToken,
  getReset,
  isTestGame,
  throttle,
  getRandomInt,
  getURLParam,
  getScoreFromScenario,
}
