import React from "react"
import { AiFillSound } from "react-icons/ai"

const PlayAudio = ({ teamTag }) => (
  <>
    <AiFillSound
      id="button_sound"
      size={"4em"}
      style={teamTag === "alpha" ? { color: "black" } : {}}
    />
    <div className="remote-control-popup">
      Conseil: coupez votre micro pour éviter les échos.
    </div>
  </>
)

export default PlayAudio
