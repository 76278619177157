// contains all the game logics

const processGameAction = (event) => {}

const AudioToolBlink = (setScenario) => {
  // red blinking of analyst audio cleaning tool
  const ims = ["pictos/mixage audio rouge.png", "pictos/mixage audio.png"]
  var cpt = 0
  const interv = setInterval(() => {
    updateScenario(ims[cpt % 2])
    cpt += 1
    if (cpt === 6) clearInterval(interv)
  }, 500)

  const updateScenario = (image) => {
    setScenario((prev) => ({
      ...prev,
      desktop: {
        ...prev.desktop,
        alpha: {
          ...prev.desktop.alpha,
          folders: {
            ...prev.desktop.alpha.folders,
            A6: {
              ...prev.desktop.alpha.folders.A6,
              image: image,
            },
          },
        },
      },
    }))
  }
}

export { processGameAction, AudioToolBlink }
