import React, { useState, useEffect, useRef } from "react"
import useSound from "use-sound"

import { throttle, pad0 } from "../../utils"

import PlayAudio from "../PlayAudio"

const AUDIO_INTRO = "audio/07 - mini jeu 2 intro.mp3"
const AUDIO_FAILED = "audio/08 - mini jeu 2 échec.mp3"
const AUDIO_SUCCESS = "audio/09 - mini jeu 2 réussite.mp3"

const SMART_CRACKER = {
  src: "minigames/crackage/fond d'écran du smart cracker.png",
  alt: "smart cracker",
}
const SECURE_DOOR = {
  src: "minigames/crackage/porte sécurisée base fixe.png",
  alt: "secure door",
}
const LIGHT_BLUE = {
  src: "minigames/crackage/lumière bleue.png",
  alt: "blue light",
}
const LIGHT_YELLOW = {
  src: "minigames/crackage/lumière jaune.png",
  alt: "yellow light",
}
const LIGHT_EMPTY = {
  src: "",
  alt: "",
}
const TXT_PLAY = {
  src: "minigames/crackage/UNLOCK CRACK SYSTEM bleu.png",
  alt: "blue text",
}
const TXT_FAILED = {
  src: "minigames/crackage/UNLOCK CRACK SYSTEM rouge.png",
  alt: "red text",
}
const TXT_DONE = {
  src: "minigames/crackage/UNLOCK CRACK SYSTEM vert.png",
  alt: "green text",
}
const CELL_PLAY = {
  src: "minigames/crackage/zones départ.png",
  alt: "start cell",
}
const CELL_FAILED = {
  src: "minigames/crackage/zones rouges.png",
  alt: "red cell",
}
const CELL_DONE = {
  src: "minigames/crackage/zones vertes.png",
  alt: "green cell",
}

const GAME_TIME = 600

const left = 0
const top = 1
const width = 2
const height = 3
// global on screen image
const smartphone_base_screen = [0.5, 0.4, 0.35, 0.38]
const smartphone_game_screen = [0, 0, 1, 1]
var smartphone_screen = smartphone_base_screen
const cracker_exit = [0.87, 0.45, 0.11, 0.18]
// relative to smartphone screen dimension
const cell_pad_pos = [0.01, 0.01, 0.98, 1]
const text_pad_pos = [0.13, 0.28, 0.75, 1]
const light_posx = [0.005, 0.144, 0.285, 0.422, 0.562, 0.702, 0.842]
const light_posy = 0.015
const light_width = 0.15

const default_lights = [
  LIGHT_YELLOW,
  LIGHT_YELLOW,
  LIGHT_YELLOW,
  LIGHT_EMPTY,
  LIGHT_BLUE,
  LIGHT_BLUE,
  LIGHT_BLUE,
]

const Crackage = ({ onAction, params }) => {
  const optionAudioIntro = {
    onplay: () => {
      setPlayAudio(true)
    },
    onend: () => {
      setPlayAudio(false)
      setDisplayGame(true)
    },
    autoplay: true,
  }
  const optionAudioSuccess = {
    onplay: () => {
      setPlayAudio(true)
    },
    onend: () => {
      setPlayAudio(false)
      onAction({
        type: "game",
        location: "CrackageEnd",
        result: { success: true },
      })
    },
  }
  const optionAudioFailed = {
    onplay: () => {
      setPlayAudio(true)
    },
    onend: () => {
      setPlayAudio(false)
      onAction({
        type: "game",
        location: "CrackageEnd",
        result: { success: false },
      })
    },
  }
  const [playIntro] = useSound(AUDIO_INTRO, optionAudioIntro)
  const [playSuccess] = useSound(AUDIO_SUCCESS, optionAudioSuccess)
  const [playFailed] = useSound(AUDIO_FAILED, optionAudioFailed)
  const [playAudio, setPlayAudio] = useState(false)

  const [displayGame, setDisplayGame] = useState(false)
  const [playGame, setPlayGame] = useState(false)
  const [cellPhoneImgMap, setCellPhoneImgMap] = useState("0, 0, 0, 0")
  const [lights, setLights] = useState(default_lights)
  const [onSuccess, setOnSuccess] = useState(false)
  const [onFailure, setOnFailure] = useState(false)
  const [onTimeout, setOnTimeout] = useState(false)
  const [timeLeft, setTimeLeft] = useState(null)
  const [stopTimer, setStopTimer] = useState(false)
  const [render, setRender] = useState(true)
  const [smartphoneLeft, setSmartphoneLeft] = useState(0)
  const [smartphoneWidth, setSmartphoneWidth] = useState(0)
  const [smartphoneTop, setSmartphoneTop] = useState(0)
  const [smartphoneHeight, setSmartphoneHeight] = useState(0)
  const timerElt = useRef(null)
  const imgBase = useRef(null)
  const imgCell = useRef(null)
  const imgText = useRef(null)

  // when mounting this component, play intro audio
  useEffect(() => {
    playIntro()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!render) {
      // force re-render images for Safari
      setRender(true)
    }
  }, [render])

  useEffect(() => {
    if (timeLeft === null || stopTimer) return
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)
      // Clear timeout if the component is unmounted
      return () => {
        clearTimeout(timer)
      }
    } else {
      // no time left --> TIMEOUT !
      setOnTimeout(true)
      onFailureAction(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft])

  const computeResize = () => {
    if (!imgBase.current) return
    const im = imgBase.current.getBoundingClientRect()
    updateImgMap(playGame)
    const smartphone_left = im.left + smartphone_screen[left] * im.width
    const smartphone_top = im.top + smartphone_screen[top] * im.height
    const smartphone_width = smartphone_screen[width] * im.width
    const smartphone_height = smartphone_screen[height] * im.height
    setSmartphoneLeft(smartphone_left)
    setSmartphoneTop(smartphone_top)
    setSmartphoneWidth(smartphone_width)
    setSmartphoneHeight(smartphone_height)
    timerElt.current.style.left =
      smartphone_left + 0.4 * smartphone_width + "px"
    timerElt.current.style.top = smartphone_top + 0.4 * smartphone_height + "px"
    timerElt.current.style.fontSize = 0.004 * smartphone_width + "rem"
    imgCell.current.style.left =
      smartphone_left + cell_pad_pos[left] * smartphone_width + "px"
    imgCell.current.style.top =
      smartphone_top + cell_pad_pos[top] * smartphone_height + "px"
    imgCell.current.style.width = cell_pad_pos[width] * smartphone_width + "px"
    imgText.current.style.left =
      smartphone_left + text_pad_pos[left] * smartphone_width + "px"
    imgText.current.style.top =
      smartphone_top + text_pad_pos[top] * smartphone_height + "px"
    imgText.current.style.width = text_pad_pos[width] * smartphone_width + "px"
  }

  /** Effect for the resize event */
  useEffect(() => {
    const onResize = throttle(() => {
      computeResize()
    }, 100)
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (displayGame) setTimeout(() => computeResize(), 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayGame])

  useEffect(() => {
    // when the status is 1, force the game to start if not already started
    if (params.status === 1 && timeLeft === null) {
      setTimeLeft(GAME_TIME)
    } else if (params.status === 3 && !stopTimer) {
      setStopTimer(true)
      playSuccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, timeLeft, stopTimer])

  const updateImgMap = (game) => {
    const im = imgBase.current.getBoundingClientRect()
    let coords
    if (!game)
      coords = [
        smartphone_screen[left] * im.width,
        smartphone_screen[top] * im.height,
        smartphone_screen[left] * im.width +
          smartphone_screen[width] * im.width,
        smartphone_screen[top] * im.height +
          smartphone_screen[height] * im.height,
      ]
    else
      coords = [
        cracker_exit[left] * im.width,
        cracker_exit[top] * im.height,
        (cracker_exit[left] + cracker_exit[width]) * im.width,
        (cracker_exit[top] + cracker_exit[height]) * im.height,
      ]
    setCellPhoneImgMap(coords.join(", "))
  }

  const onStartGame = () => {
    smartphone_screen = smartphone_game_screen
    setDisplayGame(false)
    setPlayGame(true)
    updateImgMap(true)
    if (timeLeft === null) {
      setTimeLeft(GAME_TIME)
      params.wsSend({
        topic: "game",
        action: "minigame_crackage_start",
        params: { team: params.team },
      })
    }
  }

  const onCloseGame = () => {
    smartphone_screen = smartphone_base_screen
    setDisplayGame(true)
    setPlayGame(false)
    updateImgMap(false)
  }

  const onLightClick = (event) => {
    if (onSuccess || onFailure || onTimeout) return
    setRender(false)
    const max = lights.length
    const isYellow = (pos, arr = lights) => arr[pos] === LIGHT_YELLOW
    const isBlue = (pos, arr = lights) => arr[pos] === LIGHT_BLUE
    const isEmpty = (pos, arr = lights) => arr[pos] === LIGHT_EMPTY
    const checkSuccess = (result) =>
      isBlue(0, result) &&
      isBlue(1, result) &&
      isBlue(2, result) &&
      isEmpty(3, result)
    const checkFailure = (result) => {
      for (let i = 0; i < result.length; i++) {
        if (i < max - 1 && isYellow(i, result) && isEmpty(i + 1, result))
          return false
        else if (
          i < max - 2 &&
          isYellow(i, result) &&
          isBlue(i + 1, result) &&
          isEmpty(i + 2, result)
        )
          return false
        else if (i > 0 && isBlue(i, result) && isEmpty(i - 1, result))
          return false
        else if (
          i > 1 &&
          isBlue(i, result) &&
          isYellow(i - 1, result) &&
          isEmpty(i - 2, result)
        )
          return false
      }
      return true
    }
    const pos = parseInt(event.target.id.slice(-1)) - 1
    const result = lights.slice(0, lights.length)
    if (pos < max - 1 && isYellow(pos) && isEmpty(pos + 1))
      [result[pos], result[pos + 1]] = [result[pos + 1], result[pos]]
    else if (
      pos < max - 2 &&
      isYellow(pos) &&
      isBlue(pos + 1) &&
      isEmpty(pos + 2)
    )
      [result[pos], result[pos + 2]] = [result[pos + 2], result[pos]]
    else if (pos > 0 && isBlue(pos) && isEmpty(pos - 1))
      [result[pos - 1], result[pos]] = [result[pos], result[pos - 1]]
    else if (pos > 1 && isBlue(pos) && isYellow(pos - 1) && isEmpty(pos - 2))
      [result[pos - 2], result[pos]] = [result[pos], result[pos - 2]]

    setLights(result)
    if (checkSuccess(result)) onSuccessAction()
    else if (checkFailure(result)) onFailureAction()
  }

  const onSuccessAction = () => {
    setOnSuccess(true)
    let cpt = 10
    const interv = setInterval(() => {
      if (cpt % 2) {
        imgCell.current.src = CELL_PLAY.src
        imgCell.current.alt = CELL_PLAY.alt
        imgText.current.src = TXT_PLAY.src
        imgText.current.alt = TXT_PLAY.alt
      } else {
        imgCell.current.src = CELL_DONE.src
        imgCell.current.alt = CELL_DONE.alt
        imgText.current.src = TXT_DONE.src
        imgText.current.alt = TXT_DONE.alt
      }
      if (cpt-- === 0) {
        clearInterval(interv)
        params.wsSend({
          topic: "game",
          action: "minigame_crackage_success",
          params: { team: params.team },
        })
      }
    }, 200)
  }

  const onFailureAction = (timeout) => {
    setOnFailure(true)
    let cpt = 10
    const interv = setInterval(() => {
      if (cpt % 2) {
        imgCell.current.src = CELL_PLAY.src
        imgCell.current.alt = CELL_PLAY.alt
        imgText.current.src = TXT_PLAY.src
        imgText.current.alt = TXT_PLAY.alt
      } else {
        imgCell.current.src = CELL_FAILED.src
        imgCell.current.alt = CELL_FAILED.alt
        imgText.current.src = TXT_FAILED.src
        imgText.current.alt = TXT_FAILED.alt
      }
      if (cpt-- === 0) {
        clearInterval(interv)
        if (timeout) {
          playFailed()
        } else {
          imgCell.current.src = CELL_PLAY.src
          imgCell.current.alt = CELL_PLAY.alt
          imgText.current.src = TXT_PLAY.src
          imgText.current.alt = TXT_PLAY.alt
          setLights(default_lights)
          setOnFailure(false)
        }
      }
    }, 200)
  }

  return (
    <>
      {playAudio ? <PlayAudio teamTag={params.team} /> : ""}
      {(displayGame || playGame) && (
        <div id="minigame-crackage">
          <map name="clickmap">
            <area
              shape="rect"
              coords={cellPhoneImgMap}
              alt="game"
              onClick={playGame ? onCloseGame : onStartGame}
            />
          </map>
          <img
            id="base"
            ref={imgBase}
            onLoad={computeResize}
            src={playGame ? SMART_CRACKER.src : SECURE_DOOR.src}
            alt={playGame ? SMART_CRACKER.alt : SECURE_DOOR.alt}
            useMap="#clickmap"
          />
          <img
            id={playGame ? "cell_game" : "cell"}
            ref={imgCell}
            src={CELL_PLAY.src}
            alt={CELL_PLAY.alt}
            onClick={onStartGame}
          />
          <img
            id={playGame ? "text_game" : "text"}
            ref={imgText}
            src={TXT_PLAY.src}
            alt={TXT_PLAY.alt}
            onClick={onStartGame}
          />
          {render &&
            lights.map((light, idx) => (
              <img
                key={idx}
                id={"light" + (idx + 1)}
                src={light.src}
                alt={light.alt}
                style={{
                  left: `${
                    smartphoneLeft + light_posx[idx] * smartphoneWidth
                  }px`,
                  top: `${smartphoneTop + light_posy * smartphoneHeight}px`,
                  width: `${light_width * smartphoneWidth}px`,
                }}
                onClick={playGame ? onLightClick : onStartGame}
              />
            ))}
          <div
            id={playGame ? "timer_game" : "timer"}
            ref={timerElt}
            className={onTimeout ? "blink-me-fast" : ""}
            onClick={onStartGame}
          >
            {timeLeft !== null &&
              pad0(Math.floor(timeLeft / 60)) + ":" + pad0(timeLeft % 60)}
          </div>
        </div>
      )}
    </>
  )
}

export default Crackage
