import React, { useState, useContext, useEffect } from "react"
import { Button } from "react-bootstrap"

import { MessagesContext } from "../ContextProvider"
import { getRandomInt } from "../../utils"

import "./Cheatsheet.css"

const Cheatsheet = (props) => {
  const messagesContext = useContext(MessagesContext)

  const [setMessage, setSetMessage] = useState(null)
  const [cbMessage, setCbMessage] = useState(null)

  useEffect(() => {
    if (messagesContext) {
      if (typeof messagesContext === "object" && "widget" in messagesContext) {
        if ("step" in messagesContext) {
          setStep(messagesContext["step"])
          setLastIndiceAction(null)
          setIndiceId(null)
          setLastIndice(false)
          // if we want to reinit 'indiceId' to the right last asked value
          // we can check here the props.messages list by the end and
          // compare the message with the step.str content, the found index is the value we could use to init indiceId.
          if (!setMessage && "setMessage" in messagesContext) {
            // set the function that permits to extract chatbot message out of it
            setSetMessage(() => messagesContext.setMessage)
          }
          if (!cbMessage && "cbMessage" in messagesContext) {
            // set a callback function to notify other teams of an indice display
            setCbMessage(() => messagesContext.cbMessage)
          }
          if ("lastIndiceAction" in messagesContext)
            setLastIndiceAction(() => messagesContext.lastIndiceAction)
        } else if ("message" in messagesContext) {
          props.actionProvider.createQuestResp(
            messagesContext.message.quest,
            messagesContext.message.resp,
            messagesContext.message.widget
          )
        }
      } else {
        props.actionProvider.createWidgetMessage(messagesContext)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesContext])

  useEffect(() => {
    if (setMessage) {
      setMessage(props.messages)
    }
  }, [props.messages, setMessage])

  const [step, setStep] = useState(null)
  const [indiceId, setIndiceId] = useState(null)
  const [lastIndice, setLastIndice] = useState(false)
  const [lastIndiceAction, setLastIndiceAction] = useState(null)
  const [locked, setLocked] = useState(false) // prevent flooding

  const defaultQuestion = "Nous avons besoin de votre aide !"
  const helpResponses = [
    "On est là pour ça.",
    "Bien sûr, c'est à quel sujet ?",
    "Allez-y, dites nous sur quoi vous avez besoin d'aide.",
    "Aucun problème. Dites-nous tout !",
    "Nous allons voir ce que nous pouvons faire, dites toujours !",
    "Je ne sais pas... En tous cas on va essayer de faire au mieux.",
    "Ok ! Sur quoi ?",
  ]
  const onClick = () => {
    if (lastIndice) return
    if (step) {
      // prevent click flood on button!
      setLocked(true)
      const nbMax = step.indices.length
      if (indiceId !== null && indiceId < nbMax) {
        props.actionProvider.createQuestResp(
          step.indices[indiceId].btn,
          step.indices[indiceId].str,
          step.indices[indiceId].widget
        )
        if (cbMessage) {
          cbMessage(
            step.indices[indiceId].btn,
            step.indices[indiceId].str,
            step.indices[indiceId].widget
          )
        }
        if (indiceId === nbMax - 1) {
          setLastIndice(true)
          if (lastIndiceAction) setTimeout(() => lastIndiceAction(), 1500)
        } else setIndiceId(indiceId + 1)
      } else if (indiceId === null) {
        const resp = helpResponses[getRandomInt(helpResponses.length)]
        props.actionProvider.createQuestResp(defaultQuestion, resp)
        if (cbMessage) {
          cbMessage(defaultQuestion, resp)
        }
        setIndiceId(0)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLocked(false)
    }, 2000)
  }, [indiceId])

  if (step != null)
    return (
      <div className="chatbot-button-widget">
        <div className="chatbot-button-widget-label">Question à TSUNAMI:</div>
        <Button
          variant={lastIndice ? "success" : "light"}
          className="shadow-none"
          onClick={onClick}
          disabled={locked}
        >
          <div className="chatbot-button-widget-intern">
            <img src="pictos/picto indice_200.png" alt="picto indice" />
            <span>
              {indiceId !== null ? step.indices[indiceId].btn : defaultQuestion}
            </span>
          </div>
        </Button>
      </div>
    )
  else return null
}

const text1 =
  "Vous n’êtes pas seuls ! Depuis le début de vos investigations, une équipe vous seconde dans l’ombre. Elle est parvenue à récupérer un certain nombre d’éléments qui vous permettront de préparer au mieux l’intervention dans la planque du Kidnappeur.\n\nNous vous conseillons de relire le  profil Facebook de L. Robirez, les fiches d'individus ouvertes pendant cette enquête, et d'écouter les enregistrements téléphoniques de G. Moreno.\n\nNous vous avons réunis de notre côté, des informations qui devraient s'avérer très utiles: "

const text2 =
  "Notre équipe a déjà effectué une première analyse psychologique du kidnappeur et est arrivée à éliminer onze profils. Il en reste cinq. Nous vous avons préparé des fiches susceptibles de vous aider à déterminer le profil de Leonardo Robirez.\n\nNous espérons que ces différentes informations vous permettront de faire les bons choix qui seront décisifs pour les forces de police qui se rendront sur place.\n\nL. \nTsunami"

const links = [
  {
    label: "Un rapport psychiatrique de L. Robirez",
    link: "docs/Hôpital de Jour psychiatrie.pdf",
  },
  {
    label: "Un rapport militaire sur ses états de service",
    link: "docs/rapport militaire Léonardo Robirez.pdf",
  },
  {
    label: "Un article de journal sur la mort de sa mère",
    link: "docs/PRESSE ARTICLE LE PARISIEN.pdf",
  },
  {
    label: "Une sélection des profils MBTI",
    link: "docs/dossier MBTI tsunami.pdf",
  },
]

export const RobirezWidget = (props) => {
  const onClick = (event) => {
    event.target.closest(".chatbot-message").removeAttribute("unread")
  }
  return (
    <div className="chatbot-message" onClick={onClick} unread="true">
      <pre style={{ whiteSpace: "pre-wrap" }}>{text1}</pre>
      <ul className="link-list">
        {links.map((link) => (
          <li key={link.label} className="link-list-item">
            <a
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
              className="link-list-item-url"
            >
              {link.label}
            </a>
          </li>
        ))}
      </ul>
      <pre style={{ whiteSpace: "pre-wrap" }}>{text2}</pre>
    </div>
  )
}

const tsunamiMsg =
  "Ici Tsunami. Il me semble que nous avons un ami et un ennemi commun. On nous a prévenus que vous pourriez avoir besoin de coups de pouce pour avancer dans vos recherches. Pour éviter d'affoler leur réseau avec un trop grand nombre d'intrusions, on ne pourra être que spectateur de votre investigation : en clair, nous voyons vos écrans respectifs, mais nous ne pouvons pas en prendre le contrôle. Donc, si vous avez besoin d'aide, sollicitez nous, mais ça ne sera pas sans risque ! Nous ferons au mieux pour vous aider."

export const TsunamiWidget = () => (
  <>
    <pre style={{ whiteSpace: "pre-wrap" }}>{tsunamiMsg}</pre>
  </>
)

const lockeeMsg =
  "Ici Tsunami !  Bon, cela sent pas bon pour vous !  Je sais pas combien de temps ils vont mettre pour savoir d’où vous vous connectez, mais s’ils y arrivent, vous pouvez être sûrs que vous allez animer votre quartier, tellement il y aura des camions de flics devant chez vous !\n\nBon, a priori, le symbole mathématique que vous avez sur l’interface est celui d’un jeu de Mathématiques que l’on a trouvé sur l’ordinateur de l’Analyste, mais on est pas arrivé à identifier l’emplacement précis. Bon, si avec cela vous vous en sortez pas, demandez de l’aide, mais c’est pas sans risque !"

export const LockeeHelpWidget = () => (
  <>
    <pre style={{ whiteSpace: "pre-wrap" }}>{lockeeMsg}</pre>
  </>
)

const miniGame3Msg1 =
  "On vous garde la brèche ouverte pour que vous puissiez vous introduire plus facilement dans leur réseau, mais cette manœuvre ne pourra pas durer éternellement ! On peut vous couvrir pendant environ 10 minutes. Au delà de quoi nous serons tous à découvert... Donc, si au bout de 8 minutes vous n'avez pas terminé votre piratage on prendra le relais en espérant qu'on puisse être sur tous les fronts sans se faire repérer..."

const miniGame3Msg2 =
  "Ca fait déjà 3 minutes que vous y êtes... Grouillez vous !"

const miniGame3Msg3 =
  "Dans 2 minutes, si vous n'y êtes pas arrivé, on prend la main..."

const miniGame3Msg4 =
  "Bougez vous les gars !!! Plus qu' 1 minute... Après quoi on s'en charge, mais j'aimerais autant ne pas prendre ce risque..."

export const MiniGame3Widget1 = () => (
  <>
    <pre style={{ whiteSpace: "pre-wrap" }}>{miniGame3Msg1}</pre>
  </>
)
export const MiniGame3Widget2 = () => (
  <>
    <pre style={{ whiteSpace: "pre-wrap" }}>{miniGame3Msg2}</pre>
  </>
)
export const MiniGame3Widget3 = () => (
  <>
    <pre style={{ whiteSpace: "pre-wrap" }}>{miniGame3Msg3}</pre>
  </>
)
export const MiniGame3Widget4 = () => (
  <>
    <pre style={{ whiteSpace: "pre-wrap" }}>{miniGame3Msg4}</pre>
  </>
)

export const TravelGPS = () => (
  <>
    <img
      src="backgrounds/réponse trajet ravisseur 1050.jpg"
      alt="trace gps"
      width="500px"
    />
  </>
)

export default Cheatsheet
