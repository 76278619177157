import React from "react"
import { Image } from "react-bootstrap"

import { DesktopIcon } from "./DesktopIcon"

import { WindowsMapping } from "./windows/WindowsMapping"

const DesktopWindow = ({
  content,
  scenario,
  onClose,
  onAction,
  className,
  id,
  style,
  children,
}) => {
  const _content = { ...content }
  if (_content.style) {
    style = { ...style, ..._content.style }
  }
  let InnerCompo = null
  let props = {}
  if (_content.component && _content.component in WindowsMapping) {
    InnerCompo = WindowsMapping[_content.component]
  } else if (_content.url) {
    InnerCompo = WindowsMapping["inner_frame"]
    props = { ..._content }
  }
  return (
    <div id={id} className={`window_border ${className}`} style={style}>
      <div className="window">
        {_content.title && (
          <div className="title">
            {_content.title}
            <div className="close" onClick={onClose}>
              X
            </div>
          </div>
        )}
        <div className="content">
          {_content.background && (
            <Image className="background" src={_content.background}></Image>
          )}
          {_content.text && (
            <div
              style={{ marginTop: "1em", width: "100%", textAlign: "center" }}
            >
              {_content.text}
            </div>
          )}
          {InnerCompo && (
            <InnerCompo
              className="window_content" //missing css class
              scenario={scenario}
              {...props}
              onAction={onAction}
            />
          )}
          {_content.icons &&
            _content.icons.map(
              (icon, i) =>
                icon.visible !== false && (
                  <DesktopIcon
                    key={i}
                    id={icon.id}
                    caption={icon.caption}
                    captionColor="black"
                    image={icon.image}
                    onClick={onAction}
                  />
                )
            )}
          {children}
        </div>
      </div>
    </div>
  )
}

export default DesktopWindow
