import React, { useState, useEffect } from "react"
import { Modal, Button, Form } from "react-bootstrap"

import { SurveyView } from "./SurveyView"
import PlayAudio from "../PlayAudio"

const AUDIO_FINAL = {
  audio12: "audio/12 - prise de contact +.mp3",
  audio13: "audio/13 - prise de contact -.mp3",
  audio14: "audio/14 - prise de contact =.mp3",
  audio15: "audio/15 - détente - film & tofu.mp3",
  audio16: "audio/16 - détente - xgames & tofu.mp3",
  audio17: "audio/17 - détente - foot & boeuf.mp3",
  audio18: "audio/18 - détente - foot & repas.mp3",
  audio19: "audio/19 - détente - foot & tofu.mp3",
  audio20: "audio/20 - détente - xgames & boeuf.mp3",
  audio21: "audio/21 - détente - xgames & repas.mp3",
  audio22: "audio/22 - détente - film & repas.mp3",
  audio23: "audio/23 - détente - film & boeuf.mp3",
  audio24: "audio/24 - téléphone - Léo Fred.mp3",
  audio25: "audio/25 - téléphone - Léo Isa.mp3",
  audio26: "audio/26 - téléphone - Léo Père - Léo et otage morts.mp3",
  audio27: "audio/27 - téléphone - Léo Père - Léo mort otage vie.mp3",
}
const AUDIO_SUCCESS = "audio/03 - intervention réussie mais personne.mp3"
const AUDIO_FAILURES = [
  "audio/02 - intervention mauvaise adresse anniv.mp3",
  "audio/01 - intervention mauvaise adresse proprio absent.mp3",
  "audio/00 - intervention mauvaise adresse maison vide.mp3",
]

const EmergencyInterResult = ({ onAction, params, scenario }) => {
  const [playAudio, setPlayAudio] = useState(false)
  const [displayForm, setDisplayForm] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)

  const tryPlay = (audioObj) => {
    try {
      audioObj.play()
    } catch (error) {
      console.log(`Error playing audio: `, error)
      setTimeout(() => tryPlay(audioObj), 1000)
    }
  }

  const tryLoadAndPlayAudio = (audioFile, cbEnd) => {
    try {
      var audioObj = new Audio(audioFile)
      audioObj.addEventListener("canplaythrough", () => {
        /* the audio is now playable; play it if permissions allow */
        tryPlay(audioObj)
      })
      audioObj.addEventListener("ended", cbEnd)
    } catch (error) {
      console.log(`Error playing audio ${audioFile}: `, error)
      setTimeout(() => tryLoadAndPlayAudio(audioFile, cbEnd), 500)
    }
  }

  const playSounds = (audioFiles) => {
    var audioIdx = 0
    const nextAudio = () => {
      // loop on audio files
      if (audioIdx < audioFiles.length) {
        tryLoadAndPlayAudio(audioFiles[audioIdx++], nextAudio)
      } else {
        onAudioEnd()
      }
    }
    tryLoadAndPlayAudio(audioFiles[audioIdx++], nextAudio)
  }

  const playSound = (audioFile) => {
    tryLoadAndPlayAudio(audioFile, onAudioEnd)
  }

  useEffect(() => {
    let audio = null
    // final audio playback
    if ("final" in params.address) {
      audio = params.address.final.audio.map((audio) => AUDIO_FINAL[audio])
    }
    // audio for 2bis intervention
    else if (params.address.id === 1) {
      audio = AUDIO_SUCCESS
    }
    // bad/unknown address
    else if ("id" in params.address && params.address.id !== 2) {
      audio = AUDIO_FAILURES[AUDIO_FAILURES.length - 1]
    }
    // bad/unknown address
    else if ("error" in params.address) {
      let audioIdx =
        params.address.error < AUDIO_FAILURES.length
          ? params.address.error
          : AUDIO_FAILURES.length - 1
      audio = AUDIO_FAILURES[audioIdx]
    }
    // last option : display the survey
    else {
      // assert "success" in params && params.success.id === 2
      setDisplayForm(true)
    }
    // check if this sound is already playing
    // ie: play only one sound at a time
    if (audio) {
      if (Array.isArray(audio)) playSounds(audio)
      else playSound(audio)
      setPlayAudio(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onAudioEnd = () => {
    setPlayAudio(false)
    if ("final" in params.address)
      onAction({
        type: "game",
        location: "EmergencyAudioEnd",
        result: { final: true },
      })
    else if (params.address.id === 1) setDisplayModal(true)
    else
      onAction({
        type: "game",
        location: "EmergencyAudioEnd",
        result: { success: true, address: params.address },
      })
  }

  const onCloseModal = () => {
    setDisplayModal(false)
    onAction({
      type: "game",
      location: "EmergencyAudioEnd",
      result: { success: true, address: params.address },
    })
  }

  return (
    <>
      {playAudio && <PlayAudio teamTag={params.team} />}
      {displayForm && (
        <SurveyView onAction={onAction} scenario={scenario} params={params} />
      )}
      {displayModal && <Modal2Bis onConfirm={onCloseModal} />}
    </>
  )
}

const EmergencyInterSurvey = ({ onAction, params, scenario }) => (
  <SurveyView onAction={onAction} scenario={scenario} params={params} />
)

const Modal2Bis = ({ onConfirm }) => {
  return (
    <>
      <Modal.Dialog className="modal-2bis" size="lg">
        <Modal.Header>
          <Modal.Title as="h6">Documents découverts au 2 Bis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Text>
            MESSAGE EQUIPE OSCAR pour DELTA : Des articles de journaux étaient
            dans la poubelle complètement chiffonnés. Je vous les transfère sur
            votre bureau.
          </Form.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => onConfirm(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </>
  )
}

export { EmergencyInterResult, EmergencyInterSurvey }
