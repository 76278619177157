import { getWebSocketURL } from "../utils"

const EscapeWebSocket = () => {
  let escWebSocket = undefined
  var wsChecker = undefined

  const checkConnection = (id, msgHandlers) => {
    if (
      escWebSocket &&
      (escWebSocket.readyState === WebSocket.OPEN ||
        escWebSocket.readyState === WebSocket.CONNECTING)
    )
      return
    // else try to reconnect
    console.log("WS problem, try to reconnect")
    clearInterval(wsChecker)
    connect(id, msgHandlers)
  }

  const connect = (id, messageHandlers) => {
    escWebSocket = new WebSocket(getWebSocketURL() + id + "/")

    escWebSocket.onmessage = (e) => {
      const data = JSON.parse(e.data)
      if ("topic" in data && data.topic in messageHandlers)
        messageHandlers[data.topic](data)
      else console.warn("Dunno how to process message: ", data)
    }

    escWebSocket.onerror = (error) => {
      console.error("EscWebSocket Error: ", error)
    }

    escWebSocket.onclose = (e) => {
      console.error("EscWebSocket.onClose: Chat socket closed unexpectedly")
      escWebSocket = undefined
    }

    // after 3 secs, start the ws checker to test connection
    setTimeout(() => {
      wsChecker = setInterval(() => checkConnection(id, messageHandlers), 1000)
    }, 3000)
  }

  const sendMessage = (message) => {
    if (escWebSocket) {
      if (typeof message !== "string") {
        message = JSON.stringify(message)
      }
      try {
        escWebSocket.send(message)
      } catch (error) {
        console.error("EscWebSocket.sendMessage: Error: ", error)
      }
    } else {
      console.error("EscWebSocket.sendMessage: Not connected!")
    }
  }

  const close = () => {
    if (escWebSocket) {
      clearInterval(wsChecker)
      escWebSocket.close()
    }
  }

  return { connect, sendMessage, close }
}

export { EscapeWebSocket }
