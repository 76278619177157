import React, { useState, useEffect } from "react"
import Score from "./Score"
import {
  getGameStats,
  getToken,
  getServerURL,
  GAME_VERSION_URI,
  GAME_CHECK_URI,
  getScoreFromScenario,
  pad0,
} from "../utils"
import axios from "axios"

const DEBUG = false
const displayStatusEnum = {
  UNFINISHED: "unfinished",
  LOADED: "loaded",
  INIT: "initialized",
  NO_TOKEN: "no_token",
  INVALID_TOKEN: "invalid_token",
}

const ScorePage = () => {
  const [displayStatus, setdisplayStatus] = useState(displayStatusEnum.INIT)
  const [scoring, setScoring] = useState()
  const fetchData = async () => {
    const team_token = getToken()
    try {
      if (team_token) {
        // and check server game version
        let res = await axios.get(getServerURL() + GAME_VERSION_URI)
        // then retrieve the game informations for this team
        let csrf = res.data.csrftoken
        const postUrl = getServerURL() + GAME_CHECK_URI
        const formData = new FormData()
        formData.append("token", team_token)
        let _res = await axios.post(postUrl, formData, {
          headers: {
            "X-CSRFToken": csrf,
          },
        })
        DEBUG && console.log("_res.data.game_status: ", _res.data.game_status)
        if (_res.data.error) {
          DEBUG && console.warn("Error synchronizing: ", _res.data.error)
        } else {
          DEBUG && console.log("Synchro ok: ", res.data)
          if (_res.data.game_id) {
            if (_res.data.game_status === "FI") {
              let __res = await getGameStats(_res.data.game_id)
              if (Object.entries(__res).length === 0) {
                // if empty, load the frontside version of the scenario
                let resp = await fetch("scenario.json")
                let json_resp = resp.json()
                DEBUG && console.log("return resp :", json_resp)
                setScoring(getScoreFromScenario(json_resp))
              } else {
                DEBUG && console.log("return res :", __res)
                setScoring(getScoreFromScenario(__res))
              }
              setdisplayStatus(displayStatusEnum.LOADED)
            } else {
              setdisplayStatus(displayStatusEnum.UNFINISHED)
            }
          }
        }
      } else {
        DEBUG && console.log("error : the team token does not exist")
        setdisplayStatus(displayStatusEnum.NO_TOKEN)
      }
    } catch (error) {
      DEBUG && console.log(error)
      setdisplayStatus(displayStatusEnum.INVALID_TOKEN)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*useEffect(() => {
    (scoring && DEBUG) && console.table(scoring)
  }, [scoring])*/

  const Message = ({ text }) => {
    return (
      <div className="modal-score-fallback">
        <span style={{ color: "#FFF", textAlign: "center", fontSize: "20px" }}>
          {text}
        </span>
      </div>
    )
  }

  const FallbackMessage = () => {
    // ce composant s'affichera lorsque le token n'est pas correctement saisi
    if (displayStatus === displayStatusEnum.NO_TOKEN) {
      return <Message text="Error : Pas de jeton de jeu" />
    } else if (displayStatus === displayStatusEnum.UNFINISHED) {
      return <Message text="Error : La session n'est pas encore terminée" />
    } else if (displayStatus === displayStatusEnum.INVALID_TOKEN) {
      return <Message text="Erreur : Jeton invalide" />
    } else {
      return <></>
    }
  }

  const GameLink = () => {
    const [timeLeft, setTimeLeft] = useState(60)
    useEffect(() => {
      if (timeLeft > 0) {
        const timer = setTimeout(() => {
          setTimeLeft(timeLeft - 1)
        }, 1000)
        // Clear timeout if the component is unmounted
        return () => {
          clearTimeout(timer)
        }
      } else {
        setTimeout(
          () =>
            window.open(
              "https://www.mindquest-games.fr/produit/complot-detat/"
            ),
          3000
        )
      }
    }, [timeLeft])

    return (
      <div className="score-link">
        <a
          href="https://www.mindquest-games.fr/produit/complot-detat/"
          target="_blank"
          rel="noreferrer"
        >
          Un nouveau Complot d'Etat va commencer ici ...
          <div className={timeLeft > 0 ? "timer" : "timer blink-me-fast"}>
            {pad0(Math.floor(timeLeft / 60))} : {pad0(timeLeft % 60)}
          </div>
        </a>
      </div>
    )
  }

  return (
    <>
      <GameLink />
      <img
        src="/report/article_de_fin.png"
        alt="article_de_fin"
        className="background-score"
      />
      {displayStatus === displayStatusEnum.LOADED && scoring ? (
        <Score scoring={scoring} extraClassName="modal-score-url" />
      ) : (
        <FallbackMessage />
      )}
    </>
  )
}

export default ScorePage
