import React, { useState, useEffect, useRef } from "react"
import useSound from "use-sound"

import { throttle } from "../../utils"

import PlayAudio from "../PlayAudio"

const AUDIO_INTRO = "audio/10 - mini jeu 3 intro.mp3"
const AUDIO_SUCCESS = "audio/11 - mini jeu 3 réussite.mp3"

const DOOR_UNLOCKER = {
  src: "minigames/piratage/base commune.png",
  alt: "door unlocker",
}

const TEXT_ALPHA = {
  src: "minigames/piratage/les étapes Analyste.png",
  alt: "text alpha",
}

const TEXT_DELTA = {
  src: "minigames/piratage/les étapes Directeur.png",
  alt: "text delta",
}

const UNLOCK_STEPS = [
  {
    src: "minigames/piratage/pictos estompés.png",
    alt: "unlock step 0",
  },
  {
    src: "minigames/piratage/ordi clair.png",
    alt: "unlock step 1",
  },
  {
    src: "minigames/piratage/bouclier clair.png",
    alt: "unlock step 2",
  },
  {
    src: "minigames/piratage/bouclier clair-1.png",
    alt: "unlock step 3",
  },
  {
    src: "minigames/piratage/virus clair.png",
    alt: "unlock step 4",
  },
]

const left = 0
const right = 0
const top = 1
const bottom = 1
const width = 2
const height = 3
const text_pad_pos = [0.034, 0.01, 0.92, 1]
const mask_pad_pos = [0.034, 0.12, 0.92, 0.05]
const word_mask_pos = [0.72, 0.12, 0, 0.05]
const mask_pad_tops = [0.12, 0.17, 0.21, 0.26] // num of line
// num of step and num of line
const word_mask_widths = [
  [0.05, 0.12, 0.19, 0.24],
  [0.05, 0.11, 0.19, 0.24],
  [0.04, 0.08, 0.13, 0.2],
  [0.07, 0.11, 0.16, 0.24],
]
const unlock_pad_pos = [0.01, 0.0, 0.42, 1]
const matrix_pad_pos = [0.034, 0.378, 0.564, 0.502]
const matrix_cell_width = 0.184
const matrix_cell_height = 0.168
const cell_figures = [
  "DDoS",
  "bruteforce",
  "fishing",
  "Chenu94",
  "worm",
  "find",
  "resident",
  "firewall",
  "backdoor",
  "virus",
  "folder",
  "spam",
  "shutdown",
]
const matrix_figures = [
  0, 1, 2, 3, 4, 5, 6, 7, 6, 8, 8, 1, 9, 4, 5, 4, 9, 7, 10, 0, 11, 12, 3, 1, 3,
]
const stepStringsList = [
  ["fishing", "resident", "backdoor", "worm"],
  ["worm", "firewall", "bruteforce", "DDoS"],
  ["DDoS", "find", "folder", "Chenu94"],
  ["Chenu94", "virus", "spam", "shutdown"],
]
const stepStrings = [
  "fishingresidentbackdoorworm",
  "wormfirewallbruteforceDDoS",
  "DDoSfindfolderChenu94",
  "Chenu94virusspamshutdown",
]

const Piratage = ({ onAction, scenario, params }) => {
  const tsunamiTimer = useRef(null)
  const optionAudioIntro = {
    onplay: () => {
      setPlayAudio(true)
    },
    onend: () => {
      setPlayAudio(false)
      setDisplayGame(true)
      // first timer warning for 8 minutes
      setTimeout(() => {
        params.toggleBot(true)
        params.setChatbotWidget("mg3_timer1")
        startTsunamiTimers()
      }, 500)
    },
    autoplay: true,
  }
  const optionAudioSuccess = {
    onplay: () => {
      setPlayAudio(true)
    },
    onend: () => {
      setPlayAudio(false)
      onAction({
        type: "game",
        location: "PiratageEnd",
        result: { success: !forceEndGame },
      })
    },
  }
  const [playIntro] = useSound(AUDIO_INTRO, optionAudioIntro)
  const [playSuccess] = useSound(AUDIO_SUCCESS, optionAudioSuccess)
  const [playAudio, setPlayAudio] = useState(false)
  const [forceEndGame, setForceEndGame] = useState(false)
  const [displayGame, setDisplayGame] = useState(false)
  const [unlockStep, setUnlockStep] = useState(params.unlockStep)
  // matrix cell status for the display
  const [strCells, setStrCells] = useState(new Array(25).fill(0))
  // selected cell path per step for gameplay
  const [selectedCells, setSelectedCells] = useState({
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
  })
  // matrix cell dimension for the display
  const [matrixCellDim, setMatrixCellDim] = useState([168, 78, 5, 5])
  const imgBase = useRef(null)
  const imgUnlock = useRef(null)
  const imgText = useRef(null)
  const textMasks = [useRef(null), useRef(null), useRef(null), useRef(null)]
  const wordMask = useRef(null)
  const matrix = useRef(null)
  const matrixHide = useRef(null)

  const startTsunamiTimers = () => {
    // second timer warning at 3 minutes (180000)
    tsunamiTimer.current = setTimeout(() => {
      params.toggleBot(true)
      params.setChatbotWidget("mg3_timer2")
      tsunamiTimer.current = setTimeout(() => {
        params.toggleBot(true)
        params.setChatbotWidget("mg3_timer3")
        tsunamiTimer.current = setTimeout(() => {
          params.toggleBot(true)
          params.setChatbotWidget("mg3_timer4")
          tsunamiTimer.current = setTimeout(() => {
            setForceEndGame(true)
          }, 60000)
        }, 100000)
      }, 180000)
    }, 250000)
  }

  // when forcing game end, trigger the success audio to finish
  useEffect(() => {
    if (forceEndGame) {
      onNextStepAction(3)
      setTimeout(() => playSuccess(), 2500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceEndGame])

  // when mounting this component, play intro audio
  useEffect(() => {
    playIntro()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const computeResize = () => {
    if (!imgBase.current) return
    const im = imgBase.current.getBoundingClientRect()

    imgText.current.style.left = im.left + text_pad_pos[left] * im.width + "px"
    imgText.current.style.top = im.top + text_pad_pos[top] * im.height + "px"
    imgText.current.style.width = text_pad_pos[width] * im.width + "px"

    textMasks.forEach((mask, idx) => {
      mask.current.style.left = im.left + mask_pad_pos[left] * im.width + "px"
      mask.current.style.top = im.top + mask_pad_tops[idx] * im.height + "px"
      mask.current.style.width = mask_pad_pos[width] * im.width + "px"
      mask.current.style.height = mask_pad_pos[height] * im.height + "px"
    })

    imgUnlock.current.style.right =
      im.right - im.width + unlock_pad_pos[right] * im.width + "px"
    imgUnlock.current.style.bottom =
      im.bottom - im.height + unlock_pad_pos[bottom] * im.height + "px"
    imgUnlock.current.style.width = unlock_pad_pos[width] * im.width + "px"

    matrix.current.style.left = im.left + matrix_pad_pos[left] * im.width + "px"
    matrix.current.style.top = im.top + matrix_pad_pos[top] * im.height + "px"
    const matrixWidth = matrix_pad_pos[width] * im.width
    const matrixHeight = matrix_pad_pos[height] * im.height
    matrix.current.style.width = matrixWidth + "px"
    if (matrixHide.current) {
      matrixHide.current.style.left = matrix.current.style.left
      matrixHide.current.style.top = matrix.current.style.top
      matrixHide.current.style.width = matrix.current.style.width
      matrixHide.current.style.height = matrixHeight + "px"
    }

    const cellDimX = matrixWidth * matrix_cell_width
    const cellDimY = matrixHeight * matrix_cell_height
    const cellMarginX = (matrixWidth - cellDimX * 5) / 10
    const cellMarginY = (matrixHeight - cellDimY * 5) / 10
    setMatrixCellDim([cellDimX, cellDimY, cellMarginX, cellMarginY])
  }

  const isGameActive = (team, step) => {
    if (step > 3) return false
    if (params.team === "alpha" && step % 2 === 0) return false
    else if (params.team === "delta" && step % 2 === 1) return false
    return true
  }

  /** Effect for the resize event */
  useEffect(() => {
    const onResize = throttle(() => {
      computeResize()
    }, 100)
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (displayGame) setTimeout(() => computeResize(), 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayGame])

  // effect called when a step change
  useEffect(() => {
    if (params.unlockStep === unlockStep) return
    // going one step forward and start with matrixCellId highlighted
    if (params.unlockStep > 0 && params.matrixCellId >= 0) {
      // animation for next step
      onNextStepAction(unlockStep)
      setUnlockStep(params.unlockStep)
      // clear the previously set cell status for active game
      if (isGameActive(params.team, params.unlockStep)) {
        const cellStatus = Array.from(strCells)
        cellStatus.fill(0)
        cellStatus[params.matrixCellId] = 3
        setStrCells(cellStatus)
        // update cell matrix with the current selected cell
        // matrixClick(params.matrixCellId, params.unlockStep, [], cellStatus)
      }
    } else if (unlockStep > 0) {
      // going one step backward, clear the last selected cell path
      setSelectedCells((oldSelect) => ({ ...oldSelect, [unlockStep]: [] }))
      const selection = Array.from(selectedCells[params.unlockStep])
      matrixClick(selection[selection.length - 1], params.unlockStep, selection)
      setUnlockStep(params.unlockStep)
      checkStepValidation(selection, params.unlockStep)
    }
    if (params.unlockStep === 4) {
      clearTimeout(tsunamiTimer.current)
      playSuccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, unlockStep, selectedCells, strCells])

  const checkStepValidation = (selection, step) => {
    const strList = selection.map(
      (cellId) => cell_figures[matrix_figures[cellId]]
    )
    const im = imgBase.current.getBoundingClientRect()
    wordMask.current.style.left =
      im.left + word_mask_pos[left] * im.width + "px"
    wordMask.current.style.top = im.top + mask_pad_tops[step] * im.height + "px"
    wordMask.current.style.width = 0 * im.width + "px"
    wordMask.current.style.height = word_mask_pos[height] * im.height + "px"
    for (let i = 0; i < strList.length; i++) {
      if (strList[i] === stepStringsList[step][i]) {
        wordMask.current.style.left =
          im.left + word_mask_pos[left] * im.width + "px"
        wordMask.current.style.top =
          im.top + mask_pad_tops[step] * im.height + "px"
        wordMask.current.style.width =
          word_mask_widths[step][i] * im.width + "px"
        wordMask.current.style.height = word_mask_pos[height] * im.height + "px"
      } else break
    }
    const str = strList.join("")
    if (str === stepStrings[step]) {
      // send wdMsg to go to next step
      params.wsSend({
        topic: "game",
        action: "minigame_piratage_success",
        params: {
          team: params.team,
          unlockStep: step + 1,
          cellId: selection[selection.length - 1],
        },
      })
      return true
    }
    return false
  }

  const onNextStepAction = (step) => {
    textMasks.forEach((mask) => (mask.current.style.display = "block"))
    let cpt = 10
    const interv = setInterval(() => {
      if (cpt % 2) {
        imgUnlock.current.src = UNLOCK_STEPS[step].src
        imgUnlock.current.alt = UNLOCK_STEPS[step].alt
        if (step < 3) textMasks[step + 1].current.style.display = "block"
      } else {
        imgUnlock.current.src = UNLOCK_STEPS[step + 1].src
        imgUnlock.current.alt = UNLOCK_STEPS[step + 1].src
        if (step < 3) textMasks[step + 1].current.style.display = "none"
      }
      if (cpt-- === 0) {
        clearInterval(interv)
      }
    }, 150)
  }

  const matrixClick = (id, step, selection) => {
    if (!isGameActive(params.team, step) || forceEndGame) return
    let cellId = parseInt(id)
    // matrix cell status = 0 : unselected
    // matrix cell status = 1 : selected by user
    // matrix cell status = 2 : last selected by user
    // matrix cell status = 3 : next possible cells
    // copy current strCells status
    let cellStatus = Array.from(strCells)
    if (
      (selection.length === 0 && cellStatus.every((st) => st === 0)) ||
      cellStatus[cellId] === 3
    ) {
      // first selection or a possible cell selected
      // when first selection, force a clean of the cellStatus (previous step)
      if (selection.length === 0) cellStatus.fill(0)
      cellStatus[cellId] = 2
      selection.forEach((cellId) => (cellStatus[cellId] = 1))
      selection.push(cellId)
      setSelectedCells((oldSelect) => ({ ...oldSelect, [step]: selection }))
      if (checkStepValidation(selection, step)) {
        // if step validated, clean possible cells and return to next player
        cellStatus = cellStatus.map((cell) => (cell === 3 ? 0 : cell))
        setStrCells(cellStatus)
        return
      }
    } else if (cellId === selection[selection.length - 1]) {
      // unselect the last selected cell
      cellStatus[cellId] = 0
      selection.pop()
      if (selection.length > 0) {
        cellId = selection[selection.length - 1]
        cellStatus[cellId] = 2
      } else {
        cellStatus.fill(0)
        // if not the first step then go backward one step!
        if (step > 0)
          params.wsSend({
            topic: "game",
            action: "minigame_piratage_success",
            params: {
              team: params.team,
              unlockStep: step - 1,
              cellId: -1,
            },
          })
      }
      checkStepValidation(selection, step)
      setSelectedCells((oldSelect) => ({ ...oldSelect, [step]: selection }))
    } else return
    if (cellStatus[cellId] === 2) {
      // reinit cell status=3 to 0
      cellStatus = cellStatus.map((cell) => (cell === 3 ? 0 : cell))
      // indentify adjacent cell-8
      const adjCells = []
      const cellI = cellId % 5
      const cellJ = Math.floor(cellId / 5)
      // left column
      if (cellI > 0) {
        adjCells.push(cellId - 1)
        if (cellJ > 0) {
          adjCells.push(cellId - 6)
        }
        if (cellJ < 4) {
          adjCells.push(cellId + 4)
        }
      }
      // right column
      if (cellI < 4) {
        adjCells.push(cellId + 1)
        if (cellJ > 0) {
          adjCells.push(cellId - 4)
        }
        if (cellJ < 4) {
          adjCells.push(cellId + 6)
        }
      }
      // top cell
      if (cellJ > 0) adjCells.push(cellId - 5)
      // bottom cell
      if (cellJ < 4) adjCells.push(cellId + 5)
      adjCells.forEach(
        (cellId) =>
          (cellStatus[cellId] = cellStatus[cellId] ? cellStatus[cellId] : 3)
      )
    }
    setStrCells(cellStatus)
  }

  return (
    <>
      {playAudio ? <PlayAudio teamTag={params.team} /> : ""}
      {displayGame ? (
        <div id="minigame-piratage">
          <img
            id="base"
            ref={imgBase}
            src={DOOR_UNLOCKER.src}
            alt={DOOR_UNLOCKER.alt}
            useMap="#clickmap"
          ></img>
          <img
            id={"unlock"}
            ref={imgUnlock}
            src={UNLOCK_STEPS[unlockStep].src}
            alt={UNLOCK_STEPS[unlockStep].alt}
          ></img>
          <img
            id={"text"}
            ref={imgText}
            onLoad={computeResize}
            src={params.team === "alpha" ? TEXT_ALPHA.src : TEXT_DELTA.src}
            alt={params.team === "alpha" ? TEXT_ALPHA.alt : TEXT_DELTA.alt}
          ></img>
          {textMasks.map((mask, idx) => (
            <div
              id={"mask" + idx}
              key={idx}
              ref={mask}
              style={idx === unlockStep ? { display: "none" } : {}}
            />
          ))}
          <div id="word_mask" ref={wordMask} />
          <div id={"matrix"} ref={matrix}>
            {strCells.map((status, id) => (
              <div
                key={id}
                id={id}
                className="cell"
                status={status}
                onClick={(event) =>
                  matrixClick(
                    event.target.id,
                    unlockStep,
                    Array.from(selectedCells[unlockStep])
                  )
                }
                style={{
                  width: matrixCellDim[0] + "px",
                  height: matrixCellDim[1] + "px",
                  margin: matrixCellDim[3] + "px " + matrixCellDim[2] + "px",
                }}
              />
            ))}
          </div>
          <div
            id={"matrix_hide"}
            ref={matrixHide}
            style={
              isGameActive(params.team, unlockStep) ? { display: "none" } : {}
            }
          />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default Piratage
