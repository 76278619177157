import React from "react"

const ScoreRow = ({ title, imgsrc, score_ref, textsrc, isArticlePage }) => {
  return (
    <div
      className="modal-score-row"
      style={{ maxHeight: isArticlePage ? "10rem" : "2.5rem" }}
    >
      <span
        className="modal-score-row-left"
        style={{
          paddingLeft: isArticlePage ? "5vw" : "1rem",
          fontSize: isArticlePage ? "3.5vh" : "0.84rem",
        }}
      >
        {title}
      </span>
      <div className="modal-score-row-right">
        {imgsrc &&
          [...Array(5)].map((e, i) => (
            <img
              key={i}
              className={`icon-score ${(i >= score_ref ?? 5) && "tinted"}`}
              style={{
                width: isArticlePage ? "3.5vh" : "1rem",
                height: isArticlePage ? "3.5vh" : "1rem",
              }}
              src={imgsrc}
              alt="result modal"
            />
          ))}
        {textsrc !== undefined && (
          <span style={{ fontSize: isArticlePage ? "3.5vh" : "0.84rem" }}>
            {textsrc}
          </span>
        )}
      </div>
    </div>
  )
}

const Score = ({ scoring, extraClassName }) => {
  return (
    <div className={`modal-score ${extraClassName}`}>
      <img src="/pictos/score_title.png" alt="result modal" />
      <ScoreRow
        title={"HACKERS"}
        isArticlePage={extraClassName === "modal-score-url"}
        imgsrc={"/pictos/anonymous.png"}
        score_ref={scoring.hacker}
      />
      <ScoreRow
        title={"ENQUETEUR"}
        isArticlePage={extraClassName === "modal-score-url"}
        imgsrc={"/pictos/loupe.png"}
        score_ref={scoring.investig}
      />
      <ScoreRow
        title={"MENEUR D'HOMMES"}
        isArticlePage={extraClassName === "modal-score-url"}
        imgsrc={"/pictos/police.png"}
        score_ref={scoring.leader}
      />
      <img src="/pictos/score_final.png" alt="result modal" />
      <ScoreRow
        title={"NOMBRE D'INDICES"}
        textsrc={scoring.indiceCount}
        isArticlePage={extraClassName === "modal-score-url"}
      />
      <ScoreRow
        title={"TAUX DE REUSSITE"}
        textsrc={scoring.score + "%"}
        isArticlePage={extraClassName === "modal-score-url"}
      />
    </div>
  )
}

export default Score
