import React, { useState, useEffect, useRef } from "react"
import { Alert, Modal, Button, Form, Row, Col } from "react-bootstrap"

import { DesktopIcon } from "./DesktopIcon"
import { MASTERMIND_CHARSET, MasterMind } from "./DesktopAnalyste"

const MailContent = () => (
  <div className="alert-mail-mission">
    <p>Objet : objectif de mission</p>
    Salut tout le monde, ici Rob
    <br />
    <br />
    Ce mail, d’abord, tu l’envoies à tous les membres de ton équipe, c’est clair
    ?!
    <br />
    <br />
    Bon, il parait que vous êtes les meilleurs Hackers du moment (enfin surtout
    les seuls dispos).
    <br />
    Alors pour la faire courte, j’ai un employeur un peu illuminé, un idéaliste
    qui milite activement contre toutes les formes de mesures coercitives et
    liberticides que le gouvernement prend dans notre dos. Vous savez, le genre
    de loi qu’ils font voter un dimanche soir en pleines vacances de Noël, pile
    au moment d’une nouvelle annonce de confinement à cause d’un satané virus.
    Ou le soir de l’attaque du Capitole par une bande de suprématistes ! Je peux
    vous assurer qu’à tous les coups, ça passe crème, et le bas peuple que nous
    sommes, on n’en entend même pas parler !
    <br />
    <br />
    Bon, mon supérieur a une opération à vous confier qui pourrait changer la
    face du monde, et en mieux !
    <br />
    Mais, avant cela, il a besoin de tester vos compétences ! Je me doute que
    vous n’êtes là que pour l’appât du gain, mais si par hasard, vous êtes un
    brin idéaliste, alors c’est la cerise sur le pompon ! Allez, j’arrête de
    raconter ma vie.
    <br />
    <br />
    Avant qu’on ne vous paye pour sauver le monde, vous allez devoir réussir la
    mission suivante :
    <br />
    <br />
    <div className="block2">
      Pénétrer les serveurs informatiques de la préfecture de police de Paris,
      et détruire un fichier spécifique sur l'un des ordinateurs:
      <span style={{ color: "red" }}>3WHISKEY15HOTELROMEO</span>
    </div>
  </div>
)

const AlertMail = ({ onClose }) => {
  const [displayMail, setDisplayMail] = useState(false)

  const alert = useRef(null)

  const clickMail = () => {
    if (!displayMail) {
      setDisplayMail(true)
      alert.current.style.cursor = "auto"
    }
  }

  return (
    <Alert
      ref={alert}
      className="alert-mail"
      variant="info"
      onClick={() => clickMail()}
      onClose={onClose}
      dismissible={true}
    >
      <Alert.Heading>
        <img src="pictos/picto indice_200.png" alt="picto indice" />
        Vous avez un mail !
      </Alert.Heading>
      {displayMail ? <MailContent /> : ""}
    </Alert>
  )
}

const AlertWrongLogin = (props) => {
  const [displayHelp, setDisplayHelp] = useState(0)
  const clickInfo = () => {
    if (!displayHelp) {
      setDisplayHelp(1)
      props.setAlert(2)
    } else {
      props.setAlert(0)
    }
  }
  const clickInfo2 = () => {
    setDisplayHelp(2)
  }
  return (
    <Alert
      className="alert-director"
      variant="danger"
      onClose={clickInfo}
      dismissible={displayHelp !== 0}
    >
      <Alert.Heading>Mauvais Code !</Alert.Heading>
      <hr />
      {props.error > 1 && !displayHelp ? (
        <>
          <div className="d-flex justify-content-between">
            <Button onClick={clickInfo} variant="outline-danger">
              <img src="pictos/picto indice_200.png" alt="picto indice" />
              Plus d'infos?
            </Button>
          </div>
        </>
      ) : (
        ""
      )}
      {displayHelp === 1 ? (
        <>
          <span className="alert-director-message">
            Etes vous sûr de vouloir voir la signification des codes couleur ?
          </span>
          <div className="alert-director-button-container">
            <Button onClick={clickInfo2} variant="outline-danger">
              <img src="pictos/picto indice_200.png" alt="picto indice" />
              Plus d'infos!
            </Button>
          </div>
        </>
      ) : displayHelp === 2 ? (
        <>
          <div className="d-flex flex-column">
            <div className="alert-director-hints">
              <MasterMind text={[MASTERMIND_CHARSET.checked]} />: Bon caractère,
              bonne position.
            </div>
            <div className="alert-director-hints">
              <MasterMind text={[MASTERMIND_CHARSET.empty]} />: Bon caractère,
              mauvaise position.
            </div>
            <div className="alert-director-hints">
              <MasterMind text={[MASTERMIND_CHARSET.failed]} />: Mauvais
              caractère.
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </Alert>
  )
}

const LoginDialog = ({ onAction }) => {
  const [alert, setAlert] = useState(0)
  const [nbError, setNbError] = useState(0)
  const passwd = "alice10"

  const alertDismiss = useRef(null)

  useEffect(() => {
    if (alert === 1) {
      alertDismiss.current = setTimeout(() => setAlert(0), 5000)
    } else if (alert === 2) {
      clearTimeout(alertDismiss.current)
    }
  }, [alert])

  const onChange = (e) => {
    const code = e.keyCode
    if (
      (code >= 48 && code <= 57) ||
      (code >= 65 && code <= 90) ||
      (code >= 96 && code <= 105)
    ) {
      e.target.value = String.fromCharCode(e.keyCode)
      if (e.target.value && e.target.nextSibling) {
        e.target.nextSibling.focus()
        e.preventDefault()
      }
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())
    let stringArr = Object.values(formDataObj)
    stringArr = stringArr.map((c) => (c ? c : " "))
    stringArr = stringArr.join("").toLowerCase()
    if (stringArr === passwd) {
      onAction({
        type: "game",
        location: "Login",
        result: "success",
      })
    } else {
      setAlert(1)
      setNbError((error) => error + 1)
      onAction({
        type: "game",
        location: "Login",
        result: { failed: stringArr },
      })
    }
  }
  return (
    <>
      <Modal.Dialog className="login-modal" centered size="lg">
        <Form className="form-login" onSubmit={onSubmit}>
          <Modal.Header className="justify-content-center">
            <Modal.Title>Code Confidentiel</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex" }}>
            {[...Array(passwd.length).keys()].map((i) => (
              <Form.Control
                key={i}
                name={"field" + i}
                type="text"
                maxLength="1"
                //onChange={onChange}
                onKeyDown={onChange}
              />
            ))}
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="primary" type="submit" size="sm">
              OK
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Dialog>
      {alert ? <AlertWrongLogin error={nbError} setAlert={setAlert} /> : ""}
    </>
  )
}

const DesktopDirecteur = ({ onFolderClick, content, onAction, login }) => {
  const [mailAlert, setMailAlert] = useState(false)

  const mailTimer = useRef(null)

  const onCloseMailAlert = () => {
    setMailAlert(false)
    // while archive isn't deleted, trig the mail reminder
    if (content.step === 1)
      mailTimer.current = setTimeout(() => setMailAlert(true), 60000)
  }

  useEffect(() => {
    if (content.step > 1 && !mailTimer.current) return
    if (content.step > 1) {
      // disable the timeout that display mail info
      setMailAlert(false)
      clearTimeout(mailTimer.current)
      mailTimer.current = null
    } else if ("success" in login) {
      mailTimer.current = setTimeout(() => setMailAlert(true), 600000)
    }
  }, [content, login])

  return (
    <div className="desktop_directeur">
      {"success" in login ? (
        <div className="desktop_folders_d">
          <Row noGutters>
            {/**
          <Col xs={2}>
            <DesktopIcon {...content.folders.D1} onClick={onFolderClick} />
          </Col>
           */}
            <Col xs={2}>
              <DesktopIcon {...content.folders.D5} onClick={onFolderClick} />
            </Col>
            <Col xs={2}>
              {content.folders.HACK_TIP.visible ? (
                <DesktopIcon
                  {...content.folders.HACK_TIP}
                  onClick={onFolderClick}
                />
              ) : (
                ""
              )}
            </Col>
            <Col xs={2}>
              {content.folders.D_SURVEY.visible ? (
                <DesktopIcon
                  {...content.folders.D_SURVEY}
                  onClick={() =>
                    onAction({
                      type: "game",
                      location: "SurveyView",
                      result: "open",
                    })
                  }
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={2}>
              <DesktopIcon {...content.folders.D2} onClick={onFolderClick} />
            </Col>
            <Col xs={2}>
              {content.folders.AUDIO_MORENO.visible ? (
                <DesktopIcon
                  {...content.folders.AUDIO_MORENO}
                  onClick={() =>
                    onAction({
                      type: "game",
                      location: "MorenoAudioClick",
                      result: { success: true },
                    })
                  }
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={2}>
              <DesktopIcon {...content.folders.D4} onClick={onFolderClick} />
            </Col>
            <Col xs={2}>
              {content.folders.D_DOC_2BIS.visible ? (
                <DesktopIcon
                  {...content.folders.D_DOC_2BIS}
                  onClick={onFolderClick}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={2}>
              <DesktopIcon {...content.folders.D3} onClick={onFolderClick} />
            </Col>
            <Col xs={2}>
              {content.folders.D_MINIGAME1.visible ? (
                <DesktopIcon
                  {...content.folders.D_MINIGAME1}
                  onClick={onFolderClick}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
          {mailAlert ? <AlertMail onClose={onCloseMailAlert} /> : ""}
        </div>
      ) : (
        <LoginDialog onAction={onAction} />
      )}
    </div>
  )
}

export default DesktopDirecteur
