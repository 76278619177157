import { getStringNorm } from "./DbUtils"

const searchDb = (number, street, city) => {
  for (const address of AddressDb) {
    if (
      address.number.includes(getStringNorm(number)) &&
      address.street.includes(getStringNorm(street)) &&
      address.city.includes(getStringNorm(city))
    ) {
      return address
    }
  }
  return null
}

const AddressDb = [
  {
    id: 1,
    number: ["2b", "2 b", "2bis", "2 bis"],
    street: ["impasse de conde"],
    city: ["94100", "saint-maur-des-fosses", "saint maur des fosses"],
    address: "2Bis impasse de Condé, 94100 Saint-Maur-des-Fossés",
  },
  {
    id: 2,
    number: ["79"],
    street: ["rue louise chenu", "louise chenu"],
    city: ["94450", "limeil-brevannes", "limeil brevannes"],
    address: "79 rue Louise Chenu, 94450 Limeil-Brévannes",
  },
  {
    id: 3,
    number: ["13"],
    street: ["rue aristide briand", "aristide briand"],
    city: ["94340", "joinville-le-pont", "joinville le pont"],
    address: "13 rue Aristide Briand, 94340 Joinville-le-Pont",
  },
  {
    id: 4,
    number: ["2"],
    street: ["rue de l'aulnaye dracourt", "de l'aulnaye dracourt"],
    city: ["91300", "massy"],
    address: "Zi de la Bonde, 2 rue de l'Aulnaye Dracourt, 91300 Massy",
  },
  {
    id: 5,
    number: ["10"],
    street: ["avenue pozzo di borgo"],
    city: ["92210", "saint-cloud", "saint cloud"],
    address: "10 avenue Pozzo di Borgo, 92210 Saint-Cloud",
  },
  {
    id: 6,
    number: ["7"],
    street: ["rue cadet", "cadet"],
    city: ["75009", "paris"],
    address: "7 rue Cadet, 75009 Paris",
  },
  {
    id: 7,
    number: ["156"],
    street: ["rue alexandre fourny", "alexandre fourny"],
    city: ["94500", "champigny-sur-marne", "champigny sur marne"],
    address: "156 rue Alexandre Fourny, 94500 Champigny-sur-Marne",
  },
]

const AddressDocs = [
  {
    id: 1,
    cadastre: "docs/fiche cadastrale impasse de condé.pdf",
    owner: "docs/proprio impasse de condé.pdf",
  },
  {
    id: 2,
    cadastre: "docs/fiche cadastrale rue louise chenu.pdf",
    owner: "docs/proprio rue louise chenu.pdf",
  },
  {
    id: 3,
    cadastre: "docs/fiche cadastrale aristide briand.pdf",
    owner: "docs/proprio aristide briand.pdf",
  },
  {
    id: 4,
    cadastre: "docs/fiche cadastrale aulnaye dracourt.pdf",
    owner: "docs/proprio aulnaye dracourt.pdf",
  },
  {
    id: 5,
    cadastre: "docs/fiche cadastrale pozzo di borgo.pdf",
    owner: "docs/proprio pozzo di borgo.pdf",
  },
  {
    id: 6,
    cadastre: "docs/fiche cadastrale rue cadet.pdf",
    owner: "docs/proprio rue cadet.pdf",
  },
  {
    id: 7,
    cadastre: "docs/fiche cadastrale alexandre fourny.pdf",
    owner: "docs/proprio alexandre fourny.pdf",
  },
]

export default AddressDb
export { searchDb, AddressDocs }
