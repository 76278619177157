import React from "react"

// Config starter code
import { Chatbot, createChatBotMessage } from "react-chatbot-kit"

import { DesktopIcon } from "../DesktopIcon"
import Cheatsheet, {
  LockeeHelpWidget,
  TsunamiWidget,
  RobirezWidget,
  TravelGPS,
  MiniGame3Widget1,
  MiniGame3Widget2,
  MiniGame3Widget3,
  MiniGame3Widget4,
} from "./Cheatsheet"

// MessageParser starter code
class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider
    this.state = state
    this.chatbot_nl_catalog = [
      {
        intent: "greet",
        strings: ["hello", "salut", "bonjour", "coucou"],
      },
      {
        intent: "indice",
        strings: ["indice", "aide", "help"],
      },
      {
        intent: "sexe",
        strings: [
          "t'es une fille",
          "t'es un gars",
          "t'es une femme",
          "t'es un homme",
          "sexe",
        ],
      },
      {
        intent: "creator",
        strings: ["team", "createurs", "easteregg", "creators"],
      },
      {
        intent: "unknown",
        strings: [""],
      },
    ]
  }

  findIntent(message) {
    for (const category of this.chatbot_nl_catalog) {
      for (const str of category.strings) {
        if (message.includes(str)) return category.intent
      }
    }
    return undefined
  }

  parse(message) {
    const msg = message.toLowerCase()
    const intent = this.findIntent(msg)
    if (intent) {
      this.actionProvider[intent]()
    }
  }
}

// ActionProvider starter code
class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.setState = setStateFunc
    this.createChatBotMessage = createChatBotMessage
    this.createClientMessage = createClientMessage
    this.addMessage = (msg) => {
      const _msg = createChatBotMessage(msg, {
        delay: 500,
      })
      this.updateChatbotState(_msg)
    }
    this.addClientMessage = (msg) => {
      const _msg = createClientMessage(msg)
      this.updateChatbotState(_msg)
    }
  }

  createWidgetMessage(widget) {
    const msg = createChatBotMessage(null, {
      widget: widget,
    })
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, msg],
    }))
  }

  createQuestResp(quest, resp, widget) {
    const clientMsg = this.createClientMessage(quest)
    const botMsg = this.createChatBotMessage(resp, {
      delay: 500,
    })
    const msgList = [clientMsg, botMsg]
    if (widget) {
      const widgetMsg = createChatBotMessage(null, {
        widget: widget,
        delay: 1500,
      })
      msgList.push(widgetMsg)
    }
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages].concat(msgList),
    }))
  }

  creator() {
    this.addMessage(
      "La team de choc: Cyril Villalonga (scenario, audio), Florent Attard (scenario, gfx, video, audio), Sebastien Schneider (code)"
    )
  }

  indice() {
    this.addMessage("Utilisez le bouton d'indice ci-dessous.")
  }

  greet() {
    this.addMessage(
      "Salut, compatriote. Si vous souhaitez de l'aide utilisez le bouton ci-dessous lorsqu'il est actif, mais attention, cela n'est pas sans risque !"
    )
  }

  sexe() {
    this.addMessage(
      "Mmmhhh, ne perdez pas votre précieux temps, je suis non-binaire !"
    )
  }

  unknown() {
    this.addMessage("Je n'ai pas compris, veuillez reformuler.")
  }

  updateChatbotState(message) {
    /*
     NOTE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.
    */
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }))
  }
}

const EscapeBot = (props) => {
  const config = {
    botName: "Tsunami",
    lang: "fr",
    initialMessages: [
      /*createChatBotMessage(null, {
        withAvatar: false,
        widget: "cheatsheet",
      }),*/
    ],
    customStyles: {
      fontSize: "50%",
    },
    customComponents: {
      // Replaces the default bot avatar
      botAvatar: (props) => null,
      header: () => (
        <div className="react-chatbot-kit-chat-header">
          <div className="chatbot-header-label">Conversation avec Tsunami</div>
          <button
            className="chatbot-header-close"
            onClick={(obj) => props.toggleBot((prev) => !prev)}
          >
            X
          </button>
        </div>
      ),
      //botChatMessage: (props) => (props.message ? props.message : ""),
      // Replaces the default user icon
      // userAvatar: (props) => <MyUserAvatar {...props} />,
    },
    widgets: [
      {
        widgetName: "cheatsheet",
        widgetFunc: (props) => <Cheatsheet {...props} />,
        mapStateToProps: ["messages"],
      },
      {
        widgetName: "lockee_help_widget",
        widgetFunc: (props) => <LockeeHelpWidget {...props} />,
      },
      {
        widgetName: "tsunami_widget",
        widgetFunc: (props) => <TsunamiWidget {...props} />,
      },
      {
        widgetName: "robirez_widget",
        widgetFunc: (props) => <RobirezWidget {...props} />,
      },
      {
        widgetName: "travel_gps",
        widgetFunc: (props) => <TravelGPS {...props} />,
      },
      {
        widgetName: "mg3_timer1",
        widgetFunc: (props) => <MiniGame3Widget1 {...props} />,
      },
      {
        widgetName: "mg3_timer2",
        widgetFunc: (props) => <MiniGame3Widget2 {...props} />,
      },
      {
        widgetName: "mg3_timer3",
        widgetFunc: (props) => <MiniGame3Widget3 {...props} />,
      },
      {
        widgetName: "mg3_timer4",
        widgetFunc: (props) => <MiniGame3Widget4 {...props} />,
      },
    ],
  }

  return (
    <>
      <div
        className="chatbot-container"
        style={props.displayBot ? { display: "flex" } : { display: "none" }}
      >
        <Chatbot
          config={config}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          placeholderText="indice"
          messageHistory={props.loadedMessages}
          saveMessages={props.saveMessages}
        />
      </div>
      <DesktopIcon
        id="CHATBOT"
        image="pictos/logo tsunami.png"
        className={
          props.displayBot ? "chatbot-button" : "chatbot-button blink_me"
        }
        classNameImage="chatbot-button-image"
        onClick={() => props.toggleBot((prev) => !prev)}
      />
    </>
  )
}

export { EscapeBot }
