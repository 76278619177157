import InnerFrame from "./InnerFrame"
import LockeeFrame from "./LockeeFrame"
import { MixageAudioDelta, MixageAudioAlpha } from "./MixageAudio"
import {
  CellTrackingForm,
  VehicleRegLookupForm,
  ArchiveLookupForm,
  PersonLookupForm,
  AddressLookupForm,
  EmergencyInterForm,
} from "./Forms"
import PictoToDelete from "./PictoToDelete"
import MSDosWindow from "./MSDosWindow"
import CellPhoneLocalization from "./CellPhoneLocalization"
import { PersonView } from "./PersonView"
import HackerTip from "./HackerTip"
import { EmergencyInterResult, EmergencyInterSurvey } from "./EmergencyInter"
import Deminage from "./Deminage"
import Piratage from "./Piratage"
import Crackage from "./Crackage"
import FinalReport from "./FinalReport"
import CityCamReport from "./CityCamReport"

export const WindowsMapping = {
  inner_frame: InnerFrame,
  cell_tracking: CellTrackingForm,
  localize_cellphone: CellPhoneLocalization,
  vehicle_reg_lookup: VehicleRegLookupForm,
  citycam_report: CityCamReport,
  mixage_audio_delta: MixageAudioDelta,
  mixage_audio_alpha: MixageAudioAlpha,
  module_lockee: LockeeFrame,
  picto_to_delete: PictoToDelete,
  archive_lookup: ArchiveLookupForm,
  person_lookup: PersonLookupForm,
  person_view: PersonView,
  address_lookup: AddressLookupForm,
  emergency_inter: EmergencyInterForm,
  emergency_inter_survey: EmergencyInterSurvey,
  msdos_mission_ok: MSDosWindow,
  hacker_tip: HackerTip,
  emergency_inter_result: EmergencyInterResult,
  minigame_deminage: Deminage,
  minigame_crackage: Crackage,
  minigame_piratage: Piratage,
  final_report: FinalReport,
}
