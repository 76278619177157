import React, { useState, useEffect } from "react"
import { Col, Row, Form, Image, Button } from "react-bootstrap"
import DesktopWindow from "../DesktopWindow"

const FormGroupRow2Radios = ({
  labelLeft,
  labelRight,
  value,
  radioGroup,
  radioId,
  readOnly,
  onChange,
}) => (
  <Form.Group as={Row} className="survey-one-line">
    <Col sm={5} className="d-flex justify-content-left">
      <Form.Check
        inline
        type="radio"
        name={radioGroup}
        id={radioId + "0"}
        label={labelLeft}
        onChange={onChange}
        required
        checked={value === 0}
        disabled={readOnly && value !== 0}
      />
    </Col>
    <Col sm={2} className="d-flex justify-content-center">
      <Form.Check
        inline
        type="radio"
        name={radioGroup}
        id={radioId + "1"}
        className="d-flex justify-content-center"
        onChange={onChange}
        required
        checked={value === 1}
        disabled={readOnly && value !== 1}
      />
    </Col>
    <Col sm={5}>
      <Form.Check
        inline
        type="radio"
        name={radioGroup}
        id={radioId + "2"}
        label={labelRight}
        className="d-flex flex-row-reverse"
        onChange={onChange}
        required
        checked={value === 2}
        disabled={readOnly && value !== 2}
      />
    </Col>
  </Form.Group>
)

const FormGroupRow3Radios = ({
  label,
  value,
  radioName,
  colSizes = [3, 3, 3, 3],
  readOnly,
  radio3disabled = false,
  onChange,
}) => (
  <Form.Group as={Row} className="survey-one-line">
    <Col sm={colSizes[0]}>
      <Form.Label>{label}</Form.Label>
    </Col>
    <Col sm={colSizes[1]} className="d-flex justify-content-center">
      <Form.Check
        type="radio"
        name={radioName}
        id={radioName + "0"}
        className="d-flex justify-content-center"
        onChange={onChange}
        checked={value === 0}
        required
        disabled={readOnly && value !== 0}
        style={{ marginTop: "-8px" }}
      />
    </Col>
    <Col sm={colSizes[2]} className="d-flex justify-content-center">
      <Form.Check
        type="radio"
        name={radioName}
        id={radioName + "1"}
        className="d-flex justify-content-center"
        onChange={onChange}
        checked={value === 1}
        required
        disabled={readOnly && value !== 1}
        style={{ marginTop: "-8px" }}
      />
    </Col>
    <Col sm={colSizes[3]} className="d-flex justify-content-center">
      <Form.Check
        type="radio"
        name={radioName}
        id={radioName + "2"}
        className="d-flex justify-content-center"
        onChange={onChange}
        checked={value === 2}
        required
        disabled={(readOnly && value !== 2) || radio3disabled}
        style={{ marginTop: "-8px" }}
      />
    </Col>
  </Form.Group>
)

const FormGroupRow1Radio = ({
  label,
  value,
  radioGroup,
  radioId,
  readOnly,
  onChange,
}) => (
  <>
    <Form.Group as={Row}>
      <Col>
        <Form.Check
          inline
          type="radio"
          name={radioGroup}
          id={radioId}
          label={label}
          onChange={onChange}
          required
          checked={value}
          disabled={readOnly && !value}
        />
      </Col>
    </Form.Group>
  </>
)

const SurveyForm = ({
  survey,
  updateSurvey,
  handleSubmit,
  submitted,
  readOnly,
}) => {
  const [allFilled, setAllFilled] = useState(false)

  useEffect(() => {
    if (!("results" in survey)) {
      setAllFilled(
        !Object.keys(survey)
          .map((type) => survey[type].data.some((dat) => dat.choice === null))
          .some((v) => v)
      )
    } else {
      // there is already results == user disconnected during the 3 mini hacking games.
      setAllFilled(true)
    }
  }, [survey])

  const onChange = (event) => {
    const name = event.target.name
    const id = event.target.id
    let value = parseInt(id[id.length - 1])
    if (name.startsWith("barthes")) {
      let row = parseInt(name.slice(7))
      updateSurvey({ id: "barthes", row: row, value: value })
    } else if (name.startsWith("motivation")) {
      updateSurvey({ id: "motivation", value: value })
    } else if (name.startsWith("ante")) {
      let row = parseInt(name.slice(4))
      updateSurvey({ id: "antecedents", row: row, value: value })
    } else if (name.startsWith("profilage")) {
      let row = parseInt(name.slice(9))
      updateSurvey({ id: "profilage", row: row, value: value })
    } else if (name.startsWith("psycho")) {
      value = parseInt(id.slice(6))
      updateSurvey({ id: "psycho", value: value })
    }
  }

  return (
    <div>
      <Form className="survey-form" onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Form.Label className="d-flex justify-content-center form-title-img">
            <Image src="pictos/Logo_de_la_République_française_(1999).svg" />
          </Form.Label>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label className="d-flex justify-content-center form-title">
            QUESTIONNAIRE À REMPLIR POUR L’INTERVENTION D’URGENCE
          </Form.Label>
          <Form.Text as="span">
            Vous avez demandé une intervention des services de police à
            l’adresse suivante :{" "}
            <i>79 rue Louise Chenu, 94450 Limeil-Brévannes</i> dans le cadre
            d’une prise d’otage. Merci de remplir les questionnaires ci-dessous
            afin de permettre au négociateur de faire le plus efficacement son
            travail. Les réponses que vous fournirez sont d’une importance
            capitale.
          </Form.Text>
        </Form.Group>
        <br></br>
        <Row className="survey-part-title">Questionnaire de Barthes</Row>
        <Form.Group as={Row}>
          <Form.Label column="sm" sm="3"></Form.Label>
          {survey.barthes.columns.map((data, idx) => (
            <Form.Label
              key={"key_barthes_header_" + idx}
              column="sm"
              sm="3"
              className="d-flex justify-content-center"
            >
              {data}
            </Form.Label>
          ))}
        </Form.Group>
        {survey.barthes.data.map((data, idx) => (
          <FormGroupRow3Radios
            key={"key_barthes_data_" + idx}
            label={data.value}
            value={data.choice}
            radioName={"barthes" + idx}
            readOnly={readOnly}
            radio3disabled={idx === 0 || idx === 1 || idx === 2}
            onChange={onChange}
          />
        ))}
        <Row className="survey-part-title">Motivation du kidnappeur</Row>
        {survey.motivation.columns.map((data, idx) => (
          <FormGroupRow1Radio
            key={"key_motiv_data_" + idx}
            label={data}
            value={survey.motivation.data[0].choice === idx}
            radioGroup="motivation"
            radioId={"motivation" + idx}
            readOnly={readOnly}
            onChange={onChange}
          />
        ))}
        <Row className="survey-part-title">Antécédents</Row>
        <Form.Group as={Row}>
          <Form.Label column="sm" sm="9"></Form.Label>
          {survey.antecedents.columns.map((data, idx) => (
            <Form.Label
              key={"key_ante_header_" + idx}
              column="sm"
              sm="1"
              className="d-flex justify-content-center"
            >
              {data}
            </Form.Label>
          ))}
        </Form.Group>
        {survey.antecedents.data.map((data, idx) => (
          <FormGroupRow3Radios
            key={"key_ante_data_" + idx}
            label={data.value}
            value={data.choice}
            radioName={"ante" + idx}
            colSizes={[9, 1, 1, 1]}
            readOnly={readOnly}
            onChange={onChange}
          />
        ))}
        <Row className="survey-part-title">Profilage suspect</Row>
        <Form.Group as={Row}>
          <Form.Label column="sm" sm="4"></Form.Label>
          <Form.Label
            column="sm"
            sm="4"
            className="d-flex justify-content-center"
          >
            {survey.profilage.columns[1]}
          </Form.Label>
          <Form.Label column="sm" sm="4"></Form.Label>
        </Form.Group>
        {survey.profilage.data.map((data, idx) => (
          <FormGroupRow2Radios
            key={"key_profil_data_" + idx}
            labelLeft={data.value1}
            labelRight={data.value2}
            value={data.choice}
            radioGroup={"profilage" + idx}
            radioId={"profilage" + idx}
            readOnly={readOnly}
            onChange={onChange}
          />
        ))}
        <Row className="survey-part-title">Profil psychologique</Row>
        <Form.Row>
          <div className="survey-form-psychology">
            {survey.psycho.columns.map((data, idx) => (
              <div
                key={"key_psycho_data_" + idx}
                style={{ minWidth: "4rem", marginTop: "10px" }}
              >
                <Form.Check
                  inline
                  label={data}
                  name="psycho"
                  type="radio"
                  id={`psycho${idx}`}
                  onChange={onChange}
                  required
                  checked={survey.psycho.data[0].choice === idx}
                  disabled={readOnly && !(survey.psycho.data[0].choice === idx)}
                />
              </div>
            ))}
          </div>
        </Form.Row>
        {readOnly && (
          <Form.Row>
            <Col sm="12">
              <Button
                size="sm"
                variant="primary"
                type="submit"
                block
                disabled={!allFilled || submitted}
              >
                Lancer l'Intervention d'Urgence
              </Button>
            </Col>
          </Form.Row>
        )}
      </Form>
    </div>
  )
}

const SurveyView = ({ onAction, scenario, params }) => {
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setSubmitted(true)
    // check form content
    // compute the score here
    let scoreOk = 0,
      scoreNok = 0,
      scoreNSP = 0,
      scorePsy = false
    const survey = scenario.survey
    // scoring from barthes
    survey.barthes.data.forEach((val) =>
      val.choice === 2
        ? scoreNSP++
        : val.choice === val.resp
        ? scoreOk++
        : scoreNok++
    )
    // scoring from motivation
    survey.motivation.data[0].choice === survey.motivation.data[0].resp
      ? scoreOk++
      : scoreNok++
    // scoring from antecedents
    survey.antecedents.data.forEach((val) =>
      val.choice === 2
        ? scoreNSP++
        : val.choice === val.resp
        ? scoreOk++
        : scoreNok++
    )
    // scoring from profilage
    survey.profilage.data.forEach((val) => {
      // some response not used
      if (val.resp !== null) {
        val.choice === 1
          ? scoreNSP++
          : val.choice === val.resp
          ? scoreOk++
          : scoreNok++
      }
    })
    // add an always good response for "Sociable" item whatever answer
    scoreOk++ // profilage score update
    // scoring from psychology
    scorePsy = survey.psycho.data[0].choice === survey.psycho.data[0].resp
    // compute score percent
    let scorePercent = scoreOk * 4 - scoreNok * 2
    if (scorePsy) scorePercent += 10
    // build the audio list corresponding to survey answer
    const audioList = []
    // first scoring part 'contact'
    if (scoreOk > scoreNok && scoreOk > scoreNSP) audioList.push("audio12")
    else if (
      (scoreOk === scoreNok && scoreOk === scoreNSP) ||
      scoreOk === scoreNok + scoreNSP
    )
      audioList.push("audio14")
    else audioList.push("audio13")
    // second scoring part 'detente'
    const snow = survey.barthes.data[4].choice === 0
    const foot = survey.barthes.data[8].choice === 0
    const vegan = survey.barthes.data[7].choice === 0
    const boeuf = survey.barthes.data[6].choice === 0
    if (vegan && !boeuf) {
      if (snow && !foot) audioList.push("audio16")
      else if (foot && !snow) audioList.push("audio19")
      else audioList.push("audio15")
    } else if (boeuf && !vegan) {
      if (snow && !foot) audioList.push("audio20")
      else if (foot && !snow) audioList.push("audio17")
      else audioList.push("audio23")
    } else {
      if (snow && !foot) audioList.push("audio21")
      else if (!foot && !snow) audioList.push("audio22")
      else audioList.push("audio18")
    }
    // third scoring part 'telephone'
    const femme = survey.barthes.data[0].choice === 0
    const pere = survey.barthes.data[1].choice === 0
    const fred = survey.barthes.data[2].choice === 0
    if (femme && !pere && !fred) audioList.push("audio25")
    else if (!femme && !pere && fred) audioList.push("audio24")
    else if (femme && !pere && fred) {
      if (scorePercent < 50) audioList.push("audio24")
      else audioList.push("audio25")
    } else {
      if (scorePercent < 60) audioList.push("audio26")
      else audioList.push("audio27")
    }
    onAction({
      type: "game",
      location: "SubmitSurvey",
      result: {
        success: {
          right: scoreOk,
          wrong: scoreNok,
          dunno: scoreNSP,
          bonus: scorePsy,
          percent: scorePercent,
          audio: audioList,
        },
      },
    })
  }

  const onSurveyViewClose = () => {
    onAction({
      type: "game",
      location: "SurveyView",
      result: "close",
    })
  }

  return (
    <>
      <DesktopWindow
        content={{ title: "Intervention d'Urgence" }}
        onClose={onSurveyViewClose}
        style={{
          top: "0.5em",
          left: "12em",
          width: "50vw",
          height: "95vh",
        }}
      >
        <div>
          <SurveyForm
            survey={scenario.survey}
            onAction={onAction}
            updateSurvey={params.updateSurvey}
            handleSubmit={handleSubmit}
            submitted={submitted}
            readOnly={params.team === "delta"}
          />
        </div>
      </DesktopWindow>
    </>
  )
}

export { SurveyView }
