import React, { useState, useEffect, useRef } from "react"
import { Image, Col, Row, Form, OverlayTrigger, Tooltip } from "react-bootstrap"

import DesktopWindow from "../DesktopWindow"
import { getDateStringMinusDay } from "../db/DbUtils"
import { throttle } from "../../utils"

const MixageAudioDelta = (props) => {
  const [play, setPlay] = useState(false)
  const [audio1, setAudio1] = useState(null)
  //const [audio2, setAudio2] = useState(null)
  const [audio3, setAudio3] = useState(null)

  const audioDur = useRef(null)
  const audioSeekBar = useRef(null)

  // initialize / preload audio files
  useEffect(() => {
    const updateSeekBar = () => {
      if (audioSeekBar.current) {
        const rect = audioSeekBar.current.parentNode.getBoundingClientRect()
        const size = rect.right - rect.left
        audioSeekBar.current.style.left =
          (audioObj1.currentTime / audioDur.current) * size + "px"
      }
    }
    var audioObj1 = new Audio("audio/CLEAN finalisé.mp3")
    //var audioObj2 = new Audio("audio/POLLUTION AUDIO MOTEUR.mp3")
    var audioObj3 = new Audio("audio/POLLUTION AUDIO VENT.mp3")
    const cpt1 = audioObj1.addEventListener("canplaythrough", () => {
      /* the audio is now playable; play it if permissions allow */
      //audioObj.play()
      setAudio1(audioObj1)
      audioDur.current = audioObj1.duration
    })
    /*
    const cpt2 = audioObj2.addEventListener("canplaythrough", () => {
      setAudio2(audioObj2)
      //console.log("Audio2 duration: ", audioObj2.duration)
      audioDur.current = audioObj2.duration
    })
    */
    const cpt3 = audioObj3.addEventListener("canplaythrough", () => {
      setAudio3(audioObj3)
      audioDur.current = audioObj3.duration
    })
    const updateSeek = audioObj1.addEventListener("timeupdate", updateSeekBar)
    return () => {
      if (audioObj1) audioObj1.pause()
      //if (audioObj2) audioObj2.pause()
      if (audioObj3) audioObj3.pause()
      audioObj1.removeEventListener("canplaythrough", cpt1)
      //audioObj2.removeEventListener("canplaythrough", cpt2)
      audioObj3.removeEventListener("canplaythrough", cpt3)
      audioObj1.removeEventListener("timeupdate", updateSeek)
    }
  }, [])

  useEffect(() => {
    if ([audio1, /*audio2,*/ audio3].every((e) => e)) {
      if (play) {
        audio1.play()
        //audio2.play()
        if (!props.params.cleared) audio3.play()
      } else if (audio1) {
        audio1.pause()
        //audio2.pause()
        if (!props.params.cleared) audio3.pause()
      }
    }
  }, [play, audio1, /*audio2,*/ audio3, props.params.cleared])

  useEffect(() => {
    if (audio3) audio3.pause()
  }, [audio3, props.params.cleared])

  useEffect(() => {
    if ("mixer1" in props.params && audio1) {
      audio1.volume = parseInt(props.params.mixer1) / 100.0
    }
    /*
    if ("mixer2" in props.params && audio2) {
      audio2.volume = parseInt(props.params.mixer2) / 100.0
    }
    */
    if ("mixer3" in props.params && audio3) {
      audio3.volume = parseInt(props.params.mixer3) / 100.0
    }
  }, [props.params, audio1, /*audio2,*/ audio3])

  const onAssist = (event) => {
    props.onAction({
      type: "game",
      location: "MorenoAudioAssist",
    })
    const oldClassName = event.target.className
    event.target.className += " blink-me-fast"
    setTimeout(() => (event.target.className = oldClassName), 3000)
  }
  const onPlay = () => {
    setPlay(!play)
  }
  const onSeekPlay = (event) => {
    const rect = event.target.getBoundingClientRect()
    const size = rect.right - rect.left
    const posX = event.clientX - rect.left
    const seek = posX / size
    if (audio1 && /*audio2 &&*/ audio3) {
      audio1.currentTime = audioDur.current * seek
      //audio2.currentTime = audioDur.current * seek
      audio3.currentTime = audioDur.current * seek
    }
  }

  const onWindowClose = () => {
    props.onAction({
      type: "game",
      location: "MorenoAudioClose",
      result: "close",
    })
  }

  const content_win = {
    title: "Ecoute téléphonique du " + getDateStringMinusDay(6),
    style: {
      left: "10em",
      //width: "32em",
      zIndex: 500,
    },
  }

  return (
    <DesktopWindow content={content_win} onClose={onWindowClose}>
      <div className="audio-mix-delta">
        <div className="audio-mix-delta-actions">
          {props.params.team === "delta" && (
            <OverlayTrigger overlay={<Tooltip>Demande d'assistance</Tooltip>}>
              <Image
                className="audio-mix-delta-assist"
                src="pictos/demande d'assistance.png"
                onClick={onAssist}
              ></Image>
            </OverlayTrigger>
          )}
          <Image
            className="audio-mix-delta-play"
            src="pictos/play pause.png"
            onClick={onPlay}
          ></Image>
        </div>
        <div className="audio-mix-delta-diag">
          <Image src="backgrounds/diagramme sonore.jpg" onClick={onSeekPlay} />
          <div
            ref={audioSeekBar}
            className="audio-mix-seek-bar"
            style={{ padding: "5px" }}
          ></div>
        </div>
      </div>
    </DesktopWindow>
  )
}

const MixageAudioAlpha = (props) => {
  const initFromProps = (scenario) => {
    let result = false
    if (scenario) result = scenario.desktop.alpha.folders.AUDIO_MORENO.cleared
    return result
  }

  const [audioCleared, setAudioCleared] = useState(
    initFromProps(props.scenario)
  )
  //const mixer1 = useRef(null)
  const mixer3 = useRef(null)

  const audioClearTimeout = useRef(null)

  const onMixerChange = throttle((event) => {
    if (audioClearTimeout.current) {
      clearTimeout(audioClearTimeout.current)
    }
    const name = event.target.name
    let value = parseInt(event.target.value)
    if (name === "mixer1") {
      value = (value + 50) % 100
    } else if (name === "mixer2") {
      value = (value + 80) % 100
    } else if (name === "mixer3") {
      value = (value + 30) % 100
    }
    props.onAction({
      type: "game",
      location: "UpdateAudioMix",
      result: { [name]: value },
    })
    if (mixer3.current.value >= 70 && mixer3.current.value < 80)
      audioClearTimeout.current = setTimeout(() => {
        setAudioCleared(true)
        props.onAction({
          type: "game",
          location: "UpdateAudioMix",
          result: { cleared: "cleared" },
        })
      }, 1500)
  }, 200)

  return (
    <div className="audio-mix-alpha">
      <Form className="forms">
        {/**
        <Form.Group as={Row}>
          <Form.Label column sm="4" htmlFor="mixer1">
            Mixer 1
          </Form.Label>
          <Col sm="8">
            <Form.Control
              ref={mixer1}
              id="mixer1"
              name="mixer1"
              type="range"
              onChange={onMixerChange}
              disabled={audioCleared}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="4" htmlFor="mixer2">
            Mixer 2
          </Form.Label>
          <Col sm="8">
            <Form.Control
              id="mixer2"
              name="mixer2"
              type="range"
              onChange={onMixerChange}
            />
          </Col>
        </Form.Group>
          */}
        <Form.Group as={Row}>
          <Form.Label column sm="4" htmlFor="mixer3">
            Mixer 1
          </Form.Label>
          <Col sm="8">
            <Form.Control
              ref={mixer3}
              id="mixer3"
              name="mixer3"
              type="range"
              onChange={onMixerChange}
              disabled={audioCleared}
            />
          </Col>
        </Form.Group>
        {audioCleared ? (
          <Form.Group as={Row} className="d-flex justify-content-center">
            <Form.Text style={{ fontSize: "140%", color: "green" }}>
              Piste audio nettoyée
            </Form.Text>
          </Form.Group>
        ) : (
          ""
        )}
      </Form>
    </div>
  )
}

export { MixageAudioDelta, MixageAudioAlpha }
