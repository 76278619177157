import React, { useState, useEffect } from "react"

import { Modal, Button } from "react-bootstrap"

import DesktopWindow from "../DesktopWindow"
import { ForwardDesktopIcon } from "../DesktopIcon"

let baseImage = new Image()
baseImage.src =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAATCAYAAACk9eypAAAAAXNSR0IArs4c6QAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAhGVYSWZNTQAqAAAACAAFARIAAwAAAAEAAQAAARoABQAAAAEAAABKARsABQAAAAEAAABSASgAAwAAAAEAAgAAh2kABAAAAAEAAABaAAAAAAAAAEgAAAABAAAASAAAAAEAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAAEwAAAAAChpcNAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAABqElEQVQoFY3SPUvDQBgH8BREpRHExYiDgmLFl6WC+AYmWeyLg4i7buJX8DMpOujgyxGvUYeCgzhUQUSKKLUS0+ZyptXh8Z5Ti621ekPyJHl+uftfomhaf9Ei5JyxXKfynyEA6EYcLHpwyflT958GAQ7DTABNHd8EbtDbEH2BD5QEQmi2mM8P/Iq+A0SzszEg+3sPjDnDdVEtQKQbMUidHD3xVzf6A9UDEmEm+8h9KTqTVUjT+vB53aHrCbAPiceYq1dQI1Aqv4EhMll0jzv+Y0yiRgCnLRSYyDQHVoqUXe4uKL9l+L7GXC4vkMhE6eW/AOJs9k583ORDUyXMZ8F5SVHVVnllmPNKSFagAJ5DofaqGXw/gHBYg51dIldkmknY3tguv3jOtHR4+MqAzaraJXbEhqHhcQlwGSOi5pytVQHZLN5s0WNe8HPrLYlFsO20RPHkImxsbmHdLJFI76th7Z4SeuF53hTeFLvhRCJRCTKZKxgdnRDbW+iozFJbBMw14/ElwGYc0egMBMFzT21f5Rog33Z7dX02GBm7WV5ZfT5Nn5bE3zuCDe9UxdTpNvK+5AAAAABJRU5ErkJggg=="

const RemoteCtrlPopup = () => (
  <div className="remote-control-popup blink-me-fast">
    PRISE DE CONTROLE A DISTANCE
  </div>
)

const ConfirmModal = ({ onConfirm }) => {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 })
  const [remoteCtrl, setRemoteCtrl] = useState(false)

  useEffect(() => {
    const onMouseMove = (e) => setMousePos({ x: e.clientX, y: e.clientY })
    window.addEventListener("mousemove", onMouseMove)
    return () => {
      window.removeEventListener("mousemove", onMouseMove)
    }
  })

  // Fake remote contre process...
  const hideCursor = () => {
    document.body.style.cursor = "none"
    document.documentElement.style.cursor = "none"
    let modal = document.getElementsByClassName("confirm-modal")[0]
    for (let elt of modal.getElementsByClassName("btn"))
      elt.style.cursor = "none"
    modal.style.cursor = "none"
  }

  const showCursor = () => {
    document.body.style.cursor = "default"
    document.documentElement.style.cursor = "default"
  }

  var canvas
  var context
  var width = window.innerWidth
  var height = window.innerHeight
  var cursor = { x: 0, y: 0 }
  var target = { x: 0, y: 0 }
  const init = (target_x, target_y) => {
    cursor = { x: mousePos.x, y: mousePos.y }
    target = { x: target_x, y: target_y }
    canvas = document.createElement("canvas")
    context = canvas.getContext("2d")
    canvas.style.top = "0px"
    canvas.style.left = "0px"
    canvas.style.pointerEvents = "none"
    canvas.style.position = "fixed"
    canvas.style.zIndex = 55
    document.body.appendChild(canvas)
    canvas.width = width
    canvas.height = height
    animate()
  }

  const animate = () => {
    context.clearRect(0, 0, width, height)
    if (cursor.x < target.x) cursor.x += 1
    else if (cursor.x > target.x) cursor.x -= 1
    if (cursor.y < target.y) cursor.y += 1
    else if (cursor.y > target.y) cursor.y -= 1
    context.drawImage(baseImage, cursor.x, cursor.y)
    if (cursor.x !== target.x || cursor.y !== target.y) {
      requestAnimationFrame(animate)
    } else {
      var d = document.createElement("div")
      d.className = "clickEffect"
      d.style.left = target.x + "px"
      d.style.top = target.y + "px"
      document.body.appendChild(d)
      window.setTimeout(() => endAnimate(d), 500)
    }
  }

  const endAnimate = (elt) => {
    document.body.removeChild(elt)
    document.body.removeChild(canvas)
    showCursor()
    setRemoteCtrl(false)
    onConfirm(true)
  }

  const onCancel = () => {
    setRemoteCtrl(true)
    // hide user cursor
    hideCursor()
    // get the target component for remote control action
    const modal = document.getElementsByClassName("confirm-modal")[0]
    const btn = modal.getElementsByClassName("btn-primary")[0]
    const bbox = btn.getBoundingClientRect()
    const target_x = bbox.x + bbox.width / 2
    const target_y = bbox.y + bbox.height / 2
    // initialize and run remote action animation
    window.setTimeout(
      () => init(Math.round(target_x), Math.round(target_y)),
      1500
    )
  }

  return (
    <>
      {remoteCtrl ? <RemoteCtrlPopup /> : ""}
      <Modal.Dialog className="confirm-modal">
        <Modal.Header>
          <Modal.Title>3W15HR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" onClick={onCancel}>
            OUVRIR
          </Button>
          <Button variant="primary" onClick={() => onConfirm(false)}>
            SUPPRIMER
          </Button>
        </Modal.Body>
      </Modal.Dialog>
    </>
  )
}

const CustomToggle = React.forwardRef(({ content, onClick }, ref) => (
  <ForwardDesktopIcon
    {...content}
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  />
))

const PictoToDelete = ({ onAction, scenario }) => {
  const [confirm, setConfirm] = useState(null)

  const onConfirmDelete = (with_help) => {
    setConfirm(null)
    // animate the deletion of the picto
    const picto = document.getElementById("D5_to_delete")
    //.getElementsByTagName("img")[0]
    picto.className = picto.className + " scale-out-center"
    // then send the action to display the next screen
    const res = with_help ? "success_with_help" : "success"
    setTimeout(
      () =>
        onAction({
          type: "game",
          location: "DeletePicto",
          result: res,
        }),
      1200
    )
  }

  const onDelete = () => {
    setConfirm(true)
  }

  const onWindowClose = () => {
    if (confirm) return
    onAction({
      type: "game",
      location: "DeletePicto",
      result: "close",
    })
  }

  const content = scenario.desktop.delta.custom.D5_delete
  const content_win = { title: content.title }

  return (
    <>
      {confirm ? <ConfirmModal onConfirm={onConfirmDelete} /> : ""}
      <DesktopWindow
        content={content_win}
        onClose={onWindowClose}
        style={{ top: "8em", left: "22em", width: "10em" }}
      >
        <CustomToggle content={content.icons[0]} onClick={onDelete} />
        {/**
        <Dropdown>
          <Dropdown.Toggle
            content={content.icons[0]}
            as={CustomToggle}
          ></Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={onDelete}>Supprimer</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
         */}
      </DesktopWindow>
    </>
  )
}

export default PictoToDelete
