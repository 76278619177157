import { getStringNorm, getDateStringMinusDay } from "./DbUtils"

const searchDb = (first, last) => {
  for (const person of PeopleDb) {
    if (
      getStringNorm(first) === getStringNorm(person.firstname) &&
      (getStringNorm(last) === getStringNorm(person.lastname) ||
        (person.maidenname &&
          person.maidenname !== "NC" &&
          getStringNorm(last) === getStringNorm(person.maidenname)))
    ) {
      return person
    }
  }
  return null
}

const getPersonFromId = (id) => PeopleDb.find((person) => person.id === id)

const PeopleDb = [
  {
    id: 1,
    photo: "dbpic/francois_pillu.png",
    reference: "NC",
    creation_date: new Date(2021, 0, 16).toLocaleDateString(),
    created_by: "Agent 4113",
    firstname: "François",
    lastname: "PILLU",
    maidenname: "NC",
    birthday: new Date(1965, 9, 9).toLocaleDateString(),
    address: "NC",
    addressUrl: "",
    sexe: "masculin",
    hair: "châtains",
    eyes: "verts",
    death: getDateStringMinusDay(5),
    death_place: "rue Montalivet, 75008 Paris.",
    death_cause: "Assassiné par un inconnu.",
    distinctive_signs: "Néant",
    suspect: "Victime",
    facts:
      "François Pillu travaillait à l’I.G.P.N. Il a été assassiné alors qu’il sortait de son lieu de travail et se dirigeait vers sa voiture.  Nos services ont conclu à une tentative de vol qui aurait mal tourné. Le meurtrier n’a jamais été identifié.\n\nPièces à convictions / indices  :\n\nUne pochette qui contenait certainement des dossiers a été retrouvée vidée de son contenu sur le siège avant passager portant une mention manuscrite : “Affaire des écoutes”.\nSeules empreintes retrouvées : celles de la victime.\n\nUn papier volant avec deux QR codes a été retrouvé sous le siège passager avant.\nSeules empreintes retrouvées : celles de la victime.",
    links: [2, 3],
  },
  {
    id: 2,
    photo: "dbpic/cecilia_peres.png",
    reference: "NC",
    creation_date: "NC",
    created_by: "Agent",
    firstname: "Cécilia",
    lastname: "PERES",
    maidenname: "PILLU",
    birthday: new Date(1985, 3, 1).toLocaleDateString(),
    address: "NC",
    addressUrl: "",
    sexe: "féminin",
    hair: "blonds",
    eyes: "marron",
    death: "NC",
    death_place: "NC",
    death_cause: "NC",
    distinctive_signs: "Néant",
    suspect: "Témoin",
    facts:
      "L’agent PERES est responsable du service informatique de la B.R.I. à la préfecture de Police. Elle a été entendue au sujet de l’assassinat de son mari le " +
      getDateStringMinusDay(5) +
      ". Elle travaillait dans les bureaux de la brigade au moment des faits. Elle ne connait personne susceptible de vouloir le moindre mal à son époux, il était plutôt apprécié par son entourage professionnel et  personnel.",
    links: [1],
  },
  {
    id: 3,
    photo: "dbpic/julien_duabour.png",
    reference: "NC",
    creation_date: getDateStringMinusDay(4),
    created_by: "Agent 4113",
    firstname: "Julien",
    lastname: "DUABOUR",
    maidenname: "NC",
    birthday: new Date(1975, 0, 8).toLocaleDateString(),
    address: "7 rue Cadet, 75009 Paris",
    addressUrl:
      "https://www.google.fr/maps/place/7+Rue+Cadet,+75009+Paris/@48.8747832,2.3405258,768m/data=!3m2!1e3!4b1!4m5!3m4!1s0x47e66e3f0e93c5bd:0x508d1bb35221a784!8m2!3d48.8747797!4d2.3427145",
    sexe: "masculin",
    hair: "châtains",
    eyes: "bleus",
    death: "NC",
    death_place: "NC",
    death_cause: "NC",
    distinctive_signs: "Néant",
    suspect: "Témoin",
    facts:
      "Témoin du meurtre de François Pillu\nExtrait de témoignage du " +
      getDateStringMinusDay(4) +
      "\n  “Le " +
      getDateStringMinusDay(5) +
      " vers 16H00, je me rendais chez une copine qui habite rue Montalivet. J’ai vu de loin une personne qui se tenait debout devant la portière ouverte d’une voiture et qui semblait chercher quelque chose. La rue était déserte et quand l’individu m’a vu arriver, il s’est mis à détaler vers la rue d’Aguesseau. J’ai pensé qu’il s’agissait d’un gars qui voulait tirer la voiture, mais j’ai vu à la place du conducteur le corps poignardé d’un homme. J’ai tout de suite appelé la police.\n Pour être franc, j’ai à peine vu ou fait attention au gars, je crois qu’il portait une casquette rouge, mais je serais bien incapable de l’identifier.”",
    links: [1],
  },
  {
    id: 4,
    photo: "dbpic/floriane_moreno.png",
    reference: "NC",
    creation_date: getDateStringMinusDay(6),
    created_by: "Agent 28210",
    firstname: "Floriane",
    lastname: "MORÉNO",
    maidenname: "NC",
    birthday: new Date(2004, 2, 11).toLocaleDateString(),
    address: "13 Rue Aristide Briand, 94340 Joinville-le-Pont",
    addressUrl:
      "https://www.google.fr/maps/place/13+Rue+Aristide+Briand,+94340+Joinville-le-Pont/@48.8210887,2.4647689,544m/data=!3m1!1e3!4m5!3m4!1s0x47e60d2f81b92057:0xb43e82306495be06!8m2!3d48.8211142!4d2.4649766",
    sexe: "féminin",
    hair: "châtains",
    eyes: "marron",
    death: "NC",
    death_place: "NC",
    death_cause: "NC",
    distinctive_signs: "Néant",
    suspect: "Victime",
    facts:
      "Madame Gaelle Moréno a appelé les services de police le " +
      getDateStringMinusDay(6) +
      " pour nous signaler la disparition de sa fille mineure. Nous l’avons convoquée au bureau mais, 2 heures plus tard, elle nous a appelés pour indiquer qu’il s’agissait d’une erreur et qu’elle venait de retrouver un message de sa fille indiquant qu’elle partait 2 jours chez une amie. \nDocument reçus à analyser :  [FICHIER AUDIO 3W15HR].\nDOSSIER CLOS.",
    links: [5],
  },
  {
    id: 5,
    photo: "dbpic/gaelle_moreno.png",
    reference: "NC",
    creation_date: getDateStringMinusDay(6),
    created_by: "Agent 28210",
    firstname: "Gaëlle",
    lastname: "MORÉNO",
    maidenname: "NC",
    birthday: new Date(1972, 1, 4).toLocaleDateString(),
    address: "13 Rue Aristide Briand, 94340 Joinville-le-Pont",
    addressUrl:
      "https://www.google.fr/maps/place/13+Rue+Aristide+Briand,+94340+Joinville-le-Pont/@48.8210887,2.4647689,544m/data=!3m1!1e3!4m5!3m4!1s0x47e60d2f81b92057:0xb43e82306495be06!8m2!3d48.8211142!4d2.4649766",
    sexe: "féminin",
    hair: "blonds",
    eyes: "bleus",
    death: "NC",
    death_place: "NC",
    death_cause: "NC",
    distinctive_signs: "Néant",
    suspect: "Témoin",
    facts:
      getDateStringMinusDay(6) +
      ": Madame Moréno, députée dans la 15e circonscription de Paris a appelé la police pour signaler la disparition de sa fille, mais il s’agissait d’une erreur (Cf Floriane Moréno).\nEn raison du statut d'élue de Madame Moréno, un dossier a été ouvert [MORÉNO]",
    links: [4],
  },
  {
    id: 6,
    photo: "dbpic/alberto_robirez.png",
    reference: "NC",
    creation_date: new Date(1998, 5, 12).toLocaleDateString(),
    created_by: "Agent 3654",
    firstname: "Alberto",
    lastname: "ROBIREZ",
    maidenname: "NC",
    birthday: new Date(1950, 5, 1).toLocaleDateString(),
    address: "NC",
    addressUrl: "",
    sexe: "masculin",
    hair: "gris",
    eyes: "marron",
    death: "NC",
    death_place: "NC",
    death_cause: "NC",
    distinctive_signs: "Néant",
    suspect: "Témoin",
    facts:
      "Commandant de brigade divisionnaire de Paris.\nIl a été entendu le 2 juillet 2015 au sujet du meurtre du professeur Higuelin.\nIl a indiqué que son fils Léonardo Robirez, soupçonné de l'homicide, était bien présent avec lui au moment où se sont déroulés les faits. Ils regardaient un match de foot à la télévision.",
    links: [7, 8, 9],
  },
  {
    id: 7,
    photo: "dbpic/magali_robirez.png",
    reference: "NC",
    creation_date: new Date(1998, 5, 12).toLocaleDateString(),
    created_by: "Agent xxxx",
    firstname: "Magali",
    lastname: "ROBIREZ",
    maidenname: "NC",
    birthday: new Date(1960, 7, 14).toLocaleDateString(),
    address: "NC",
    addressUrl: "",
    sexe: "féminin",
    hair: "châtains",
    eyes: "marron",
    death: new Date(1998, 6, 11).toLocaleDateString(),
    death_place: "Hôpital Paris Saint-Joseph",
    death_cause: "blessure par balle",
    distinctive_signs: "Néant",
    suspect: "Victime",
    facts:
      "13/06/1998: Magali Robirez, lieutenant de police, a été grièvement blessée à la sortie de son domicile. L'assassin a été arrêté. Elle est décédée à l’hôpital Paris Saint-Joseph de la suite de ses blessures.",
    links: [6, 8],
  },
  {
    id: 8,
    photo: "dbpic/leonardo_robirez.png",
    reference: "NC",
    creation_date: getDateStringMinusDay(6),
    created_by: "Agent 55610",
    firstname: "Léonardo",
    lastname: "ROBIREZ",
    maidenname: "NC",
    birthday: new Date(1984, 8, 12).toLocaleDateString(),
    address: "2Bis impasse de Condé, 94100 Saint-Maur-Des-Fossés",
    addressUrl:
      "https://www.google.fr/maps/place/2+Impasse+de+Cond%C3%A9,+94100+Saint-Maur-des-Foss%C3%A9s/@48.8131031,2.476816,457m/data=!3m1!1e3!4m5!3m4!1s0x47e60ce029d67e03:0xa7607effebd348e6!8m2!3d48.8131239!4d2.4782",
    sexe: "masculin",
    hair: "châtains",
    eyes: "marron",
    death: "NC",
    death_place: "NC",
    death_cause: "NC",
    distinctive_signs: "Néant",
    suspect: "Suspect",
    facts:
      "[2013] Opération Serval (Unité Commando)\n[2015-2018] Brigade de Recherche et d’Intervention (B.R.I.)\n[2019] à maintenant : Responsable de l’unité de Protection rapprochée du Ministre de l'Intérieur.\n\nDistinctions :\nMédaille d’argent de la Défense nationale.\nMédaille militaire.\n\nCasier judiciaire : VIERGE\n[2014] Suspecté et innocenté de l'accusation de coups et blessures.\n[2015] Suspecté et innocenté de l'accusation d’homicide.\n[2019] Plainte enregistrée pour diffamation sur son profil Facebook “Bobby Deap”. La plainte a été retirée par le plaignant.\n\nLéonardo Robirez a été nommé Responsable de l’unité de Protection rapprochée du Ministre de l'Intérieur. (GSMI), le 15 septembre 2019.\n\nService de la protection (SDLP)",
    links: [6, 7],
  },
  {
    id: 9,
    photo: "dbpic/professeur_higuelin.png",
    reference: "NC",
    creation_date: new Date(2015, 5, 12).toLocaleDateString(),
    created_by: "Agent",
    firstname: "Alain",
    lastname: "HIGUELIN",
    maidenname: "NC",
    birthday: new Date(1945, 10, 11).toLocaleDateString(),
    address: "10 Avenue Pozzo Di Borgo, 92210 Saint-Cloud",
    addressUrl:
      "https://www.google.fr/maps/place/10+Avenue+Pozzo+Di+Borgo,+92210+Saint-Cloud/@48.8444735,2.2135549,769m/data=!3m2!1e3!4b1!4m5!3m4!1s0x47e67b3bed21c6fd:0xb24062a060006ace!8m2!3d48.84447!4d2.2157436",
    sexe: "masculin",
    hair: "gris",
    eyes: "marron",
    death: new Date(2015, 5, 12).toLocaleDateString(),
    death_place: "10 Avenue Pozzo Di Borgo, 92210 Saint-Cloud",
    death_cause: "Coups et blessures",
    distinctive_signs: "Néant",
    suspect: "Victime",
    facts:
      "Le professeur sortait de son domicile le 13/06/2015 pour sortir sa poubelle, lorsqu’une personne en tenue complète de motard (casque noir teinté, et combinaison kevlar noire) est arrivée en moto et s’est ruée sur lui. Il a reçu de nombreux coups d’une violence extrême, est tombé en arrière et sa tête a frappé violemment contre le sol. L’assaillant est reparti en courant vers sa moto et a quitté les lieux. C'est un voisin qui a assisté à la chute du professeur et à la fuite du motard et qui a pu témoigner. Mais il n’a pas été en mesure de donner plus de détails, à part la couleur de la moto : bleue.\n\nLe professeur était connu pour être très autoritaire et pas toujours apprécié de ses élèves au collège Stanislas où il enseignait depuis quinze ans, mais aucun ennemi ne lui était connu.",
    links: [10],
  },
  {
    id: 10,
    photo: "dbpic/frederic_taulle.png",
    reference: "NC",
    creation_date: new Date(2015, 5, 14).toLocaleDateString(),
    created_by: "Agent",
    firstname: "Frédéric",
    lastname: "TAULLÉ",
    maidenname: "NC",
    birthday: new Date(1984, 2, 4).toLocaleDateString(),
    address: "NC",
    addressUrl: "",
    sexe: "masculin",
    hair: "châtains",
    eyes: "verts",
    death: "NC",
    death_place: "NC",
    death_cause: "NC",
    distinctive_signs: "Néant",
    suspect: "Témoin",
    facts:
      new Date(2015, 5, 14).toLocaleDateString() +
      " : Frédéric Taullé s’est présenté de lui-même au commissariat et nous a indiqué qu’il connaissait le professeur Higuelin qui venait d’être assassiné. Il a été son professeur de Mathématiques au collège Stanislas. M. Taullé a révélé que peu de temps auparavant un ancien camarade de classe, Léonardo Robirez, était venu le voir pour le questionner sur ce professeur. Il lui a posé des questions assez précises sur son lieu d’habitation et sa situation familiale. Frédéric Taullé qui n’a plus de contact depuis sa scolarité avec le professeur, n'a pas été capable de répondre à ses questions. M. Léonardo Robirez a été entendu et innocenté.\n\n13/06/2019 : Frédéric Taullé a porté plainte pour diffamation contre Léonardo Robirez.\nCe dernier aurait hacké l’ordinateur de Frédéric Taullé et diffusé sur sa propre page Facebook personnelle BOBBY DEAP toute une série de photos très personnelles appartenant à Frédéric Taullé. Sur certaines d’entre elles, Frédéric Taullé pose en costume d’officier nazi. Il nous a expliqué que ces photos avaient été faites dans le cadre d’un jeu de type Grandeur Nature, une sorte de manifestation théâtrale. Sorties de leur contexte, ces photos présentent une véritable nuisance.\n\nLa plainte est retirée par Frédéric Taullé le 17/06/2019.",
    links: [8, 9],
  },
  {
    id: 11,
    photo: "dbpic/jean_butor.png",
    reference: "NC",
    creation_date: new Date(2014, 5, 12).toLocaleDateString(),
    created_by: "Agent 2158",
    firstname: "Jean",
    lastname: "BUTOR",
    maidenname: "NC",
    birthday: new Date(1990, 1, 9).toLocaleDateString(),
    address: "NC",
    addressUrl: "",
    sexe: "masculin",
    hair: "blonds",
    eyes: "bleus",
    death: "NC",
    death_place: "NC",
    death_cause: "NC",
    distinctive_signs: "Néant",
    suspect: "Victime",
    facts:
      "Jean Butor est venu porter plainte le 13 juin 2014 : alors qu’il sortait de son club de sport et se dirigeait vers sa moto, un homme s’est jeté sur lui, et l’a violemment frappé sans donner la moindre explication. La victime a reconnu Léonardo Robirez, l’ex petit-ami de sa conjointe actuelle.\n\nLe 16 juin 2014, Jean Butor a retiré sa plainte.",
    links: [8],
  },
]

export default PeopleDb
export { searchDb, getPersonFromId }
