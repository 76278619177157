import React, { useState, useEffect } from "react"
import TypeWriterEffect from "react-typewriter-effect"

import HackerTip from "./HackerTip"
import DesktopWindow from "../DesktopWindow"
import { throttle } from "../../utils"

const text =
  "Good Job ! Grâce à vous, mon patron n’aura plus de soucis à se faire, et il va pouvoir continuer à se faire plein de fric ! Dernière étape : supprimez toutes traces de votre passage sur ces ordinateurs, en fermant les fenêtres de “publicité” sur les différents bureaux virtuels. Ne fermez pas vos navigateurs tant que vous ne l’avez pas fait hein ?!! Retrouvez bientôt la saison 2 de l’escape Game Hacking, avec de nouvelles missions !!!"

const text_with_help =
  "Good Job ! Bon, on vous a un peu aidés à la fin, vous aviez l'air d'avoir du mal à le supprimer, mais GOOD JOB ! Grâce à vous, mon patron n’aura plus de soucis à se faire, et il va pouvoir continuer à se faire plein de fric ! Dernière étape : supprimez toutes traces de votre passage sur ces ordinateurs, en fermant les fenêtres de “publicité” sur les différents bureaux virtuels. Ne fermez pas vos navigateurs tant que vous ne l’avez pas fait hein ?!! Retrouvez bientôt la saison 2 de l’escape Game Hacking, avec de nouvelles missions !!!"

const MSDosWindow = ({ onAction, params }) => {
  const [picSize, setPicSize] = useState([0, 0])

  // Effect for ALPHA team to update clip-position on hidden text
  useEffect(() => {
    if (params.team === "alpha" && "x" in params) {
      const x = params.x * picSize[0]
      const y = params.y * picSize[1]
      document.documentElement.style.setProperty("--clip-pos", `${x}px ${y}px`)
    }
  }, [picSize, params, params.team, params.x, params.y])

  // Effect for DELTA team registering mouse move event
  useEffect(() => {
    if (params.team === "delta") {
      const ad_img = document
        .getElementById("advert")
        .getElementsByClassName("content")[0]
      const onMove = throttle((event) => {
        const x = event.offsetX / picSize[0]
        const y = event.offsetY / picSize[1]
        onAction({
          type: "game",
          location: "Tsunami",
          result: { event: "mousemove", x: x, y: y },
        })
      }, 200)
      ad_img.addEventListener("mousemove", onMove)
      return () => {
        ad_img.removeEventListener("mousemove", onMove)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picSize, params])

  // Effect for window resize event, at component init time
  useEffect(() => {
    const getImgSize = () => {
      const ad_img = document
        .getElementById("advert")
        .getElementsByClassName("content")[0]
      return [ad_img.clientWidth, ad_img.clientHeight]
    }
    const onResize = () => {
      const size = getImgSize()
      setPicSize(size)
    }
    // init
    onResize()
    // register event listener
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  const onTsunami = () => {
    onAction({
      type: "game",
      location: "Tsunami",
      result: "success",
    })
  }

  return (
    <>
      <DesktopWindow
        className="msdos"
        style={{
          width: "21em",
          top: "2em",
          left: "28em",
        }}
      >
        <TypeWriterEffect
          startDelay={100}
          cursorColor="white"
          text={params.help === "with_help" ? text_with_help : text}
          typeSpeed={80}
        />
      </DesktopWindow>
      <DesktopWindow
        className="advert"
        id="advert"
        content={{
          title: "Regen Ethics",
          //background: "backgrounds/projet hacker bientot.png",
        }}
        style={{
          width: "26em",
          height: "22.5em",
          top: "1em",
          left: "1em",
        }}
      >
        <HackerTip
          onTsunami={onTsunami}
          noText={params.team === "delta"}
          hidedText={true}
        />
      </DesktopWindow>
    </>
  )
}

export default MSDosWindow
