import React from "react"

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

const InnerFrame = (props) => (
  <div style={{ height: "100%" }}>
    {props.url.toLowerCase().endsWith("jpg") ||
    props.url.toLowerCase().endsWith("png") ? (
      <ImageViewer {...props} />
    ) : (
      <iframe className="inner_frame" src={props.url} title={props.title} />
    )}
  </div>
)

const ImageViewer = (props) => (
  <TransformWrapper>
    <TransformComponent>
      <img className="inner_frame" src={props.url} alt={props.title} />
    </TransformComponent>
  </TransformWrapper>
)

export default InnerFrame
