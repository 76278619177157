import React, { useState, useEffect } from "react"
import { Container, Modal, Image, Row, Col } from "react-bootstrap"
import PatternLock from "react-pattern-lock"

import { pad0 } from "../../utils"

const LockeeFrame = (props) => {
  const [timeLeft, setTimeLeft] = useState(480) //480 = 8mns
  const [trackedIP, setTrackedIP] = useState(false)
  const [path, setPath] = useState([])

  const playSound = () => {
    var audioObj = new Audio("audio/Alerte intrusion ordi.wav")
    audioObj.addEventListener("canplaythrough", () => {
      audioObj.volume = 0.2
      audioObj.play()
    })
  }

  // fx for sound fx when component is mounted
  useEffect(() => {
    playSound()
  }, [])

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)
      // Clear timeout if the component is unmounted
      return () => {
        clearTimeout(timer)
      }
    } else {
      playSound()
      setTrackedIP(true)
      props.onAction({
        type: "game",
        location: "Lockee",
        result: "timeout",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft])

  const checkPath = () => {
    const sPath = path.join("")
    if (sPath === "24678") {
      if ("onAction" in props) {
        props.onAction({
          type: "game",
          location: "Lockee",
          result: "success",
        })
      }
    } else {
      if ("onAction" in props) {
        props.onAction({
          type: "game",
          location: "Lockee",
          result: "error",
        })
      }
      setPath([])
    }
  }

  return (
    <Modal.Dialog size="lg" className="lockee-modal">
      <Modal.Body className="border-blinking">
        <Container className="content">
          <Row>
            <Col xs={4}>
              <fieldset className="pense-bete">
                <legend>Pense-bête</legend>
                <Image src="backgrounds/indice jeux math white V2.png" fluid />
              </fieldset>
            </Col>
            <Col xs={8}>
              <div>UTILISATEUR INCONNU !</div>
              <div>
                {trackedIP
                  ? "Tracez votre schéma pour vous identifier. Procédure de Tracking de votre IP en cours."
                  : "Tracez votre schéma pour vous identifier dans les 8 minutes avant le lancement de la procédure de tracking de votre IP."}
              </div>
              <div className={trackedIP ? "timer blink-me-fast" : "timer"}>
                {pad0(Math.floor(timeLeft / 60))} : {pad0(timeLeft % 60)}
              </div>
            </Col>
          </Row>
          <Row xs={1} className="justify-content-md-center">
            <Col md={12} className="justify-content-md-center">
              <div className="pattern-lock-wrapper">
                <div className="lockee-numbers">
                  {Array.from({ length: 9 }).map((_, i) => (
                    <div key={i} className="lockee-num">
                      {(i + 1) % 9}
                    </div>
                  ))}
                </div>
                <PatternLock
                  pointSize={15}
                  size={3}
                  path={path}
                  onChange={(pattern) => {
                    setPath(pattern)
                  }}
                  onFinish={checkPath}
                  style={{
                    backgroundColor: "#1115",
                    zIndex: "25",
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal.Dialog>
  )
}

export default LockeeFrame
