import React from "react"
import { MdFullscreen, MdPowerSettingsNew } from "react-icons/md"

/* Get the documentElement (<html>) to display the page in fullscreen */
/* View in fullscreen */
const openFullscreen = () => {
  var elem = document.documentElement
  if (elem.requestFullscreen) {
    elem.requestFullscreen()
  } else if (elem.webkitRequestFullScreen) {
    /* Safari */
    elem.webkitRequestFullScreen()
  } else if (elem.msRequestFullScreen) {
    /* IE11 */
    elem.msRequestFullScreen()
  } else if (elem.mozRequestFullScreen) {
    /* Mozilla */
    elem.mozRequestFullScreen()
  }
}

const FullScreenButton = ({ teamTag }) => (
  <div
    id="button_fs"
    onClick={() => openFullscreen()}
    style={teamTag === "alpha" ? { color: "black" } : {}}
  >
    <MdFullscreen />
  </div>
)

const ResetScreenButton = ({ teamTag, onReset }) => (
  <div
    id="button_reset"
    onClick={onReset}
    style={teamTag === "alpha" ? { color: "black" } : {}}
  >
    <MdPowerSettingsNew />
  </div>
)

export default FullScreenButton
export { ResetScreenButton }
