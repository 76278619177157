import React, { useState, useEffect, useRef } from "react"
import { Container } from "react-bootstrap"
import CookieConsent from "react-cookie-consent"
import ScorePage from "./components/ScorePage"

import {
  getGameStats,
  postGameUpdate,
  GAME_STATES,
  STARTED,
  SYNCH,
  END,
  SCORE,
  isTestGame,
} from "./utils"
import FullScreenButton, {
  ResetScreenButton,
} from "./components/FullScreenButton"
import HackerPage from "./components/HackerPage"
import DesktopPage from "./components/DesktopPage"
import { EscapeWebSocket } from "./components/Synchro"

import "./App.css"

const DEBUG = false
const websocket = EscapeWebSocket()

const App = (props) => {
  const [gameState, setGameState] = useState(GAME_STATES[SYNCH])
  const [gameData, setGameData] = useState({})
  const [gameAction, setGameAction] = useState(undefined)
  // eslint-disable-next-line no-unused-vars
  const [spectator, setSpectator] = useState(false)
  const [displayFS, setDisplayFS] = useState(true)
  const [displayReset] = useState(isTestGame())
  const gameId = useRef(null)

  // fullscreen management
  useEffect(() => {
    const fsChange = () => {
      var fullscreenElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      setDisplayFS(fullscreenElement == null)
    }
    const mozfs = document.addEventListener("mozfullscreenchange", fsChange)
    const webkitfs = document.addEventListener(
      "webkitfullscreenchange",
      fsChange
    )
    const fs = document.addEventListener("fullscreenchange", fsChange)
    return () => {
      document.removeEventListener("mozfullscreenchange", mozfs)
      document.removeEventListener("webkitfullscreenchange", webkitfs)
      document.removeEventListener("fullscreenchange", fs)
    }
  }, [displayFS])

  // tab closing warning
  useEffect(() => {
    // called when the user accept the closing -> send a mail?
    const handleTabClosing = () => {
      console.log("EXITING GAME")
    }
    // called when the user ask to close the tab/window or reload (f5)
    const alertUser = (event) => {
      event.preventDefault()
      event.returnValue = ""
    }
    window.addEventListener("beforeunload", alertUser)
    window.addEventListener("unload", handleTabClosing)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
      window.removeEventListener("unload", handleTabClosing)
    }
  })

  useEffect(() => {
    gameId.current = gameData.game_id
  }, [gameData.game_id])

  useEffect(() => {
    // update some global team dependant CSS
    if (gameData.team_tag === "alpha") {
      document.body.style.backgroundColor = "#ffffff"
    } else if (gameData.team_tag === "delta") {
      document.body.style.backgroundColor = "#180090"
    }
  }, [gameData.team_tag])

  // WebSocket message receive handler
  // two kind of handler depending on message type: start or game
  // message start type is used during team synchronization
  const onStart = (msg) => {
    if (DEBUG) console.log("Received START msg from ws : ", msg)
    const gameState =
      msg.game_state === "ST"
        ? GAME_STATES[STARTED]
        : msg.game_state === "FI"
        ? GAME_STATES[END]
        : GAME_STATES[SYNCH]
    setGameState(gameState)
    postGameUpdate(msg.game_id, gameState).then((res) => {
      if (DEBUG) console.log("OnStart Game Update: ", res)
    })
  }
  // message game type is used during game play
  const onGame = (msg) => {
    //console.log("Received GAME msg from ws : ", msg)
    if ("action" in msg) {
      setGameAction({ ...msg })
    }
  }
  const messageHandlers = { start: onStart, game: onGame }

  const sendWSMessage = (message) => {
    if (message.topic === "save") {
      // currently in the case of a 'save' topic, don't send a websocket msg!!!
      // we could imagine one day that the scenario/step update could go through
      // web socket also
      if (DEBUG)
        console.log(
          `Receive a SCENARIO SAVE for team ${gameData.team_tag} and step ${message.step}, POST Scenario `,
          message.scenario
        )
      postGameUpdate(gameId.current, message.scenario)
    } else {
      websocket.sendMessage(message)
    }
  }

  const onConnect = (data) => {
    // connection to EscapeWeb data server ok
    if (data.finalScore) {
      setGameState(GAME_STATES[SCORE])
      return
    }
    setGameData(data)
    if (data.game_status === "FI") {
      setGameState(GAME_STATES[END])
      getGameStats(data.game_id).then((res) => {
        setGameAction({
          topic: "game",
          action: "final_report", // display final report
          params: { final: res },
        })
      })
      return
    }
    // connect the Web Socket
    websocket.connect(data.team_id, messageHandlers)
  }

  const onReset = () => {
    postGameUpdate(gameId.current, "reset")
    document.location.reload()
  }

  return (
    <Container fluid>
      {gameState === GAME_STATES[SYNCH] ? (
        <HackerPage onConnect={onConnect} />
      ) : gameState === GAME_STATES[SCORE] ? (
        <ScorePage />
      ) : (
        <DesktopPage
          gameId={gameData.game_id}
          teamTag={gameData.team_tag}
          wsSend={sendWSMessage}
          gameAction={gameAction}
          spectator={spectator}
        />
      )}
      {displayFS && <FullScreenButton teamTag={gameData.team_tag} />}
      {displayFS && displayReset && (
        <ResetScreenButton teamTag={gameData.team_tag} onReset={onReset} />
      )}
      {(gameData.team_id ?? false) && (
        <CookieConsent
          cookieName="USER_CONSENT"
          location="bottom"
          buttonClasses="btn btn-warning"
          contentClasses="col"
        >
          This game uses cookies to enhance the user experience. By using
          Escape-Web, you accept our use of cookies.
        </CookieConsent>
      )}
    </Container>
  )
}

export default App
