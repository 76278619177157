import React, { useState, useEffect, useRef } from "react"
import { Alert, Modal, Form, Row, Col } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"

import { DesktopIcon } from "./DesktopIcon"

const MASTERMIND_CHARSET = {
  checked: { code: 9745, color: "green" },
  empty: { code: 9744, color: "#e0af00" },
  failed: { code: 9746, color: "red" },
}

const AlertAnalystLogin = () => {
  return (
    <Alert className="alert-analyst" variant="danger">
      <Alert.Heading>Mauvais Code !</Alert.Heading>
    </Alert>
  )
}

const MasterMind = ({ text, aboveFHD }) =>
  text.map((k, i) => (
    <span
      key={i}
      style={{
        color: k.color,
        fontSize: `${aboveFHD ? "1rem" : "1.2rem"}`,
      }}
    >
      {String.fromCharCode(k.code)}
    </span>
  ))

const LoginDialog = ({ onAction, login }) => {
  const [alert, setAlert] = useState(false)
  const [mastermind, setMastermind] = useState([])
  const isLargerThanFHD = useMediaQuery({ minWidth: 1920 })

  useEffect(() => {
    if (alert) {
      window.setTimeout(() => setAlert(false), 1000)
    }
  }, [alert])

  const getCodeString = (pass) => {
    const resp = "alice10"
    var ret = []
    // check only spaces (init)
    if (pass === "       ") {
      return Array(7).fill(MASTERMIND_CHARSET.empty)
    }
    for (var i = 0; i < pass.length; i++) {
      if (pass[i] === resp[i]) {
        ret[i] = MASTERMIND_CHARSET.checked
      } else if (resp.includes(pass[i])) {
        ret[i] = MASTERMIND_CHARSET.empty
      } else {
        ret[i] = MASTERMIND_CHARSET.failed
      }
    }
    return ret
  }

  useEffect(() => {
    setMastermind(getCodeString(login.failure))
  }, [login])

  const onSubmit = (e) => {
    e.preventDefault()
    setAlert(true)
  }
  return (
    <>
      <Modal.Dialog className="login-modal-analyst" size="lg">
        <Form className="form-login" onSubmit={onSubmit}>
          <Modal.Header className="justify-content-center">
            <Modal.Title>Contrôle de Code</Modal.Title>
          </Modal.Header>
          <Modal.Body className="form-mastermind">
            {<MasterMind text={mastermind} aboveFHD={isLargerThanFHD} />}
          </Modal.Body>
        </Form>
      </Modal.Dialog>
      {alert ? <AlertAnalystLogin /> : ""}
    </>
  )
}

const DesktopAnalyste = ({ onFolderClick, content, onAction, login }) => {
  const desktop = useRef(null)

  useEffect(() => {
    if ("success" in login) {
      desktop.current.style.backgroundImage =
        "url('backgrounds/personnel3.jpg')"
    }
  }, [login])
  return (
    <div ref={desktop} className="desktop_analyste">
      {"success" in login ? (
        <>
          {/**
          <div className="desktop_folders_a1">
            <DesktopIcon {...content.folders.A1} onClick={onFolderClick} />
          </div>
          */}
          <div className="desktop_folders_a">
            <Row noGutters>
              <Col xs={2}>
                <DesktopIcon {...content.folders.A1} onClick={onFolderClick} />
              </Col>
              <Col xs={2}>
                {content.folders.HACK_TIP.visible ? (
                  <DesktopIcon
                    {...content.folders.HACK_TIP}
                    onClick={onFolderClick}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col xs={2}>
                {content.folders.AUDIO_MORENO.visible ? (
                  <DesktopIcon
                    {...content.folders.AUDIO_MORENO}
                    onClick={() =>
                      onAction({
                        type: "game",
                        location: "MorenoAudioClick",
                        result: { success: true },
                      })
                    }
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row noGutters>
              <Col xs={2}>
                <DesktopIcon {...content.folders.A2} onClick={onFolderClick} />
              </Col>
              <Col xs={2}>
                <DesktopIcon {...content.folders.A4} onClick={onFolderClick} />
              </Col>
              <Col xs={2}>
                {content.folders.A_SURVEY.visible ? (
                  <DesktopIcon
                    {...content.folders.A_SURVEY}
                    onClick={() =>
                      onAction({
                        type: "game",
                        location: "SurveyView",
                        result: "open",
                      })
                    }
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row noGutters>
              <Col xs={2}>
                <DesktopIcon {...content.folders.A3} onClick={onFolderClick} />
              </Col>
              <Col xs={2}>
                <DesktopIcon {...content.folders.A5} onClick={onFolderClick} />
              </Col>
              <Col xs={2}>
                {content.folders.A_MINIGAME1.visible ? (
                  <DesktopIcon
                    {...content.folders.A_MINIGAME1}
                    onClick={onFolderClick}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row noGutters>
              <Col xs={2}>
                <DesktopIcon {...content.folders.A6} onClick={onFolderClick} />
              </Col>
              <Col xs={2}>
                <DesktopIcon {...content.folders.A7} onClick={onFolderClick} />
              </Col>
              <Col xs={2}>
                {content.folders.A_DOC_2BIS.visible ? (
                  <DesktopIcon
                    {...content.folders.A_DOC_2BIS}
                    onClick={onFolderClick}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <LoginDialog onAction={onAction} login={login} />
      )}
    </div>
  )
}

export { DesktopAnalyste, MASTERMIND_CHARSET, MasterMind }
