import React, { useState } from "react"
import { Image } from "react-bootstrap"

import Progress from "../Progress"
import DesktopWindow from "../DesktopWindow"

const CellPhoneLocalization = ({ onAction, scenario }) => {
  const [progress, setProgress] = useState(true)

  const onWindowClose = () => {
    onAction({
      type: "game",
      location: "DeletePicto",
      result: "close",
    })
  }

  const onProgressEnd = () => setProgress(false)

  const content = scenario.desktop.alpha.custom.A8_cellphone_loc
  const content_win = { title: content.title }

  return (
    <>
      <DesktopWindow
        className="cellphone-loc-window"
        content={content_win}
        onClose={onWindowClose}
        style={{
          top: "4em",
          left: "15em",
          width: "20em",
          color: "white",
        }}
      >
        <div className="cellphone-localization">
          {"Numéro de téléphone détecté : "}
          {progress ? (
            <Progress timeProgress={40} timeEnd={1000} onEnd={onProgressEnd} />
          ) : (
            <>
              Floriane Moreno à Joinville-le-Pont (94340).
              <br />
              Reportez-vous au plan ci-dessous pour en savoir plus.
              <Image
                src="backgrounds/zoom localisation téléphone.gif"
                onClick={() =>
                  window
                    .open(
                      "https://www.google.com/maps/@48.8167549,2.47052,1282m/data=!3m1!1e3?hl=fr",
                      "_blank"
                    )
                    .focus()
                }
                style={{ cursor: "pointer" }}
              />
            </>
          )}
        </div>
      </DesktopWindow>
    </>
  )
}

export default CellPhoneLocalization
